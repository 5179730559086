/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React, { useState, useEffect, createContext, useContext } from 'react';
import { globalStyles } from '../../componentstyles/globalstyles';
import { searchResultStyles } from '../../componentstyles/searchResultStyles';
import { PackagePriceContext } from '../../../context/PackagePriceTagContext';
import ExceptionController from './exceptionController';
import { useLocation } from 'react-router-dom';
import ModalContainer from './modalContainer';
import { useHistory } from 'react-router';
// import FooterContent from './footerInventory';
import SearchFilter from './searchCriteria';
import DynamicGridComponent from './grid';
import { useParams } from 'react-router';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import AddedItem from './addedItem';
import PrintPackagePrice from '../PackagePriceTags/PackagePricePrintDoc'
import { getInventorySearch, getPricingClientCall, getPackagePriceTag, postbreakPackage, createPackage, getCurrentUser, getCoworkerDetails } from '../../../api/user';
import SecondFactor from './secondAuthen';
import {
  Grid,
  Card,
  Typography,
  CardContent,
  CircularProgress,
  RACButton,
  RACModalCard,
} from '@rentacenter/racstrap';

export const Usercontext = createContext({});

export interface columns {
  columnId: string;
  IsSorted: boolean;
  sortOrder: string;
  columnType?: 'link';
  columnTitle: string;
  IsSortable: boolean;
  columnColor?: string;
  subColumns?: columns[];
  handleColumnClick?: () => void;
}

interface Params {
  customerId: string;
}

const SearchResult1 = (props: any) => {
  const { packagePriceTagsOne, setinitialAgrData, packageContextValue: [packageDetailContext] } =
    useContext(PackagePriceContext);
  const search = useLocation().search;
  const history = useHistory()
  let coWorkerRole = '';
  const classes = globalStyles();
  const agrComponent = searchResultStyles();
  const [errorMsg, setErrorMsg] = useState<boolean>(false)
  const { customerId } = useParams<Params>();
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');
  const sortDirection = {
    ASC: 'asc',
    DESC: 'desc',
  };

  const RateTermCompetitor = {
    AgrRateInp: null,
    AgrTermInp: null,
    CompetitorCheck: false,
  };

  const resultControllerObj = {
    controlRecord: false,
    controlEmptyRecord: false,
    internalServerController: false,
  };

  const modalStates = {
    breakPopup: false,
    addAllPopUp: false,
    itemPricingFail: false,
    breakPackageCtrl: false,
    RateTermController: false,
    PackageExceptionPopup: false,
    packageExceptionController: false,
  };

  const loaderObj = {
    Gridloader: true,
    masterLoader: false,
    loaderController: false,
  };

  const deleteIdObj = { invDeleteId: '' };
  const [loaderBundle, setLoaderBundle] = useState(loaderObj);
  const [errorContent, seterrorContent] = useState<any>('')
  const [PricexeptionMessage, setPricexeptionMessage] = useState('');
  const [errorMsgCondition, seterrorMsgCondition] = useState<boolean>(false)
  const [currentCWRole, setcurrentCWRole] = useState('');
  const [printCreatePackage, setprintCreatePackage] = useState<any>()
  const [print, setPrint] = useState<boolean>(false)
  const [openPackageSelection, setopenPackageSelection] = useState(false);
  const [singleItemvalidate, setsingleItemvalidate] = useState<boolean>(false);
  const [deleteId, setdeleteId] = React.useState(deleteIdObj);
  const [modalController, setmodalController] = useState(modalStates);
  const [priceController, setpriceController] =
    useState<any>(RateTermCompetitor);
  const [resultController, setresultController] = useState(resultControllerObj);
  const [scrollLoader, setscrollLoader] = useState(false);
  const [secondLvlAuth, setsecondLvlAuth] = useState(false);
  const [hasMore, sethasMore] = useState(true);
  const [payloadSetNumber, setpayloadSetNumber] = useState<any>(1);
  const [moduleName, setmoduleName] = useState('');
  const [gridData, setGridData] = useState<any>();
  const [isPackage, setIsPackage] = useState(false);
  const [itemArray, setItemArray] = useState<any>([]);
  const [salesLeadData, setSalesLeadData] = useState();
  const [packagePriceTags, setPackagePriceTags] = useState<any>([]);
  const [bucketCopy, setBucketCopy] = useState<any>([]);
  const [collectIndex, setcollectIndex] = useState<any>();
  const [formException, setformException] = useState<any>();
  const [PackageItemId, setPackageItemId] = useState<any>();
  const [packageDeptid, setpackageDeptid] = useState<any>();
  const [showAddedItem, setShowAddedItem] = useState(false);
  const [storeSalesData, setstoreSalesData] = useState<any>();
  const [breakPriceIds, setbreakPriceIds]: any = useState([]);
  const [packageSubdepid, setpackageSubdepid] = useState<any>();
  const [receivedpackage, setreceivedpackage] = useState<any>();
  const [packageCriteria, setpackageCriteria] = useState<any>();
  const [controlViewMode, setControlViewMode] = useState(false);
  const [packageBracketid, setpackageBracketid] = useState<any>();
  const [pricingException, setpricingException] = useState<any>();
  const [footerControlData, setfooterControlData] = useState(true);
  const [PackagePricingResponse, setPackagePricingResponse] = useState<any>();
  const [internalContextData, setInternalContextProvider] = useState<any>();
  const [tireController, settireController] = useState<any>(false);
  const [selectedPackage, setselectedPackage] = useState('');
  const [packagebtn, setPackagebtn] = useState<any>(true);
  const [packageText, setPackageText] = useState<any>(false);
  const [packageList, setpackageListData] = useState();

  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme';
  const altersearch = showAddedItem
    ? agrComponent.collapsedWigid
    : agrComponent.expandWigid;

  useEffect(() => {
    console.log(props);
    if (itemArray !== undefined && itemArray.length > 0) {
      console.log('selectedPackage in rate change', selectedPackage);

      // setmasterLoader(true);
      setLoaderBundle({ ...loaderBundle, masterLoader: true });
      const AgrRateTerm = async () => {
        console.log('function call');
        const ClientCallResponse = await pricingCall(
          itemArray,
          null,
          isPackage,
          priceController.AgrRateInp != null
            ? parseFloat(priceController.AgrRateInp)
            : null,
          priceController.AgrTermInp != null
            ? parseInt(priceController.AgrTermInp)
            : null,
          priceController.CompetitorCheck,
          selectedPackage
        );
        // setmasterLoader(false);
        console.log('ClientCallResponse', ClientCallResponse);
        setLoaderBundle({ ...loaderBundle, masterLoader: false });

        if (
          ClientCallResponse !== undefined &&
          ClientCallResponse.status == 200
        ) {
          setInternalContextProvider(ClientCallResponse.data);
          // provideContext(ClientCallResponse.data, itemArray);

          // setaddedItemContext(ClientCallResponse.data);
        } else if (
          ClientCallResponse !== undefined &&
          ClientCallResponse.status == 400
        ) {
          setpricingException(ClientCallResponse.data.errors[0].error);
          setmodalController({ ...modalController, RateTermController: true });
          // setinvalidTerm(true);
        }
      };
      AgrRateTerm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceController.AgrRateInp, priceController.AgrTermInp]);
  useEffect(() => {
    console.log('packagePriceTagsmain', packagePriceTagsOne);
    if (packageDetailContext.packageName !== "") {
      setPackageText(true);
      setItemArray(packageDetailContext.primaryItem)
      const packages = async () => {
        const response: any = await pricingCall(
          packageDetailContext.primaryItem,
          null,
          true,
          null,
          null,
          false,
          // ''
          packageDetailContext.packageName
        );
        console.log('packagesRes', response);
        if (response?.data && response?.status == 200) {
          setInternalContextProvider(response.data);
          setShowAddedItem(true);
          const uniqueChars = [...new Map(response.data.itemPricing.map((item: any) => [item.packagePriceId, item])).values()];

          const packageIds = uniqueChars.map((value: any) => {
            if (value.packagePriceId != null) {
              console.log('value', value.packagePriceId)
              return parseInt(value.packagePriceId)
            }
          })
          setbreakPriceIds(packageIds)
        }
      };
      packages();
    }
  }, [packageDetailContext]);

  useEffect(() => {
    getCoWorkerRole()
  }, [])
  const getCoWorkerRole = async () => {
    try {

      const currentUserRes: any = await getCurrentUser();
      console.log('getCurrentUser-apiResponse', currentUserRes);

      if (currentUserRes?.status === 200) {
        const EmployeeId = currentUserRes?.data?.employeeId;
        console.log('EmployeeId', EmployeeId);

        const payload = {
          coworkerId: EmployeeId,
          storesRequired: true
        }
        console.log('menuandstores-payload', payload);
        const coWorkerRoleObj: any = await getCoworkerDetails(payload);
        if (
          coWorkerRoleObj && coWorkerRoleObj?.status == 200 &&
          coWorkerRoleObj?.data?.coworkerProfile &&
          coWorkerRoleObj?.data?.coworkerProfile?.role
        ) {
          const roleObj = coWorkerRoleObj.data.coworkerProfile.role;
          coWorkerRole = roleObj;
          console.log('roleObj', roleObj);

          setcurrentCWRole(roleObj);

          console.log('coWorkerRoleObj', coWorkerRole);
        }
        /* eslint-disable no-console */
        console.log('coWorkerRoleObj', coWorkerRoleObj);
      }

    } catch (e: any) {
      console.log('Error in co worker role');
    }
  };

  const OnPrintPrice = (module: any) => {
    setsecondLvlAuth(true);
    //setauthenType(authType);
    setmoduleName(module);
  }
  //AgrRateInp, AgrTermInp
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    console.log('vaue of id in useeffect', deleteId.invDeleteId);
    if (deleteId.invDeleteId !== '') {
      if (isPackage) {
        // setbreakPackageCtrl(true);
        setmodalController({ ...modalController, breakPackageCtrl: true });

        console.log('Came Inside Is Package');
      } else if (!isPackage) {
        console.log('Came Inside Not Package');
        deleteItem();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]); // this method is used to manipulate the data item based in the delete in the addedadd item menu




  const pricingCall = async (
    inventoriesValue: any,
    validateInventoryIdValue: any,
    packageRequiredValue: any,
    agreementRateValue: any,
    agreementTermValue: any,
    isCompetitorMatchValue: any,
    packageNameValue: any
  ) => {
    const req: any = {
      inventories: inventoriesValue,
      validateInventoryId: parseInt(validateInventoryIdValue),
      packageRequired: packageRequiredValue,
      agreementRate: agreementRateValue,
      agreementTerm: agreementTermValue,
      isCompetitorMatch: isCompetitorMatchValue,
      packageName: packageNameValue,
    };
    console.log('pricingCall request', req);

    return await getPricingClientCall(req);
  };

  const closeModal = (controller: any): any => {
    console.log('received controller', controller);
    if (controller == 'addAllPopUp') {
      setmodalController({ ...modalController, addAllPopUp: false });
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    } else if (controller == 'breakPackageCtrl') {
      setmodalController({ ...modalController, breakPackageCtrl: false });
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    } else if (controller == 'RateTermController') {
      setmodalController({ ...modalController, RateTermController: false });
    } else if (controller == 'breakPopup') {
      setmodalController({ ...modalController, breakPopup: false });
    }
  };

  const deleteItem = () => {
    if (itemArray !== undefined && itemArray.length > 0) {
      console.log('Data Inside invDeleteId use effect', deleteId.invDeleteId);
      let UpdatedArray: any = [];
      itemArray.map((deleteObject: any, index: any) => {
        if (parseInt(deleteObject) === parseInt(deleteId.invDeleteId)) {
          UpdatedArray = itemArray.filter((item: any, filterIndex: any) => {
            console.log('filter copy item', item);
            return filterIndex != index;
          });
          //UpdatedArray = itemArray.splice(index, 1);
          console.log('Newly Updated Array', UpdatedArray);
          setItemArray(UpdatedArray);
        }
      });
      if (UpdatedArray.length <= 1) {
        console.log('reached null received package');
        setselectedPackage('');
        setreceivedpackage('');
        //setsearchCriteria({ ...searchCriteria, packageTypeval: '' });
      }
      // setitemArrayContext(UpdatedArray);
      //setitemArrayData(UpdatedArray);

      let response: any;
      if (UpdatedArray.length > 0) {
        console.log('Inside if');
        const selectedPackageProvider =
          UpdatedArray.length > 1 ? selectedPackage : '';
        console.log('selectedPackageProvider logger', selectedPackageProvider);

        const triggerAPI = async () => {
          response = await pricingCall(
            UpdatedArray,
            null,
            false,
            null,
            null,
            false,
            selectedPackageProvider
          );
          // setmasterLoader(false);
          setLoaderBundle({ ...loaderBundle, masterLoader: false });

          console.log('Inside Use Effect of invDeleteId', response);
          console.log('Came inside result if condition');
          // setaddedItemContext(response.data);
          //setaddedItem(response.data);

          if (response.data !== undefined && response.status == 200) {
            setinitialAgrData(response.data.agreementPricing.agreementRate);
            setShowAddedItem(true);
            setfooterControlData(false);
            setInternalContextProvider(response.data);
            if (response.data.packageInformation !== null) {
              setpackageCriteria(
                response.data.packageInformation[0].packageCriteria
              );
              setreceivedpackage(response.data.itemPricing[0].packageName);
              //   setsearchCriteria({
              //     ...searchCriteria,
              //     packageTypeval: response.data.itemPricing[0].packageName,
              //   });
            } else {
              setpackageCriteria(response.data.packageInformation);
            }

            setpackageListData(response.data.packageInformation);
          } else if (response.status == 400) {
            setmodalController({
              ...modalController,
              itemPricingFail: true,
            });
            setPricexeptionMessage(response.data.errors[0].error);

          }
        };
        triggerAPI();
      } else {
        // setmasterLoader(false);
        setLoaderBundle({ ...loaderBundle, masterLoader: false });
        //setisCompCheck(false);
        console.log('Came inside deep else');
        setInternalContextProvider(undefined);
        setShowAddedItem(false);
        setfooterControlData(true);
      }
    } else {
      console.log('Came inside else');
      setInternalContextProvider(undefined);
      setShowAddedItem(false);
      setfooterControlData(true);

      // setmasterLoader(false);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    }
    const copyNum = deleteId.invDeleteId;

    const resultArr = bucketCopy.filter((data: any, index: any) => {
      return bucketCopy.indexOf(data) === index;
    });

    console.log('copy before map', bucketCopy);
    console.log('copyNum', copyNum);

    const refinedTableData: any = [];
    gridData.map((obj: any, index: any) => {
      if (obj.inventoryId !== copyNum) {
        refinedTableData.push(obj);
      }
    });

    resultArr.map((obj: any, index: any) => {
      console.log('copy map obj', obj);
      if (obj.inventoryId == copyNum) {
        const copyTableData = refinedTableData;
        copyTableData.push(obj);
        setGridData(copyTableData);

        const newCopy = bucketCopy.filter((item: any, filterIndex: any) => {
          console.log('filter copy item', item);
          return filterIndex != index;
        });

        console.log('copy after map', newCopy);
        setBucketCopy(newCopy);
        // setcopyArrayContext(newCopy);
        //setcopyArrayData(newCopy);
      }
    });
  };

  const acceptBreakPackage = () => {
    deleteItem();
    setIsPackage(false);
    // setIsPackageBreaked(false);
    // setbreakPackageCtrl(false);
    setmodalController({ ...modalController, breakPackageCtrl: false });
  };

  const declineBreakPackage = () => {
    // setmasterLoader(false);
    setLoaderBundle({ ...loaderBundle, masterLoader: false });

    // setbreakPackageCtrl(false);
    setmodalController({ ...modalController, breakPackageCtrl: false });
  };

  const searchClick = async (value: any, source: any) => {
    try {
      if (source == 'searchButtonClick') {
        setpayloadSetNumber(1);
        //setOffSetValue(1);
        setLoaderBundle({
          ...loaderBundle,
          Gridloader: true,
          loaderController: true,
        });
        sethasMore(true);
      } else if (source == 'searchButtonNotClick') {
        setscrollLoader(true);
      }
      console.log('thsg6rd8z4vsc5search', value);

      const currentStore = sessionStorage.getItem('storeNumber');
      const req: any = {
        storeNumbers: [currentStore],
        itemNumber: value.itemNo == '' ? null : [value.itemNo],
        serialNumber: value.serialNo?.trim(),
        bracket: value.bracket?.trim(),
        brand: value.brand?.trim(),
        modelNumber: value.model?.trim(),
        statusId: value.statusId,
        subStatusId: salesLeadId == null ? [2] : [2, 6],
        conditionId:
          value.conditionId == '0' || value.conditionId == null
            ? null
            : parseInt(value.conditionId),
        department: value.departments,
        subDepartment: value.subDepartments,
        packageName: value.packageTypeval,
        setNumber: source == 'searchButtonClick' ? 0 : payloadSetNumber,
      };
      console.log('defined payload', req);



      const response: any = await getInventorySearch(req);
      // if (salesLeadId !== null) {
      //   setSalesLeadData({
      //     packageTypeval: value.packageTypeval,
      //   });
      //   setsearchCriteria({
      //     ...searchCriteria,
      //     packageTypeval: value.packageTypeval,
      //   });
      // }
      if (response.status == 400 || response.status == 500) {
        // setinternalServerController(true);
        setresultController({
          ...resultControllerObj,
          internalServerController: true,
        });
        // setloaderController(false);
        setLoaderBundle({ ...loaderBundle, loaderController: false });
      } else if (response.status == 200 && response.data.response.length > 0) {
        // setsearchResultContext(response.data.response);
        if (source == 'searchButtonNotClick') {
          setpayloadSetNumber(payloadSetNumber + 1);
          //setOffSetValue(payloadSetNumber + 1);
        }
        let furtherFilteredData: any = [];
        if (itemArray.length > 0) {
          console.log('reached if of search');
          let pushMe = false;
          response.data.response.map((filteredDataObj: any, index: any) => {
            console.log('times of trigger', index);

            for (let i = 0; i < itemArray.length; i++) {

              console.log(
                'filteredDataObj.inventoryId',
                filteredDataObj.inventoryId
              );

              if (itemArray[i] !== parseInt(filteredDataObj.inventoryId)) {
                console.log('reached once true');
                pushMe = true;
              } else {
                console.log('reached break statement');
                pushMe = false;
                break;
              }
            }

            if (pushMe) {
              console.log('times reached fbdfbdfzBf');

              furtherFilteredData.push(filteredDataObj);
            }
          });
        } else {
          console.log('reached else of sr');
          furtherFilteredData = response.data.response;
        }
        if (furtherFilteredData.length <= 0) {
          setGridData([]);
          setresultController({ ...resultControllerObj, controlRecord: true });

          // setloaderController(false);
          setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
        } else {
          // setsearchResult(furtherFilteredData); //This will be storing the response of the inventory search API
          // setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
          // console.log('Response from api', response);

          // setGridData(furtherFilteredData); //This will assign the API result to get the Search Result grid get load.
          if (source == 'searchButtonNotClick') {
            //setsearchResult([...gridData, ...furtherFilteredData]);
            setGridData([...gridData, ...furtherFilteredData]);
          } else {
            //setsearchResult(furtherFilteredData); //This will be storing the response of the inventory search API
            setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
            console.log('Response from api', response);

            setGridData(furtherFilteredData); //This will assign the API result to get the Search Result grid get load.
          }
        }
        //setsearchResult(response.data.response);

        // setloaderController(false);
        //setLoaderBundle({ ...loaderBundle, loaderController: false });

        console.log('Response from api', response);

        const ApiResult: any = response.data.response;
        console.log('response', ApiResult);
        console.log('response-array', ApiResult[0]);

        // if (salesLeadId !== null && storeSalesData.modelNumber == null && storeSalesData.inventories[0].inventoryNumber !== null && getSalesInfoFail == false) {
        //   console.log("passed the condition");
        //   console.log("response-array", ApiResult[0]);
        //   const apiInventoryid: any = ApiResult[0];
        //   console.log("apiInventoryid", apiInventoryid);
        //   const setInventoryid: any = apiInventoryid.inventoryId;
        //   console.log("setInventoryid", setInventoryid);
        //   let SalesInventoryId: any = [];
        //   SalesInventoryId = [...itemArray, parseInt(setInventoryid)];
        //   setBucketCopy(ApiResult);
        //   addSalesLeadItem(setInventoryid);
        //   setItemArray(SalesInventoryId);
        // } // Commented as QA Requested
        // else {
        //   setGridData(ApiResult);
        // }
        setresultController(resultControllerObj);
      } else if (response.data.response.length <= 0) {
        // setGridData([]);
        // // setcontrolRecord(true);
        // setresultController({ ...resultController, controlRecord: true });

        // // setloaderController(false);
        // setLoaderBundle({ ...loaderBundle, loaderController: false });
        // if (gridData.length == 0) {
        //   setGridData([]);
        //   setresultController({ ...resultController, controlRecord: true });
        //   setLoaderBundle({ ...loaderBundle, loaderController: false });
        // } else {
        //   sethasMore(false);
        // }

        if (source == 'searchButtonClick') {
          setGridData([]);
          setresultController({ ...resultControllerObj, controlRecord: true });
          setLoaderBundle({ ...loaderBundle, loaderController: false });
        } else if (source == 'searchButtonNotClick') {
          sethasMore(false);
          setLoaderBundle({ ...loaderBundle, loaderController: false });
        }
      }
    }
    catch {
      setresultController({
        ...resultControllerObj,
        internalServerController: true,
      });
    }
  };

  const gridCallMethod = () => {
    console.log("Came inside Grid Method");

    if (gridData !== undefined && gridData.length > 0) {
      console.log("Inside table bind", gridData);
      return (
        <div>
          <Typography className={`${classes.title} ${classes.mt3}`}>
            Search Results
          </Typography>
          <Card className={`${classes.card} ${classes.mb4} ${classes.p3}`}>
            <CardContent className={classes.p0}>
              <DynamicGridComponent
                items={gridData}
                orderBy="loanerAgreement"
                handleActionType={addItem}
                order={sortDirection.ASC}
                reveiveHasMore={hasMore}
                reveivescrollLoader={scrollLoader}
                triggerSearch={searchClick}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );
    } else if (resultController.internalServerController) {
      return (
        // <div>
        //   <Typography className="title mt-3">Search Results</Typography>
        //   <div className="text-center mt-5">
        //     <AlertIcon className="alerticon" />
        //     <Typography className="title mb-3">Something Went Wrong</Typography>
        //   </div>
        // </div>
        <ExceptionController
          throwExceptionDecider="Went Wrong"
          throwStyle={classes}
        />

      );
    } else if (resultController.controlRecord) {
      return (
        // <div>
        //   <Typography className={`${classes.title} ${classes.mt3}`}>
        //     Search Results
        //   </Typography>
        //   <div className={`${classes.textCenter} ${classes.mt5}`}>
        //     <AlertIcon />
        //     <Typography className={`${classes.subTitle} ${classes.mb3}`}>
        //       No Records Found
        //     </Typography>
        //   </div>
        // </div>
        <ExceptionController
          throwExceptionDecider="No Records"
          throwStyle={classes}
        />

      );
    } else if (resultController.controlEmptyRecord) {
      return (
        // <div>
        //   <Typography className={`${classes.title} ${classes.mt3}`}>
        //     Search Results
        //   </Typography>
        //   <div className={`${classes.textCenter} ${classes.mt5}`}>
        //     <AlertIcon />
        //     <Typography className={`${classes.subTitle} ${classes.mb3}`}>
        //       No Items to Add
        //     </Typography>
        //   </div>
        // </div>
        <ExceptionController
          throwExceptionDecider="No Item"
          throwStyle={classes}
        />

      );
    }
  };




  //PS-12 this method will be getting all the package items from api

  const addSalesLeadItem = async (itemId: any) => {
    console.log("enterd the addSalesLead");
    // setmasterLoader(true);
    setLoaderBundle({ ...loaderBundle, masterLoader: true });

    console.log("indexaddiyem", itemId);
    const response: any = await pricingCall(
      itemArray,
      itemId,
      false,
      null,
      null,
      false,
      selectedPackage
    );

    if (response.status == 200) {
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
      //setinitialAgrData(response.data.agreementPricing.agreementRate)
      console.log("raw response salesLed", response);
      //setaddedItem(response.data);

      console.log("Respoooooonse salesLead", response.data);
      setInternalContextProvider(response.data);
      setShowAddedItem(true);
    } else if (response.status == 400) {
      setmodalController({
        ...modalController,
        itemPricingFail: true,
      });
    }


  };
  const addAllItem = async () => {
    setIsPackage(true);
    // setaddAllPopUp(false);
    setmodalController({ ...modalController, addAllPopUp: false });

    // setmasterLoader(true);
    setLoaderBundle({ ...loaderBundle, masterLoader: true });

    setItemArray([]);
    const response = await pricingCall(
      [],
      PackageItemId,
      true,
      null,
      null,
      false,
      ""
    );
    console.log("add all item popup", response);
    if (response.status == 200) {
      // setpackageDataContext(response.data.packageInformation);
      setpackageListData(response.data.packageInformation);
      setinitialAgrData(response.data.agreementPricing.agreementRate)
      // setmasterLoader(false);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });

      setreceivedpackage(response.data.itemPricing[0].packageName);
      console.log(
        "response.data.itemPricing[0].packageName",
        response.data.itemPricing[0].packageName
      );
      //setsearchCriteria({...searchCriteria,packageTypeval: response.data.itemPricing[0].packageName})

      let newItemArray: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((value: any) => {
          console.log("individual inv id of package", value.inventoryId);

          newItemArray = [...newItemArray, parseInt(value.inventoryId)];
        });

        setfooterControlData(false);

        if (response.data.packageInformation !== null) {
          setpackageCriteria(
            response.data.packageInformation[0].packageCriteria
          );
          setreceivedpackage(response.data.itemPricing[0].packageName);
          //setsearchCriteria({...searchCriteria,packageTypeval: response.data.itemPricing[0].packageName})

        } else {
          setpackageCriteria(response.data.packageInformation);
        }
      }
      setItemArray(newItemArray);
      // setitemArrayContext(newItemArray);
      //setitemArrayData(newItemArray);

      console.log("Item Array in package", newItemArray);
      let tireController = false;
      response.data.itemPricing.map((obj: any) => {
        if (
          obj.rmsItemNumber == "200012120" ||
          obj.rmsItemNumber == "200012121" ||
          obj.rmsItemNumber == "200013177" ||
          obj.rmsItemNumber == "200012118" ||
          obj.rmsItemNumber == "200013350" ||
          obj.rmsItemNumber == "200012119"
        ) {
          tireController = true;
          settireController(true);
        }
        // else{
        //   settireController(false);
        // }
      });
      // provideContext(response.data, newItemArray, tireController);
      //   ContextSkeleton(
      //     response.data,
      //     newItemArray,
      //     setTestInput,
      //     customerId,
      //     priceController,
      //     competitorDDValue,
      //     tireController,
      //   );

      setInternalContextProvider(response.data);

      // setGridData([...tabledata, copy]);
      const copyTableData = gridData;

      // setGridData([...tabledata, obj]);
      bucketCopy.map((obj: any) => {
        copyTableData.push(obj);
      });
      setGridData(copyTableData);
      // setcopy([]);

      let emptyCopy: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((obj: any) => {
          gridData.map((value: any) => {
            if (value.inventoryId === obj.inventoryId) {
              emptyCopy = [...emptyCopy, value];
            }
          });
        });
        setBucketCopy(emptyCopy);
        // setcopyArrayContext(emptyCopy);
        //setcopyArrayData(emptyCopy);
      }

      console.log("the empty copy is", emptyCopy);

      console.log("before tab data values", gridData);

      let emptyTableData: any = [];
      emptyTableData = gridData.filter((object: any) => !emptyCopy.includes(object));
      console.log("exptyTableData", emptyTableData);
      setGridData(emptyTableData);
      if (response.data !== undefined) {
        // setaddedItemContext(response.data);
        //setaddedItem(response.data);

        setShowAddedItem(true);
        // setaddAllPopUp(false);
        setmodalController({ ...modalController, addAllPopUp: false });
      }
    } else if (response.status == 400) {
      // setpackageExceptionController(true);
      setmodalController({
        ...modalController,
        itemPricingFail: true,
      });
      // setmasterLoader(false);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
      setPricexeptionMessage(response.data.errors[0].error);
    }
  };
  const declineAdding = () => {
    // setChange('1');
    // setaddAllPopUp(false);
    setmodalController({
      ...modalController,
      addAllPopUp: false,
      breakPopup: false,
    });
    // setbreakPopup(false);
    if (itemArray.length > 0) {
      setShowAddedItem(true);
    } else {
      setShowAddedItem(false);
      setInternalContextProvider({});
    }

    // setInternalContextProvider({});
  }; //PS-12


  const calculateSetToDefault = () => {
    setpriceController(RateTermCompetitor);
  };
  // this method is triggered when an item is added in the search result
  const addItem = async (itemid: any, index: any) => {

    // setmasterLoader(true);
    setLoaderBundle({ ...loaderBundle, masterLoader: true });

    setcollectIndex(index);
    let constructedItemArray = [];
    if (isPackage) {
      constructedItemArray = [];
    } else {
      constructedItemArray = itemArray;
    }
    const response: any = await pricingCall(
      constructedItemArray,
      itemid,
      false,
      null,
      null,
      false,
      selectedPackage
    );

    // setmasterLoader(false);
    setLoaderBundle({ ...loaderBundle, masterLoader: false });

    console.log("raw response", response);
    // setaddedItemContext(response.data);
    //setaddedItem(response.data);

    console.log("Respoooooonse", response.data);
    let newItemArray: any = [];
    if (
      response.data !== undefined &&
      response.status == 200 &&
      response.data.isPackage === true
    ) {
      // setShowAddedItem(false);
      // setaddAllPopUp(true);
      setmodalController({ ...modalController, breakPopup: true });

      setPackageItemId(parseInt(itemid));
      setPackagePricingResponse(response.data);
    } else if (response.status == 200 && response.data.isPackage === false) {
      let constructedItemArray = [];
      if (isPackage) {
        constructedItemArray = [];
      } else {
        constructedItemArray = itemArray;
      }

      newItemArray = [...constructedItemArray, parseInt(itemid)];
      setinitialAgrData(response.data.agreementPricing.agreementRate)
      setIsPackage(false);
      console.log("newItemArray in add item", newItemArray);

      setItemArray(newItemArray);
      console.log("reached setitemArrayData");
      setpackageListData(response.data.packageInformation);
      console.log("reached setpackageData");

      console.log(
        "response.data.packageInformation",
        response.packageInformation
      );

      setfooterControlData(false);
      if (isPackage) {
        console.log("reached expected");
        console.log("reached expected copy", bucketCopy);
        const copyTableData = gridData;
        bucketCopy.map((obj: any) => {
          copyTableData.push(obj);
        });
        setGridData(copyTableData);
      }
      // if (newItemArray.length > 1) {
      //   setpackageCriteria(response.data.packageInformation[0].packageCriteria);
      //   setreceivedpackage(response.data.itemPricing[0].packageName);
      // }
      if (response.data.packageInformation !== null) {
        setpackageCriteria(response.data.packageInformation[0].packageCriteria);
        setreceivedpackage(response.data.itemPricing[0].packageName);
        //setsearchCriteria({...searchCriteria,packageTypeval:response.data.itemPricing[0].packageName })
      } else {
        setpackageCriteria(response.data.packageInformation);
      }
      setInternalContextProvider(response.data);
      if (response.data.itemPricing.length !== 1) { setPackagebtn(false) } else { setPackagebtn(true) }
      setBucketCopy([...bucketCopy, gridData[index]]);
      // setcopyArrayContext([...copy, tabledata[index]]);
      //setcopyArrayData([...bucketCopy, gridData[index]]);

      console.log("copy", bucketCopy);
      console.log("tabledata.filter", gridData);

      const newTableData = gridData.filter((item: any, filterIndex: any) => {
        return filterIndex != index;
      });
      if (newTableData.length <= 0) {
        // setcontrolEmptyRecord(true);
        setresultController({ ...resultController, controlEmptyRecord: true });
      }

      // setsearchResultContext(newTableData);
      //setsearchResult(newTableData);

      console.log("ItemMainAry", newTableData);
      setGridData(newTableData);
      // setaddAllPopUp(false);
      //setmodalController({ ...modalController, addAllPopUp: false });

      setShowAddedItem(true);
    } else if (response.status == 400) {
      // setpackageExceptionController(true);
      setmodalController({
        ...modalController,
        itemPricingFail: true,
      });
      setPricexeptionMessage(response.data.errors[0].error);
    }
  };
  const pricingCallTrigger = async (packData: any) => {
    if (itemArray.length > 1) {
      // setmasterLoader(true);
      setLoaderBundle({ ...loaderBundle, masterLoader: true });

      const getResult = await pricingCall(
        itemArray,
        null,
        false,
        priceController.AgrRateInp,
        priceController.AgrTermInp,
        false,
        packData
      );


      if (getResult.data !== undefined &&
        getResult.status == 200) {
        setLoaderBundle({ ...loaderBundle, masterLoader: false });

        //setaddedItem(getResult.data);

        setInternalContextProvider(getResult.data);
        setmodalController({ ...modalController, addAllPopUp: false });
        if (getResult.data.packageInformation !== null) {
          setreceivedpackage(getResult.data.itemPricing[0].packageName);
          // setsearchCriteria({...searchCriteria,packageTypeval:getResult.data.itemPricing[0].packageName })

        }
        setShowAddedItem(true);
        //   ContextSkeleton(
        //     getResult.data,
        //     itemArray,
        //     setTestInput,
        //     customerId,
        //     priceController,
        //     competitorDDValue,
        //     tireController,
        //   );
      } else if (getResult.status == 400) {
        setmodalController({
          ...modalController,
          itemPricingFail: true,
        });
        setLoaderBundle({ ...loaderBundle, masterLoader: false });
        setPricexeptionMessage(getResult.data.errors[0].error);
      }

      return getResult;


    }
  };

  const closeRateTermPopUp = () => {
    // setinvalidTerm(false);
    setmodalController({ ...modalController, RateTermController: false });

    setpricingException("");

  };

  const closepackageExceptionController = () => {
    // setpackageExceptionController(false);
    setmodalController({
      ...modalController,
      packageExceptionController: false,
    });
  };

  const acceptPackageCriteria = () => {
    // setPackageExceptionPopup(false);
    setmodalController({ ...modalController, PackageExceptionPopup: false });
  };

  const closeAcceptPricingFail = () => {
    setmodalController({ ...modalController, itemPricingFail: false });
  }

  const clearbtnClick = (value: any) => {
    if (value === true) {
      setpackageDeptid([]);
      setpackageSubdepid([]);
      setpackageBracketid([]);
      // setpackageItemMasterid([]);
    }
  };


  const closeGroupPop = async () => {
    setreceivedpackage("");
    //setsearchCriteria({...searchCriteria,packageTypeval:''})

    let newItemArray: any = [];
    console.log("Package Pricing Response", PackagePricingResponse);
    // setpackageDataContext(PackagePricingResponse.packageInformation);

    newItemArray = [
      ...itemArray,
      parseInt(
        PackagePricingResponse.itemPricing[
          PackagePricingResponse.itemPricing.length - 1
        ].inventoryId
      ),
    ];
    if (newItemArray.length > 1) {
      setpackageListData(PackagePricingResponse.packageInformation);
      setreceivedpackage(PackagePricingResponse.itemPricing[0].packageName);
      setpackageCriteria(
        PackagePricingResponse.packageInformation[0].packageCriteria
      );
      setInternalContextProvider(PackagePricingResponse);
      //setsearchCriteria({ ...searchCriteria, packageTypeval: PackagePricingResponse.itemPricing[0].packageName })

    }
    else {
      PackagePricingResponse.itemPricing[0].packageName = ""
      setInternalContextProvider(PackagePricingResponse);
    }
    setItemArray(newItemArray);
    //setitemArrayData(newItemArray);
    console.log("Break Item Array", newItemArray);
    console.log("itemArray in closeGroupPop", itemArray);

    console.log(
      "PackagePricingResponse.itemPricing.inventoryId",
      PackagePricingResponse.itemPricing[0].inventoryId
    );

    setfooterControlData(false);
    // provideContext(PackagePricingResponse, newItemArray);
    // ContextSkeleton(
    //   PackagePricingResponse,
    //   newItemArray,
    //   setTestInput,
    //   customerId,
    //   priceController,
    //   competitorDDValue,
    //   tireController,
    // );

    //setaddedItem(PackagePricingResponse);
    setBucketCopy([...bucketCopy, gridData[collectIndex]]);
    //setcopyArrayData([...bucketCopy, gridData[collectIndex]]);
    // setcopy([...copy, tabledata[index]]);
    console.log("copy", bucketCopy);

    // const newTableData = tabledata.filter((item, filterIndex) => {
    //   console.log('filter item', item);
    //   return filterIndex != index;
    // });

    const uniqueChars = [...new Map(PackagePricingResponse.itemPricing.map((item: any) => [item.packagePriceId, item])).values()];

    const packageIds = uniqueChars.map((value: any) => {
      if (value.packagePriceId != null) {
        console.log('value', value.packagePriceId)
        return parseInt(value.packagePriceId)
      }
    })
    setbreakPriceIds(packageIds)
    console.log("received PackageItemId", PackageItemId);
    const newTableData = gridData.filter((item: any) => {
      if (parseInt(item.inventoryId) == PackageItemId) {
        console.log("PackageItemId in logic added", PackageItemId);
        console.log("item.inventoryid in logic added", item.inventoryid);
        console.log("Logic Worked");
      }
      console.log("filter item", item);
      return parseInt(item.inventoryId) != PackageItemId;
    });
    console.log("Add Package newTableData", newTableData);
    setGridData(newTableData);
    if (PackagePricingResponse !== undefined) {
      setShowAddedItem(true);
      // setaddAllPopUp(false);
      setmodalController({ ...modalController, addAllPopUp: false });
    }
    console.log("ItemMainAry", newTableData);
    setmodalController({
      ...modalController,
      addAllPopUp: false,
      breakPopup: false,
    });
    setShowAddedItem(true);



  };
  // this method is used to close the group popup //PS-12
  const declineAddOther = () => {
    // setaddAllPopUp(false);
    // setbreakPopup(true);
    setmodalController({
      ...modalController,
      addAllPopUp: false,
      breakPopup: true,
    });
  };

  const deleteSingleItem = async (itemId: any) => {
    // setmasterLoader(true);
    console.log('package',internalContextData);
    const itemPrice= internalContextData.itemPricing
    const FiteredItemPrice=itemPrice.filter((el:any)=>el.inventoryId==itemId);
       if(FiteredItemPrice[0].packagePriceId!=null){
         setIsPackage(true)
       }
    setLoaderBundle({ ...loaderBundle, masterLoader: true });

    console.log("Required Item Id", itemId);
    setdeleteId({ ...deleteId, invDeleteId: itemId });
  };

  const receiveAgreementRate = (value: any) => {
    //setFromValues({...FromValues,"packagetype":""});
    //setmasterLoader(true);
    //setAgrRateInp(value);
    setpriceController({ ...priceController, AgrRateInp: value });

    //console.log("changeAgrRate", value);
  };
  const receiveAgreementTerm = (value: any) => {
    //setFromValues({...FromValues,"packagetype":""});
    //setmasterLoader(true);
    //setAgrTermInp(value);
    setpriceController({ ...priceController, AgrTermInp: value });

    //console.log("changeAgrTerm", value);
  };

  // const receivePackage = (value: any) => {
  //   console.log('Received Package Value', value);
  //   setreceivedpackage(value);
  // };


  const storeSelectedPackage = (value: any) => {
    console.log('value of the selected packjage', value);

    setselectedPackage(value);
  }
  /*
  * calls
  * This printPackagePriceTag method will generate HTML format and Print Format.
  * */
  const printPackagePriceTag = () => {

    const packagePriceTagDoc = document?.getElementById('PackagePriceTagList')?.innerHTML;
    if (packagePriceTagDoc !== undefined && packagePriceTagDoc !== null) {
      const a: any = window.open(
        "",
        '_blank'

      );
      a?.document?.write("<html>");
      a?.document?.write(packagePriceTagDoc);
      a?.document?.write("</html>");
      a?.document?.close();
      a?.print();
    }

  }

  /*
   * Method
   * This handlePrintPackageOnClick method will call break,create and print Packages API call and error msg method.
   * */
  const handlePrintPackageOnClick = () => {

    if (internalContextData !== undefined) {
      const req1 = async () => {
        setPrint(true)
        if (breakPriceIds?.length > 0 && breakPriceIds !== null) {

          const brIds = breakPriceIds.map((value: any) => {
            if (breakPriceIds !== null && value != undefined) {
              return parseInt(value)
            }
          })
          const req = {
            packagePriceIds: brIds
          }
          const response: any = await postbreakPackage(req);
          if (response?.status == 200) {
            const res = {
              packageInformation: {
                packageName: internalContextData.itemPricing[0].packageName,
                packageRate: parseFloat(internalContextData.agreementPricing.agreementRate),
                packageTerm: parseInt(internalContextData.agreementPricing.agreementTerm)
              },
              inventoryNumbers: internalContextData.itemPricing.map((e: any) => e.inventoryNumber),
              storeNumber: sessionStorage.getItem('storeNumber')
            }
            const packageres = await createPackage(res)
            if (packageres?.status == 200) {
              const packageId = packageres?.data?.packagePriceId
              setprintCreatePackage([packageId])
              const respData = await getPackagePriceTag({ packagePriceIds: [parseInt(packageId)] });
              setPrint(false)
              if (respData?.status == 200) {
                setPackagePriceTags(respData?.data)
                printPackagePriceTag();
                history.push('/inventory/packagepricetag')
              }
              else if (respData?.status == 400) {
                seterrorMsgCondition(true)
                seterrorContent(packageres?.data?.errors[0]?.error)
              }
              else {
                setErrorMsg(true)
              }
            }
            else if (packageres?.status == 400) {
              seterrorMsgCondition(true)
              seterrorContent(packageres?.data?.errors[0]?.error)
            }
            else {
              setErrorMsg(true)
            }
          }
          else if (response?.status == 400) {
            seterrorMsgCondition(true)
            seterrorContent(response?.data?.errors[0]?.error)
          }
          else {
            setErrorMsg(true)
          }
        }

        else {
          const res = {
            packageInformation: {
              packageName: internalContextData.itemPricing[0].packageName,
              packageRate: parseFloat(internalContextData.agreementPricing.agreementRate),
              packageTerm: parseInt(internalContextData.agreementPricing.agreementTerm)
            },
            inventoryNumbers: internalContextData.itemPricing.map((e: any) => e.inventoryNumber),
            storeNumber: sessionStorage.getItem('storeNumber')
          }
          const packageres = await createPackage(res)
          if (packageres?.status == 200) {
            const packageId = packageres?.data?.packagePriceId
            setprintCreatePackage([packageId])
            const respData = await getPackagePriceTag({ packagePriceIds: [parseInt(packageId)] });
            setPrint(false)
            if (respData?.status == 200) {
              setPackagePriceTags(respData?.data)
              printPackagePriceTag();
              history.push('/inventory/packagepricetag')
              // printHTML(document.getElementById('PackagePriceTagList'));
            }
            else if (respData?.status == 400) {
              seterrorMsgCondition(true)
              seterrorContent(packageres?.data?.errors[0]?.error)
            }
            else {
              setErrorMsg(true)
            }
          }
          else if (packageres?.status == 400) {
            seterrorMsgCondition(true)
            seterrorContent(packageres?.data?.errors[0]?.error)
          }
          else {
            setErrorMsg(true)
          }
        }


      }



      req1()


    }

  }

  /*
   * calls
   * This RACModalCard materialUI Popup calls.
   * */
  const methodAssigner: any = {
    closeGroupPopCaller: closeGroupPop,
    acceptBreakPackageCaller: acceptBreakPackage,
    declineBreakPackageCaller: declineBreakPackage,
    closepackageExceptionControllerCaller: closepackageExceptionController,
    addAllItemCaller: addAllItem,
    closeRateTermPopUpCaller: closeRateTermPopUp,
    declineAddOtherCaller: declineAddOther,
    declineAddingCaller: declineAdding,
    acceptPackageCriteriaCaller: acceptPackageCriteria,
    closeModalCaller: closeModal,
    acceptPricingFailCaller: closeAcceptPricingFail,
  };
  const noRecordPopup = () => {
    return (
      <Grid>
        <Grid>
          <Grid className={classes.textCenter}>
            <AlertIcon />
            <Typography >
              {' '}
              Something Went Wrong.
            </Typography>

          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => setErrorMsg(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const errorMessagePopup = () => {
    return (
      <Grid>
        <Grid>
          <Grid className={classes.textCenter}>
            <AlertIcon />
            <Typography >
              {' '}
              {errorContent}
            </Typography>

          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => seterrorMsgCondition(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const buildPackageSelection = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            Please select a Package to Continue{' '}
            {/* <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              {receivedpackage}
            </Typography> */}
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setopenPackageSelection(false)}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const buildPackageOneItemValidation = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            Package requires more than one item.No Package will be Created{' '}
            {/* <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              {receivedpackage}
            </Typography> */}
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setsingleItemvalidate(false);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const minMaxValidator = () => {

    const captureObjectIndex: any = [];
    let packageMapper = packageCriteria;
    if (packageMapper !== undefined && packageMapper !== null) {
      const result = packageMapper.map(function (obj: any) {
        return Object.keys(obj).reduce(function (r: any, key: any) {
          const k = key.includes('minQuantity') ? 'minPackageQuantity' : key;
          const l = key.includes('maxQuantity') ? 'maxPackageQuantity' : key;

          r[k] = obj[key];
          r[l] = obj[key];

          return r;
        }, {});
      });

      packageMapper = result;

      console.log('renamed object', result);
    }

    // console.log('props.throwpackageCriteria', props.throwpackageCriteria);
    if (
      packageMapper !== undefined &&
      packageMapper !== null &&
      bucketCopy !== undefined
    ) {
      console.log('packageMapper in footer', packageMapper);
      console.log('props.throwCopy in footer', bucketCopy);
      bucketCopy?.map((cpyObj: any) => {
        console.log('cpyObj.departmentsEn', cpyObj.departmentsEn);
        packageMapper.map((pkgObj: any) => {
          console.log('pkgObj.rmsDepartment', pkgObj.rmsDepartment);
          console.log('pkgObj.rmsSubDepartment', pkgObj.rmsSubDepartment);
          console.log('pkgObj.rmsBracket', pkgObj.rmsBracket);
          if (
            (pkgObj.rmsDepartment == null ||
              cpyObj.departmentsEn == pkgObj.rmsDepartment) &&
            (pkgObj.rmsSubDepartment == null ||
              cpyObj.subDepartmentsEn == pkgObj.rmsSubDepartment) &&
            (pkgObj.rmsBracket == null ||
              cpyObj.bracketEn == pkgObj.rmsBracket) &&
            (pkgObj.rmsItemMasterId == null ||
              cpyObj.rmsItemMasterId == pkgObj.rmsItemMasterId)
          ) {
            console.log('reached decrement');
            console.log('cpyObj.departmentsEn', cpyObj.departmentsEn);
            console.log('pkgObj.rmsBracket', pkgObj.rmsBracket);
            console.log('pkgObj.rmsItemMasterId', pkgObj.rmsBracket);
            pkgObj.minPackageQuantity = pkgObj.minPackageQuantity - 1;
            pkgObj.maxPackageQuantity = pkgObj.maxPackageQuantity - 1;
          }
        });
      });
      console.log('data inside package mapper', packageMapper);
    }

    if (packageMapper !== undefined && packageMapper !== null) {
      console.log('packageMapper', packageMapper);
      packageMapper.map((pkgObj: any, pkgIndex: any) => {
        if (pkgObj.minPackageQuantity > 0 || pkgObj.maxPackageQuantity < 0) {
          captureObjectIndex.push(pkgIndex);
        }
      });
    }

    console.log('captureObjectIndex.data', captureObjectIndex);
    if (captureObjectIndex.length == 0) {
      OnPrintPrice('Rent Ready')
    } else {
      console.log('trigger error popup');

      minMaxException(captureObjectIndex);
    }
  };

  const minMaxException = (index: any) => {
    console.log('index log of fault package', index);

    if (packageCriteria !== undefined) {
      const wordingArray: any = [
        'Package missing required component(s).No package will be created.',
      ];
      index.map((indexObj: any, index: any) => {
        packageCriteria.map((obj: any, pkgindex: any) => {
          if (pkgindex == indexObj) {
            console.log('popup rmsDepartment', obj.rmsDepartment);
            console.log('popup minQuantity', obj.minQuantity);
            console.log('popup maxQuantity', obj.maxQuantity);
            const wordFormation = `${obj.rmsDepartment} Minimum: ${obj.minQuantity} Maximum: ${obj.maxQuantity}`;
            wordingArray.push(wordFormation);
            // setformException(
            //   `Items in the Agreement do not meet the Package Criteria. Please check the Item in the Agreement to Continue. ${obj.rmsDepartment} Minimum: ${obj.minQuantity} Maximum: ${obj.maxQuantity}`
            // );
          }
        });
      });
      console.log('wordingArray finally', wordingArray);

      if (wordingArray !== undefined && wordingArray.length > 0) {
        let str: any = '';
        wordingArray.map((el: any) => {
          str = str + el;
        });
        console.log('str formed value', str);

        setformException(str);
      }
      setmodalController({ ...modalController, PackageExceptionPopup: true });
    }
  };
  const mainPrint = () => {
    if (isPackage === false && itemArray.length > 1 && selectedPackage !== "") {
      setopenPackageSelection(true);
    }
    else if (isPackage === false && itemArray.length == 1) {
      setsingleItemvalidate(true)
    }
    else {
      minMaxValidator()
    }
  }
  return (
    <div className={classes.agreementContainerSpacing}>
      <div>
        {loaderBundle.masterLoader ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        {secondLvlAuth ? (
          <SecondFactor
            setTwoFactorCancelClick={() => setsecondLvlAuth(false)}
            setTwoFactorCompleted={() => handlePrintPackageOnClick()}
            moduleName={moduleName}
            CurrentRole={currentCWRole}
          ></SecondFactor>
        ) : null}
        <Grid container spacing={2} className={dynamicViewClass}>
          <Grid item className={altersearch}>
            <SearchFilter
              searchClick={searchClick}
              // PackagedropdownOnClick={PackagedropdownOnClick}
              IsPackage={true}
              clearbtnClick={clearbtnClick}
              triggerItemPriceByData={pricingCallTrigger}
              throwStyle={classes}
              throwSalesLead={salesLeadData}
              throwPackage={receivedpackage}
              throwSelectedPackage={storeSelectedPackage}
              throwItemArray={itemArray}
              throwpackageList={packageList}
            />
            <div>
              {loaderBundle.loaderController === true &&
                loaderBundle.Gridloader === true ? (
                <ExceptionController
                  throwExceptionDecider="Data Delay"
                  throwStyle={classes}
                />

              ) : (
                gridCallMethod()
              )}
            </div>
            <ModalContainer
              throwModalController={modalController}
              throwMethodAssigner={methodAssigner}
              throwPricingException={pricingException}
              throwFormException={formException}
              throwPriceExceptionMessage={PricexeptionMessage}
            />
          </Grid>
          {showAddedItem ? (
            <Usercontext.Provider value={internalContextData}>
              <AddedItem
                triggeroneDelete={deleteSingleItem}
                setreceiveAgreementRate={receiveAgreementRate}
                setreceiveAgreementTerm={receiveAgreementTerm}
                module="Agreement"
                throwPackage={receivedpackage}
                priceexceptionMessage={pricingException}
                triggerSetToDefault={calculateSetToDefault}
                throwStyle={classes}
              />
            </Usercontext.Provider>
          ) : null}
        </Grid>
        <Grid className={classes.fixedBottom} style={{ display: 'block' }}>
          <Grid className={classes.floatRight}>
            <RACButton
              color='primary'
              variant="contained"
              // disabled={packagebtn}
              // disabled={print}
              disabled={internalContextData?.itemPricing?.length > 0 ? false : true}
              loading={print}
              onClick={() => mainPrint()}
              style={{ marginRight: '4px' }}
            >
              {packageText ? 'Print Price Tag' : 'Save & Print Price Tag'}
            </RACButton>
          </Grid>
        </Grid>
        <RACModalCard
          isOpen={errorMsg}
          closeIcon={false}
          maxWidth="xs"
          borderRadius={"9px !important"}

        >

          {noRecordPopup()}

        </RACModalCard>
        <RACModalCard
          isOpen={errorMsgCondition}
          closeIcon={false}
          maxWidth="xs"
          borderRadius={"9px !important"}

        >

          {errorMessagePopup()}

        </RACModalCard>
        <RACModalCard
          isOpen={openPackageSelection}
          maxWidth="xs"
          borderRadius={'25px !important'}
          //eslint-disable-next-line react/no-children-prop
          children={buildPackageSelection()}
        ></RACModalCard>
        <RACModalCard
          isOpen={singleItemvalidate}
          maxWidth="xs"
          borderRadius={'25px !important'}
          //eslint-disable-next-line react/no-children-prop
          children={buildPackageOneItemValidation()}
        ></RACModalCard>
        <div id="PackagePriceTagList" style={{ display: 'none' }}>
          <PrintPackagePrice
            packageIdsToPrint={printCreatePackage}
            detailsToPrint={packagePriceTags}
            language={"English"}
          />
        </div>

      </div>
    </div>
  );
};

export default SearchResult1;
