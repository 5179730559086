/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect, useContext, useReducer, useRef } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from 'react-router';
import { InventoryContext } from '../../../context/InventoryInfoContext';
import moment from "moment";
import { truncString, validateObject } from "../../Shared/validateObject";
import { Grid,RACButton,Card, CardContent, Typography, RACSelect, CircularProgress } from '@rentacenter/racstrap';
import { InventoryDetails,LoadInventoryInformation} from '../../../api/user';
import { globalStyles } from "../../componentstyles/globalstyles"
import { formStyles } from "../../componentstyles/formstyles";
import PrintInfo from '../printInfo'
import { printHTML } from '../../Shared/print'
import { useParams } from 'react-router-dom';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
// import ReactToPrint from 'react-to-print';

interface ParamType {
  inventoryNum: string;
}

export const Details = (props: any) => {

  const storeNo: any = sessionStorage.getItem('storeNumber');
  const classes = globalStyles();
  const form = formStyles();
  const history = useHistory();
  const componentRef = useRef(null);
  const { inventoryNum } = useParams<ParamType>();
  const [inventoryInformations, setinventoryInformations] = useState<any>(
    {
      inventoryHeaderObj: {},
      depreciationEstimatorObj: {},
      gaapDepreciationObj: {},
      idleObj: {}
    }
  )
  const [inventoryInfo, setinventoryInfo] = useState<any>(
    {
      inventoryHeaderObj: {},
      inventoryInformationObj: {},
      currentAgreementObj: {},
      chargedOffObj: {},
      recoveryObj: {}
    }
  )
  const { headerValue, tabDisableContext } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const [tabDisable, settabDisable] = tabDisableContext;


  const [responseLoader, setresponseLoader] = useState({
    loader1: false,
    loader2: true
  });
  const [somethingWentWrong, setsomethingWentWrong]: any = React.useState(false);



 

  const InitialLoad = async (inventoryNumber: any, type?: any) => {

    if (validateObject(props) && validateObject(props.response) && validateObject(props.response.data)) {
      console.log('props-response', props.response);

      setresponseLoader({ ...responseLoader, loader1: true, loader2: false })
      const response = type === 'Y' ? await InventoryDetails(inventoryNumber) : props.response;
      setresponseLoader({ ...responseLoader, loader1: false, loader2: false })

      console.log('inventoryInfo-apiResponse', response)

      if (response.status == 400 || response.status == 500) {
        setsomethingWentWrong(true);
      }

      if (validateObject(response) &&
        response.status == 200 &&
        validateObject(response.data) &&
        ((validateObject(response.data.inventoryHeader) && validateObject(response.data.inventoryHeader.status)) ||
          validateObject(response.data.depreciationEstimator) ||
          validateObject(response.data.gaapDepreciation) ||
          validateObject(response.data.idle) 
         
        )
      ) {
        setinventoryInformations({
          ...inventoryInformations,
          inventoryHeaderObj: response.data.header,
          depreciationEstimatorObj: response.data.depreciationEstimator,
          gaapDepreciationObj: response.data.gaapDepreciation,
          idleObj: response.data.idle,
          
        })

        props.setData(response);

      }
    }
    if (validateObject(props) && validateObject(props.inventoryInfo) && validateObject(props.inventoryInfo.data)) {
        console.log('props-response', props.inventoryInfo);

        const response = type === 'Y' ? await LoadInventoryInformation(inventoryNumber) : props.inventoryInfo;
      
  
        console.log('inventoryInfo-apiResponse', response)

  
        if (validateObject(response) &&
          response.status == 200 &&
          validateObject(response.data) &&
          ((validateObject(response.data.inventoryHeader) && validateObject(response.data.inventoryHeader.status)) ||
            validateObject(response.data.inventoryInformation) ||
            validateObject(response.data.currentAgreement) ||
            validateObject(response.data.chargedOff) ||
            validateObject(response.data.recovery)
          )
        ) {
            setinventoryInfo({
            ...inventoryInfo,
            inventoryHeaderObj: response.data.inventoryHeader,
            inventoryInformationObj: response.data.inventoryInformation,
            currentAgreementObj: response.data.currentAgreement,
            chargedOffObj: response.data.chargedOff,
            recoveryObj: response.data.recovery
          })
         
        }
      }
  }

  useEffect(() => {
    
    InitialLoad(inventoryNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);



    const onClickCancelBtn = () => {
      history.push(`/inventory/inventorysearch`)
    }

    const roundOff = (num: any, places: number) => {
      const x = Math.pow(10, places);
      return Math.round(num * x) / x;
    }



    return (
      <>
        {responseLoader.loader1 || responseLoader.loader2 == true ?
          <div>
            <div className={classes.racLoaderPage}>
              <CircularProgress style={{ marginTop: '0px' }} />
            </div>
          </div>
          :
          somethingWentWrong == true ?
            <>
              <div className={`${classes.textCenter} ${classes.mt5}`}>
                <AlertIcon />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  Something Went Wrong
                </Typography>
              </div>
            </>
            :
            <Grid>
              <Grid container className={classes.mbFooterSpacing}>
                <Grid item md={12}>

                    {/* DepreciationEstimator starts here */}

                  {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.depreciationEstimatorObj) ?
                    <>
                      <Grid className={classes.mt4}>
                        <Typography variant="h5" className={classes.m2}>
                          Depreciation Estimator
                        </Typography>
                      </Grid>
                      <Card className={`${classes.m2} ${classes.racCard}`}>
                        <CardContent>
                          <Grid container spacing={4} style={{ marginBottom: '10px' }} className={classes.alignCenter}>
                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>PO # </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`} style={{ color: '#0d6efd' }}>
                                {validateObject(inventoryInformations.depreciationEstimatorObj.po) ? inventoryInformations.depreciationEstimatorObj.po : '-'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>Vendor </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.depreciationEstimatorObj.vendor) && inventoryInformations.depreciationEstimatorObj.vendor.length > 20 ? inventoryInformations.depreciationEstimatorObj.vendor : ''}>{validateObject(inventoryInformations.depreciationEstimatorObj.vendor) ? truncString(inventoryInformations.depreciationEstimatorObj.vendor, 20, '...') : '-'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>Brand </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.depreciationEstimatorObj.brand) && inventoryInformations.depreciationEstimatorObj.brand.length > 25 ? inventoryInformations.depreciationEstimatorObj.brand : ''}>{validateObject(inventoryInformations.depreciationEstimatorObj.brand) ? truncString(inventoryInformations.depreciationEstimatorObj.brand, 25, '...') : '-'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>Model # </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.depreciationEstimatorObj.modelNumber) && inventoryInformations.depreciationEstimatorObj.modelNumber.length > 25 ? inventoryInformations.depreciationEstimatorObj.modelNumber : ''}>{validateObject(inventoryInformations.depreciationEstimatorObj.modelNumber) ? truncString(inventoryInformations.depreciationEstimatorObj.modelNumber, 25, '...') : '-'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>Serial # </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`} title={validateObject(inventoryInformations.depreciationEstimatorObj.serialNumber) && inventoryInformations.depreciationEstimatorObj.serialNumber.length > 25 ? inventoryInformations.depreciationEstimatorObj.serialNumber : ''}>{validateObject(inventoryInformations.depreciationEstimatorObj.serialNumber) ? truncString(inventoryInformations.depreciationEstimatorObj.serialNumber, 25, '...') : '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>Purchase Date </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.depreciationEstimatorObj.purchaseDate) ? moment(inventoryInformations.depreciationEstimatorObj.purchaseDate).format('MM/DD/YYYY') : '-'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>Purchase Cost </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>
                                {validateObject(inventoryInformations.depreciationEstimatorObj.purchaseCost) && (inventoryInformations.depreciationEstimatorObj.purchaseCost !== "0" || inventoryInformations.depreciationEstimatorObj.purchaseCost !== "0.00") ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.depreciationEstimatorObj.purchaseCost, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  /> : '$ 0.00'}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>Total Revenue </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>
                                {validateObject(inventoryInformations.depreciationEstimatorObj.totalRevenue) && (inventoryInformations.depreciationEstimatorObj.totalRevenue !== "0" || inventoryInformations.depreciationEstimatorObj.totalRevenue !== "0.00") ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.depreciationEstimatorObj.totalRevenue, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  /> : '$ 0.00'}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>Revenue Turns </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.depreciationEstimatorObj.revenueTurns) && inventoryInformations.depreciationEstimatorObj.revenueTurns !== 0 ? inventoryInformations.depreciationEstimatorObj.revenueTurns : '0.00'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2} >
                              <Typography className={form.formLabel}>Remaining Value </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>
                                {validateObject(inventoryInformations.depreciationEstimatorObj.remainingValue) && (inventoryInformations.depreciationEstimatorObj.remainingValue !== "0" || inventoryInformations.depreciationEstimatorObj.remainingValue !== "0.00") ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.depreciationEstimatorObj.remainingValue, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  /> : '$ 0.00'}
                              </Typography>
                            </Grid>
                          </Grid>

                        </CardContent>
                      </Card>
                    </>
                    : null}
                    {/* DepreciationEstimator ends here */}
                    {/* GAAPDepreciation starts here */}
                  {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.gaapDepreciationObj) ?
                    <>
                      <Grid className={classes.mt4}>
                        <Typography variant="h5" className={classes.m2}>
                          GAAP Depreciation
                        </Typography>
                      </Grid>
                      <Card className={`${classes.m2} ${classes.racCard}`}>
                        <CardContent>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={4} md={4} className={classes.fourColumns}>
                              <Typography className={form.formLabel}>Depreciation Method # </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.gaapDepreciationObj.depreciationMethod) ? inventoryInformations.gaapDepreciationObj.depreciationMethod : "N/A"}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} className={classes.fourColumns}>
                              <Typography className={form.formLabel}>Depreciation Percentage </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.gaapDepreciationObj.depreciationPercentage) && (inventoryInformations.gaapDepreciationObj.depreciationPercentage)  ? roundOff((inventoryInformations.gaapDepreciationObj?.depreciationPercentage), 3).toFixed(2) + ' %' : "N/A"}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} className={classes.fourColumns}>
                              <Typography className={form.formLabel}>Gross Profit Per Payment </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>
                                {validateObject(inventoryInformations.gaapDepreciationObj.grossProfitPerPayment) && ( inventoryInformations.gaapDepreciationObj.grossProfitPerPayment)  ?
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={roundOff(inventoryInformations.gaapDepreciationObj.grossProfitPerPayment, 3).toFixed(2)}
                                    prefix="$ "
                                    className={classes.formLabelValue}
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                  /> : "N/A"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} className={classes.fourColumns}>
                              <Typography className={form.formLabel}>Turns </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.gaapDepreciationObj.turns) && (inventoryInformations.gaapDepreciationObj.turns)  ? roundOff(inventoryInformations.gaapDepreciationObj.turns, 3).toFixed(2) : "-"}</Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </>
                    : null}
                    {/* GAAPDepreciation ends here */}
                    {/* Idle starts here */}
                  {/* {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.status) && validateObject(inventoryInformations.idleObj) && (inventoryInformations.inventoryHeaderObj.status === RENT_READY || inventoryInformations.inventoryHeaderObj.status === NOT_RENT_READY || inventoryInformations.inventoryHeaderObj.status === PENDING_CHARGE_OFF || inventoryInformations.inventoryHeaderObj.status === PENDING_LOANER_AGREEMENT || inventoryInformations.inventoryHeaderObj.status === PENDING_RENTAL_AGREEMENT ||
                    inventoryInformations.inventoryHeaderObj.status === HOLD || inventoryInformations.inventoryHeaderObj.status === TRANSFER_IN_PROGRESS || inventoryInformations.inventoryHeaderObj.status === SERVICE_IDLE || inventoryInformations.inventoryHeaderObj.status === RESERVED) ? */}
                    {validateObject(inventoryInformations.idleObj)?
                    <>
                      <Grid className={classes.mt4}>
                        <Typography variant="h5" className={classes.m2}>
                          Idle
                        </Typography>
                      </Grid>
                      <Card className={`${classes.m2} ${classes.racCard}`}>
                        <CardContent>
                          <Grid container spacing={4} style={{ marginBottom: '10px' }} className={classes.alignCenter}>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}>Idle Since </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.idleSince) ? moment(inventoryInformations.idleObj.idleSince).format('MM/DD/YYYY') : '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}>Days Idle </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.daysIdle) && inventoryInformations.idleObj.daysIdle !== 0 ? inventoryInformations.idleObj.daysIdle : '0'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}>#Days Idle in Current Store </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.daysIdleInCurrentStore) && inventoryInformations.idleObj.daysIdleInCurrentStore !== 0 ? inventoryInformations.idleObj.daysIdleInCurrentStore : '0'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}># Time 90 Days Idle </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.times90DaysIdle) && inventoryInformations.idleObj.times90DaysIdle !== "0" ? inventoryInformations.idleObj.times90DaysIdle : '0'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}>Total Days On Rent </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.totalDaysOnRent) && inventoryInformations.idleObj.totalDaysOnRent !== 0 ? inventoryInformations.idleObj.totalDaysOnRent : '0'}</Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}>Hold Date </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.holdDate) ? moment(inventoryInformations.idleObj.holdDate).format('MM/DD/YYYY') : '-'}</Typography>
                            </Grid> */}


                            {/* <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}>Days On Hold </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.daysOnhold) && inventoryInformations.idleObj.daysOnhold !== 0 ? inventoryInformations.idleObj.daysOnhold : '0'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}>Total Days On Hold </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.totalDaysOnHold) && inventoryInformations.idleObj.totalDaysOnHold !== "0" ? inventoryInformations.idleObj.totalDaysOnHold : '0'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}>Total Times On Hold </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.totalTimesOnHold) && inventoryInformations.idleObj.totalTimesOnHold !== "0" ? inventoryInformations.idleObj.totalTimesOnHold : '0'}</Typography>
                            </Grid> */}
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}># Days Rented </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.daysRented) && inventoryInformations.idleObj.daysRented !== "0" ? inventoryInformations.idleObj.daysRented : '0'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography className={form.formLabel}># Times Rented </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.timesRented) && inventoryInformations.idleObj.timesRented !== "0" ? inventoryInformations.idleObj.timesRented : '0'}</Typography>
                            </Grid>

                          </Grid>

                          <Grid container spacing={1} className={classes.alignCenter}>


                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '8px' }}>
                              <Typography variant="h5" className={classes.mt2}>
                                Store #
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography variant="caption" className={form.formLabel} style={{width:'100%',float:'left'}}>Physical Store</Typography>
                              <Typography variant="caption" className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.physicalStore) ? inventoryInformations.idleObj.physicalStore : '-'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2}>
                              <Typography variant="caption" className={form.formLabel} style={{width:'100%',float:'left'}}>Financial Store</Typography>
                              <Typography variant="caption" className={`${form.formValue} ${classes.mt2}`}>{validateObject(inventoryInformations.idleObj.financialStore) ? inventoryInformations.idleObj.financialStore : '-'}</Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </>

                    : null}
                    {/* Idle starts here */}

                </Grid>
              </Grid>


              {/* --- Footer Code Starts Here ---  */}
              <Grid container style={{ zIndex: '0' }} className={classes.fixedFooter}>
                <Grid className={classes.floatLeft}>
                  <RACButton variant="outlined" color="primary" className={classes.ms2} onClick={() => onClickCancelBtn()} >Cancel</RACButton>
                </Grid>
                {headerContext?.storeNumber===storeNo?
                <Grid className={classes.floatRight}>
                  <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.mr2}
                  onClick={() => printHTML(document.getElementById('InventoryPrintHTML'))}
                >
                  Print
                </RACButton>
                  <>
                    {/* <ReactToPrint
                      trigger={() =>
                        <RACButton
                          variant="contained"
                          color="primary"
                          className={classes.mr2}
                        >
                          Print
                        </RACButton>}
                      content={() => componentRef.current}
                      documentTitle="Inventory Item Information"
                    /> */}
                    <div className={classes.hide}>
                    <PrintInfo  inventoryInfoRes={inventoryInfo} inventoryDetail={inventoryInformations}/>
                      {/* <div ref={componentRef} id="InventoryPrintHTML" style={{margin:'5%'}}>
                        <div style={{ width: '96%', margin: '2%', float: 'left' }}>
                          <div style={{ width: '100%', float: 'left', marginBottom: '6px' }}>
                            <h2 style={{ float: 'left', margin: '0px' }}>Inventory Item Information</h2>
                            <div style={{ float: 'right', marginTop: '5px', fontStyle: 'italic' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
                          </div>

                          <div style={{ width: '100%', float: 'left', marginBottom: '4px' }}>
                            <div style={{ width: '20%', float: 'left' }}>
                              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Rent-A-Center</span>
                              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}({storeNo})</span>
                            </div>
                            <div style={{ width: '30%', float: 'left' }}>
                              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Item #:</span>
                              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}{inventoryNum}</span>
                            </div>
                            <div style={{ width: '50%', float: 'left' }}>
                              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Description:</span>
                              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}{headerContext.description}</span>
                            </div>
                          </div>

                          {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.depreciationEstimatorObj) ?
                            <div style={{ width: '100%', float: 'left' }}>
                              <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Depreciation Estimator</h3>
                              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>PO #</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.po) ? inventoryInformations.depreciationEstimatorObj.po : '-'}</div>
                                  </span>
                                </div>
                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Purchase Date</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.purchaseDate) ? moment(inventoryInformations.depreciationEstimatorObj.purchaseDate).format('MM/DD/YYYY') : '-'}</div>
                                  </span>
                                </div>
                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Brand</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.brand) ? inventoryInformations.depreciationEstimatorObj.brand : '-'}</div>
                                  </span>
                                </div>
                              </div>

                              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Purchase Cost</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.purchaseCost) && (inventoryInformations.depreciationEstimatorObj.purchaseCost !== "0" || inventoryInformations.depreciationEstimatorObj.purchaseCost !== "0.00") ?
                                      <NumberFormat
                                        thousandsGroupStyle="thousand"
                                        value={roundOff(inventoryInformations.depreciationEstimatorObj.purchaseCost, 3).toFixed(2)}
                                        prefix="$ "
                                        className={classes.formLabelValue}
                                        displayType="text"
                                        type="text"
                                        thousandSeparator={true}
                                      /> : '$ 0.00'}
                                    </div>
                                  </span>
                                </div>
                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Model #</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.modelNumber) ? inventoryInformations.depreciationEstimatorObj.modelNumber : '-'}</div>
                                  </span>
                                </div>
                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Vendor</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.vendor) ? inventoryInformations.depreciationEstimatorObj.vendor : '-'}</div>
                                  </span>
                                </div>
                              </div>

                              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>

                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Serial #</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.depreciationEstimatorObj.serialNumber) ? inventoryInformations.depreciationEstimatorObj.serialNumber : '-'}</div>
                                  </span>
                                </div>
                              </div>
                            </div> : null}

                          {validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.gaapDepreciationObj) ?
                            <div style={{ width: '100%', float: 'left' }}>
                              <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>GAAP Depreciation</h3>
                              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>

                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Depreciation Method</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.gaapDepreciationObj.depreciationMethod) && (inventoryInformations.gaapDepreciationObj.depreciationMethod !== "0" || inventoryInformations.gaapDepreciationObj.depreciationMethod !== "0.00") ? inventoryInformations.gaapDepreciationObj.depreciationMethod : "N/A"}</div>
                                  </span>
                                </div>
                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Depreciaton Percentage</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.gaapDepreciationObj.depreciationPercentage)  && (inventoryInformations.gaapDepreciationObj.depreciationPercentage)  ? roundOff((inventoryInformations.gaapDepreciationObj?.depreciationPercentage), 3).toFixed(2) + ' %' : "N/A"}</div>
                                  </span>
                                </div>
                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Gross Profit Per Payment</div>
                                    <div style={{ fontSize: '14px' }}>
                                      {validateObject(inventoryInformations.gaapDepreciationObj.grossProfitPerPayment) && (inventoryInformations.gaapDepreciationObj.grossProfitPerPayment) ?
                                        <NumberFormat
                                          thousandsGroupStyle="thousand"
                                          value={roundOff(inventoryInformations.gaapDepreciationObj.grossProfitPerPayment, 3).toFixed(2)}
                                          prefix="$ "
                                          className={classes.formLabelValue}
                                          displayType="text"
                                          type="text"
                                          thousandSeparator={true}
                                        /> : "N/A"}
                                    </div>
                                  </span>
                                </div>
                              </div>

                              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                                <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                  <span>
                                    <div style={{ fontSize: '14px' }}>Turns</div>
                                    <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.gaapDepreciationObj.turns) && (inventoryInformations.gaapDepreciationObj.turns) ? roundOff(inventoryInformations.gaapDepreciationObj.turns, 3).toFixed(2) : "-"}</div>
                                  </span>
                                </div>
                              </div>
                            </div> : null}

                            {validateObject(inventoryInformations.idleObj)?
                            <>
                              <div style={{ width: '100%', float: 'left' }}>
                                <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Idle</h3>
                                <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                                  <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                    <span>
                                      <div style={{ fontSize: '14px' }}>Idle Since</div>
                                      <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.idleSince) ? moment(inventoryInformations.idleObj.idleSince).format('MM/DD/YYYY') : '-'}</div>
                                    </span>
                                  </div>
                                  <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                    <span>
                                      <div style={{ fontSize: '14px' }}># Times 90 Days Idle</div>
                                      <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.times90DaysIdle) ? inventoryInformations.idleObj.times90DaysIdle : '-'}</div>
                                    </span>
                                  </div>
                                  <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                    <span>
                                      <div style={{ fontSize: '14px' }}>Days Idle</div>
                                      <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.daysIdle) && inventoryInformations.idleObj.daysIdle !== 0 ? inventoryInformations.idleObj.daysIdle : '0'}</div>
                                    </span>
                                  </div>
                                </div>

                                <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                                  <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                    <span>
                                      <div style={{ fontSize: '14px' }}>Total Days on Rent</div>
                                      <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.totalDaysOnRent) && inventoryInformations.idleObj.totalDaysOnRent !== 0 ? inventoryInformations.idleObj.totalDaysOnRent : '0'}</div>
                                    </span>
                                  </div>
                                  <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                    <span>
                                      <div style={{ fontSize: '14px' }}># Days Idle in Current Store</div>
                                      <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.daysIdleInCurrentStore) ? inventoryInformations.idleObj.daysIdleInCurrentStore : '-'}</div>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div style={{ width: '100%', float: 'left', marginBottom: '-12px' }}>
                                <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px', marginBottom: '0px' }}>Store #</h3>
                                <div style={{ width: '100%', float: 'left' }}>
                                  <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                    <span>
                                      <div style={{ fontSize: '14px' }}>Physical Store</div>
                                      <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.physicalStore) ? inventoryInformations.idleObj.physicalStore : '-'}</div>
                                    </span>
                                  </div>
                                  <div style={{ width: '31.3%', margin: '1%', float: 'left', marginBottom: '10px' }}>
                                    <span>
                                      <div style={{ fontSize: '14px' }}>Financial Store</div>
                                      <div style={{ fontSize: '14px' }}>{validateObject(inventoryInformations.idleObj.financialStore) ? inventoryInformations.idleObj.financialStore : '-'}</div>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                            : null}



                        </div>
                      </div> */}
                    </div>
                  </>
                  
                </Grid>:null}
              </Grid>
              <Grid>
                {/* --- Footer Code Ends Here --- */}

                {/* --- Inv Info Html Code ends Here --- */}
              </Grid>
            </Grid>
        }
      </>
    );
  }
  export default Details;