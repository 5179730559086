/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, Route, Switch } from 'react-router-dom';
import InventoryHeader from '../Shared/InventoryHeader';
import Inventoryinfo from './InventoryInfo/InventoryInformation';
import Details from './Details/Details';
import AgreementHistory from './AgreementHistory/AgreementHistory';
import ActivitiesInfo from '../InventoryTabs/Activities/Activities';
import IdleInventoryAudit from './IdleInventoryAudit/IdleInventoryAudit';
import PriceTag from './PriceTag/PriceTag';
import {
  LoadInventoryInformation,
  LoadIdleInventoryAudit,
  LoadAgreementHistory,
  getInventoryPriceTags,
  getActivities,
  InventoryDetails
} from '../../api/user';
import { AppRoute } from '../../config/route-config';
import { getPriceTagsReq } from './PriceTag/PriceTagProps';

interface ParamType {
  inventoryNum: string;
}
export const InventoryInfoRoute = () => {
  const { inventoryNum } = useParams<ParamType>();
  const [InventoryInfoData, setInventoryInfoData] = useState();
  const [InventoryDetailsData, setInventoryDetailsData] = useState({});
  const [IdleInvAuditData, setIdleInvAuditData] = useState();
  const [PriceTagData, setPriceTagData] = useState();
  const [AgrHistoryData, setAgrHistoryData] = useState();
  const [ActivitiesData, setActivitiesData] = useState();
  const [GotApiResponse,setGotApiResponse] =useState(false);

  useEffect(() => {
    if(!GotApiResponse)
    {
      PageReload('initialPageload');
    }    
  }, []);
  const PageReload =(action:string)=>{
    console.log('PageReload');
    
    const AllTabsLoad = async (action:string) => {

      if(action=='initialPageload'){
        const InventoryInfo = new Promise(() => {
          const GetResponse = async () => {
            const InventoryInfoResponse = await LoadInventoryInformation(
              inventoryNum
            );
            setInventoryInfoData(InventoryInfoResponse);
            console.log('InventoryInfoResponse', InventoryInfoResponse);
          };
          GetResponse();
        });
        const InventoryDetailsRes = new Promise(() => {
          const GetResponse = async () => {
            const InventoryDetailsResponse = await InventoryDetails(
              inventoryNum
            );
            setInventoryDetailsData(InventoryDetailsResponse);
            console.log('InventoryInfoResponse', InventoryDetailsResponse);
          };
          GetResponse();
        });
        const InventoryAudit = new Promise(() => {
          const GetResponse = async () => {
            const InventoryAuditResponse = await LoadIdleInventoryAudit(
              inventoryNum
            );
            setIdleInvAuditData(InventoryAuditResponse);
            console.log('InventoryAuditResponse', InventoryAuditResponse);
          };
          GetResponse();
        });
        const AgrHistory = new Promise(() => {
          const GetResponse = async () => {
            const InventoryAuditResponse = await LoadAgreementHistory(
              inventoryNum
            );
            setAgrHistoryData(InventoryAuditResponse);
            console.log('InventoryAuditResponse', InventoryAuditResponse);
          };
          GetResponse();
        });
        const Activities = new Promise(() => {
          const GetResponse = async () => {
            const ActivitiesResponse = await getActivities(
              inventoryNum
            );
            setActivitiesData(ActivitiesResponse);
            console.log('ActivitiesResponse', ActivitiesResponse);
          };
          GetResponse();
        });
        const PriceTags = new Promise(() => {
              const request :getPriceTagsReq={
                inventoryNumbers:[inventoryNum],
                isCurrentPriceTag:false
              }
          const GetResponse = async () => {
            const InvPriceTagResponse = await getInventoryPriceTags(request);
            setPriceTagData(InvPriceTagResponse);
            console.log('InvPriceTagResponse', InvPriceTagResponse);
          };
          GetResponse();
        });
        Promise.race([InventoryInfo,InventoryDetailsRes,InventoryAudit, AgrHistory, Activities, PriceTags]);
        setGotApiResponse(true);
      }
      else if(action=='rentReady' || action=='chargedOff' || action=='pendingChargeOff' || action=='notRentReady' || action=='cancelChargedOff' || action=='hold' || action=='unHold'){
            const InventoryInfo = new Promise(() => {
              const GetResponse = async () => {
                const InventoryInfoResponse = await LoadInventoryInformation(
                  inventoryNum
                );
                setInventoryInfoData(InventoryInfoResponse);
                console.log('InventoryInfoResponse', InventoryInfoResponse);
              };
              GetResponse();
            });
            const InventoryDetailsRes = new Promise(() => {
              const GetResponse = async () => {
                const InventoryDetailsResponse = await InventoryDetails(
                  inventoryNum
                );
                setInventoryDetailsData(InventoryDetailsResponse);
                console.log('InventoryInfoResponse', InventoryDetailsResponse);
              };
              GetResponse();
            });
            const Activities = new Promise(() => {
              const GetResponse = async () => {
                const ActivitiesResponse = await getActivities(
                  inventoryNum
                );
                setActivitiesData(ActivitiesResponse);
                console.log('ActivitiesResponse', ActivitiesResponse);
              };
              GetResponse();
            });
            Promise.race([InventoryInfo,InventoryDetailsRes,Activities]);
            setGotApiResponse(true);
      }
      
    };

    AllTabsLoad(action);
    }
  return (
    <>
      <InventoryHeader response={InventoryInfoData} GotApiResponse={GotApiResponse}/>
      <Switch>
        <Route exact path={AppRoute.InventoryInformation}>
          <Inventoryinfo response={InventoryInfoData} detailsResponse={InventoryDetailsData} activeResponse={ActivitiesData} setData={setInventoryInfoData} PageReload={PageReload}/>
        </Route>
        <Route exact path={AppRoute.Details}>
          <Details response={InventoryDetailsData} inventoryInfo={InventoryInfoData} activeResponse={ActivitiesData} setData={setInventoryDetailsData}/>
        </Route> 
        <Route exact path={AppRoute.IdleInventoryAudit}>
          <IdleInventoryAudit response={IdleInvAuditData} inventoryInfo={InventoryInfoData} AgreeHistory={AgrHistoryData} inventoryDetails={InventoryDetailsData} activeResponse={ActivitiesData}/>
        </Route>
        <Route exact path={AppRoute.AgreementHistory}>
          <AgreementHistory response={AgrHistoryData} inventoryInfo={InventoryInfoData} idleInventoryRes={IdleInvAuditData} activeResponse={ActivitiesData} inventoryDetails={InventoryDetailsData}/>
        </Route>
        <Route exact path={AppRoute.Activities}>
          <ActivitiesInfo response={ActivitiesData} inventoryInfo={InventoryInfoData} inventoryDetails={InventoryDetailsData} idleInventoryRes={IdleInvAuditData} AgreeHistory={AgrHistoryData}/>
        </Route>
        <Route exact path={AppRoute.PriceTag}>
          <PriceTag invenpricetaginfo={PriceTagData} />
        </Route>
        
      </Switch>
    </>
  );
};
