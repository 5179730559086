/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { formatter, validateData } from '../../constants/CommonMethods';

export interface itemPrintProps {
  printItemNo: any[];
  printLanguage: string;
  printItemResp: any;
}
const Barcode = require('react-barcode');

export default function PrintFunction(props: itemPrintProps) {

  console.log('inside Print Function',props);
  
  const data = validateData(props.printItemResp) ? props.printItemResp : [];
  console.log('responsedata', data);

  const PriceTag = () => {
    return props.printItemNo.map((values: any, index: any) => {
      
        if (
          validateData(data[index]) &&
          validateData(data[index].weeklyRate) &&
          validateData(data[index].brand)
        ) {
           const SplitweeklyRate = data[index].weeklyRate.split('.');
           const brand = data[index].brand.toUpperCase();
        

        return (

          <div key={index} style={{fontFamily: 'Arial',marginTop:'0% important'}}>
              <div style={{width: '100%'}}>
                <h1 style={{fontSize: '25px', textAlign: 'center'}}>
                  {validateData(brand)? brand : '-'}
                </h1>
                <p style={{textAlign: 'center'}}>
                {validateData(data[index]) &&
                  validateData(data[index].bracketDescEn)
                    ? data[index].bracketDescEn
                    : '-'}
                </p>
                
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{width: '90%',display: 'flex', justifyContent: 'center'}}>
                        <div style={{textAlign:'center',display: 'inline-block'}}>
                          <span  style={{fontSize: '50px', fontWeight: 'bold',position: 'relative',top:'-28px'}}>$</span>
                          <span style={{fontSize: '95px', fontWeight: 'bold'}}>
                            {validateData(SplitweeklyRate[0])?SplitweeklyRate[0]:' - '}
                          </span>
                        </div>
                        <div style={{textAlign:'center'}}>
                          <p style={{fontSize: '50px', fontWeight: 'bold',marginBottom:'10px',marginTop:'15px'}}>
                          {validateData(SplitweeklyRate[1])?SplitweeklyRate[1]:' - '}
                          </p>
                          <p style={{margin:'0px 0px 10px 0px'}}>PER WEEK</p>
                          <p style={{margin:'0px 0px 10px 0px'}}>
                          {validateData(data[index]) &&
                              validateData(data[index].weeklyTerm)
                                ? data[index].weeklyTerm
                            : '-'}{' '}                
                            Payments</p>
                          <p style={{margin:'0px 0px 10px 0px'}}>to Own It</p>
                        </div>
                    </div>
                  </div>

                    <p style={{fontSize: '20px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center'}}>Additional Flexible Payment Options:</p>
                    <div style={{width: '100%', float: 'left', marginBottom: '40px'}}>
                      <div style={{width: '33%', float: 'left', borderRight: '1px solid #000'}}>
                        <p style={{textAlign: 'center', fontStyle: 'italic'}}>Bi-weekly</p>
                        <div style={{width: '100%', float: 'left', textAlign: 'center'}}> 
                          <span style={{fontWeight: 'bold'}}>
                          {validateData(data[index]) &&
                            validateData(data[index].biWeeklyRate)
                              ? formatter.format(data[index].biWeeklyRate)
                          : '-'}
                            ,</span>
                          <span style={{marginLeft: '5px'}}>
                            {validateData(data[index]) &&
                              validateData(data[index].biWeeklyTerm)
                                ? (data[index].biWeeklyTerm)
                                : '-'}{' '}
                            Payments 
                          </span>
                        </div>
                      </div>
                      <div style={{width: '33%', float: 'left', borderRight: '1px solid #000'}}>
                        <p style={{textAlign: 'center', fontStyle: 'italic'}}>Semi-monthly</p>
                        <div style={{width: '100%', float: 'left', textAlign: 'center'}}> 
                          <span style={{fontWeight: 'bold'}}>
                          {validateData(data[index]) &&
                            validateData(data[index].semiMonthlyRate)
                              ? formatter.format(data[index].semiMonthlyRate)
                              : '-'}
                            ,
                          </span>
                          <span style={{marginLeft: '5px'}}>
                            {validateData(data[index]) &&
                              validateData(data[index].semiMonthlyTerm)
                                ? (data[index].semiMonthlyTerm)
                                : '-'}{' '}
                            Payments 
                          </span>
                        </div>
                      </div>
                      <div style={{width: '33%', float: 'left'}}>
                        <p style={{textAlign: 'center', fontStyle: 'italic'}}>Monthly</p>
                        <div style={{width: '100%', float: 'left', textAlign: 'center'}}> 
                          <span style={{fontWeight: 'bold'}}>
                            {validateData(data[index]) &&
                              validateData(data[index].monthlyRate)
                                ? formatter.format(data[index].monthlyRate)
                                : '-'}
                            ,</span>
                          <span style={{marginLeft: '5px'}}>
                            {validateData(data[index]) &&
                              validateData(data[index].monthlyTerm)
                              ? (data[index].monthlyTerm)
                              : '-'}{' '}           
                            Payments 
                          </span>
                        </div>
                      </div>
                    </div>
                <p style={{fontSize: '20px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center'}}>Easy Ownership Options:</p>
                <div style={{width: '100%', textAlign: 'center', marginBottom: '10px'}}>
                  <span style={{fontWeight: 'bold'}}>Same As Cash Price (SAC):</span>
                  <span style={{fontWeight: 'bold'}}>
                    {validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                        ? formatter.format(data[index].sameAsCashPrice)
                        : '-'}
                  </span>
                </div>
                <div style={{width: '100%', textAlign: 'center', marginBottom: '10px'}}>
                  <span style={{fontWeight: 'bold'}}>After SAC:</span>
                  <span><span style={{fontWeight: 'bold'}}>50 %</span> off remaining total</span>
                </div>
                <div style={{width: '100%', textAlign: 'center', marginBottom: '30px'}}>
                  <span style={{fontWeight: 'bold'}}>Total Cost: </span>
                  <span>
                    
                  {validateData(data[index]) &&
                    validateData(data[index].weeklyRate)
                      ? formatter.format(data[index].weeklyRate)
                      : '-'}{' '}
                  x{' '}
                  {validateData(data[index]) &&
                    validateData(data[index].weeklyTerm)
                      ? data[index].weeklyTerm
                      : '-'}{' '}
                  </span>
                  <span>payments =</span>
                  <span>{' '}
                  {validateData(data[index]) &&
                    validateData(data[index].totalCost)
                      ? formatter.format(data[index].totalCost)
                      : '-'}
                  </span>
                </div>
                <div style={{width: '100%', textAlign: 'center'}}>
                  <p style={{textAlign: 'center', fontSize: '20px'}}>
                      {validateData(data[index]) &&
                        validateData(data[index].status)
                          ? data[index].status
                          : '-'}{'  '}
                    {/* Previously Rented */}
                    </p>
                  <div className="text-align:center;width:100%">
                    <span>
                      <span>Item: </span>
                      <span>
                      {' '}
                       {validateData(data[index]) &&
                        validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : '-'}{'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Model: </span>
                      <span>
                      {' '}
                       {validateData(data[index]) &&
                          validateData(data[index].model)
                            ? data[index].model
                        : '-'}
                      {'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Serial: </span>
                      <span>
                      {' '}
                       {validateData(data[index]) &&
                        validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : '-'}
                      </span>
                    </span>
                  </div>
                  <div className="text-align:center;" >
                    <Barcode value={data[index].inventoryPriceId} displayValue={false} height={48}/>{' '}
                  </div>
                </div>
              </div>
          </div>
        );
      }
    });
  };

  return <div>{PriceTag()}</div>;
}

// export default PrintFunction
