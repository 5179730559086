export enum AppRoute {
  Index = '/',
  InventorySearch = '/inventory/inventorysearch',
  Placeholder1 = '/placeholder1',
  Placeholder2 = '/placeholder2',
  ItemDetails = '/itemDetails',
  Inventory = '/inventory/:inventoryNum',
  InventoryInformation = '/inventory/:inventoryNum/inventoryinformation',
  Details = '/inventory/:inventoryNum/details',
  PriceTag = '/inventory/:inventoryNum/pricetag',
  Service = '/inventory/:inventoryNum/service',
  IdleInventoryAudit = '/inventory/:inventoryNum/idleinventoryaudit',
  AgreementHistory = '/inventory/:inventoryNum/agreementhistory',
  Activities = '/inventory/:inventoryNum/activities',
  PrintEditPriceTagList = '/inventory/printeditpricetag',
  PackagePriceTagList = '/inventory/packagepricetag',
  ItemSearch = '/inventory/packageitemsearch',
  InventoryTransferReceive = '/inventory/inventorytransfer',
}
