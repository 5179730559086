/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect, useContext, useRef } from "react";
import { IdleInventoryAuditProps } from './IdleInventoryAuditTypeScripts';
import { printHTML } from '../../Shared/print'
import DynamicGridComponent from "./dynamicGrid";
import { useHistory } from 'react-router';
import { InventoryContext } from '../../../context/InventoryInfoContext';
import { validateObject } from "../../Shared/validateObject";
import moment from "moment";
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import {
  Card,
  CardContent,
  Grid,
  RACButton,
  Typography,
} from '@rentacenter/racstrap';
import { globalStyles } from "../../componentstyles/globalstyles"
import ActivitiesPrintInfo from '../ActivitiesPrint'
// import ReactToPrint from 'react-to-print';

export const IdleInventoryAudit = (props: any) => {

  const classes = globalStyles();
  const storeNo: any = sessionStorage.getItem('storeNumber');

  const sortDirection = { ASC: "asc", DESC: "desc" };
  const [Idleinventoryaudits, setIdleinventoryaudits] = useState<IdleInventoryAuditProps["Idleinventoryaudit"][] | []>([]);
  const { headerValue } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const [activity,setActivity]=useState<any>({})
  const[inventoryInfo,setinventoryInfo]=useState<any>({})
  const[idleInvAudit,setidleInvAudit]=useState<any>()
  const[AgrHistory,setAgrHistory] =useState<any>({})

  const[inventoryDetail,setinventoryDetail]=useState<any>({})
  const [gridLoader, setgridLoader] = useState<any>(true);
  const [somethingWentWrong, setsomethingWentWrong]: any = React.useState(false);
  const [errMsgTxt, seterrMsgTxt] = useState('');
  const history = useHistory();
  // const componentRef = useRef(null);

  useEffect(() => {

    

      if (validateObject(props) && validateObject(props.response) && validateObject(props.response.data)) {

        const response = props.response;
        setgridLoader(false);
        console.log('idleinventoryaudit-apiResponse', response);

        if (validateObject(response) && response.status == 200) {
          if (validateObject(response.data) && validateObject(response.data.idleInventoryAudit) && response.data.idleInventoryAudit.length > 0) {
            seterrMsgTxt('')
            setsomethingWentWrong(false);
            setIdleinventoryaudits(response.data.idleInventoryAudit);
            setidleInvAudit(response.data)
          }
          else {
            setIdleinventoryaudits([])
          }
        }
        else if (validateObject(response) && response.status == 400 && validateObject(response.data) && validateObject(response.data.errors[0]) && validateObject(response.data.errors[0].error)) {
          seterrMsgTxt(response.data.errors[0].error)
          setsomethingWentWrong(true);
        }
        else {
          seterrMsgTxt('Something Went Wrong')
          setsomethingWentWrong(true);
        }
      }
      if(props?.inventoryInfo ||props?.inventoryDetails || props?.activeResponse ||props?.AgreeHistory){
       
        const inventoryinfo=props.inventoryInfo?.data
        const AgreeHistory=props.AgreeHistory?.data
        const ActivityRes=props.activeResponse?.data
        const inventoryDetails =props.inventoryDetails?.data
        setinventoryInfo(inventoryinfo)
        setAgrHistory(AgreeHistory)
        setActivity(ActivityRes)
        
        setinventoryDetail(inventoryDetails)

      }
    
    

  }, [props]);

  const onClickCancelBtn = () => {
    history.push(`/inventory/inventorysearch`)
  }

  const bindGrid = () => {
    if (somethingWentWrong) {
      return (
        <>
          <div className={`${classes.textCenter} ${classes.mt5} ${classes.ml45}`}>
            <AlertIcon />
            <Typography className={`${classes.subTitle} ${classes.mb3}`}>
              {errMsgTxt}
            </Typography>
          </div>
        </>
      );
    }
    else {
      return (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Grid item md={12} className={classes.componentSpaceBetween}>
              <Grid className={classes.m2}>
                <Typography variant="h5">Idle Inventory Audit</Typography>
              </Grid>
            </Grid>
            <Grid className={`${classes.mbFooterSpacing}`}>
            <Card className={`${classes.m2} ${classes.racCard}`}>
              <CardContent>

                <DynamicGridComponent
                  gridtype="Normal"
                  id="packagePriceItems"
                  items={Idleinventoryaudits}
                  setLoader={gridLoader}
                  isPrint={false}
                  ColumnLink={true}
                  orderBy="loanerAgreement"
                  order={sortDirection.ASC}
                ></DynamicGridComponent>

              </CardContent>
            </Card>
            </Grid>
          </Grid>
        </>
      );
    }
  }

  return (
    <>
      <Grid>
        <Grid container>
          {bindGrid()}
        </Grid>
      </Grid>

      <Grid container style={{ zIndex: '1' }} className={classes.fixedFooter}>
        <Grid className={classes.floatLeft}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.ms2}
            onClick={() => onClickCancelBtn()}
          >
            Cancel
          </RACButton>
        </Grid>
        {headerContext?.storeNumber===storeNo?
        <Grid className={classes.floatRight}>
          <RACButton
            className={classes.mx1}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => printHTML(document.getElementById('InventoryPrintHTML'))}
          >
            Print
          </RACButton>
          {/* <>
            <ReactToPrint
              trigger={() =>
                <RACButton
                  className={classes.mx1}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Print
                </RACButton>}
              content={() => componentRef.current}
              documentTitle="Idle Inventory Audit"
            />
            
          </> */}
        </Grid>:null}
      </Grid>
      <div className={classes.hide}>
      <ActivitiesPrintInfo activeresponse={activity} inventoryInfoRes={inventoryInfo} idleInvAudit={idleInvAudit} AgrHistory={AgrHistory} inventoryDetail={inventoryDetail}/>
        {/* <div id="IdleinventoryinfoPrintHTML" style={{margin:'5%'}}> */}

          {/* <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
            <h2 style={{ float: 'left', margin: '0px' }}>Idle Inventory Audit</h2>
            <div style={{ float: 'right', marginTop: '5px', fontStyle: 'italic' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
          </div>

          <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
            <div style={{ width: '20%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Rent-A-Center</span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}({storeNo})</span>
            </div>
            <div style={{ width: '30%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Item #:</span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}{headerContext.inventoryNumber}</span>
            </div>
            <div style={{ width: '50%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Description:</span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{' '}{headerContext.description}</span>
            </div>
          </div>

          {validateObject(Idleinventoryaudits) && Idleinventoryaudits.length > 0 ?
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px' }}>Idle Inventory Audit</h3>
              <div style={{ width: '100%', float: 'left' }}>
                <DynamicGridComponent
                  gridtype="Normal"
                  id="packagePriceItems"
                  setLoader={false}
                  items={Idleinventoryaudits}
                  isPrint={true}
                  ColumnLink={true}
                  orderBy="loanerAgreement"
                  order={sortDirection.ASC}
                ></DynamicGridComponent>
              </div>
            </div> : null} */}

        {/* </div> */}
      </div>


    </>
  );
}

export default IdleInventoryAudit;