/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { validateObject } from '../Shared/validateObject';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { globalStyles } from '../componentstyles/globalstyles';
import DynamicGridRA from '../InventoryTabs/AgreementHistory/dynamicGrids/dynamicGridRA';
import DynamicGridLA from '../InventoryTabs/AgreementHistory/dynamicGrids/dynamicGridLA';
import DynamicGridPA from '../InventoryTabs/AgreementHistory/dynamicGrids/dynamicGridPA';
import DynamicGridComponent from '../InventoryTabs/IdleInventoryAudit/dynamicGrid';
export const ActivitiesPrintInfo = (props: any) => {
  const classes = globalStyles();
  const [inventoryInfo, setinventoryInfo] = useState<any>({});
  const [inventoryDetails, setinventoryDetails] = useState<any>({});
  const [activity, setactivity] = useState<any>({
    activity: {},
    idleAudit: {},
    agrHistory: {},
  });

  const sortDirection = { ASC: 'asc', DESC: 'desc' };
  useEffect(() => {
    if (props) {
      console.log('printprops', props);
      setactivity({
        ...activity,
        activity: props.activeresponse,
        idleAudit: props.idleInvAudit,
        agrHistory: props.AgrHistory,
      });
      setinventoryInfo(props.inventoryInfoRes);
      setinventoryDetails(props.inventoryDetail);
    }
  }, [props]);

  const roundOff = (num: any, places: number) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  };

  const BindGrid = () => {
    if (
      validateObject(activity?.activity?.activities) &&
      activity?.activity?.activities.length > 0
    ) {
      return activity.activity.activities.map((value: any, index: any) => {
        return (
          <tr key={index}>
            <td
              style={{
                border: '1px soild #000',
                padding: '5px',
                fontSize: '14px',
              }}
            >
              {value.activityDate}
            </td>
            <td
              style={{
                border: '1px soild #000',
                padding: '5px',
                fontSize: '14px',
              }}
            >
              {value.description}
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <div style={{ display: 'none' }}>
      {/* {inventoryInfo} */}
      <div id="InventoryPrintHTML">
        <div style={{ width: '96%', margin: '2%', float: 'left' }}>
          <div style={{ width: '100%', float: 'left', marginBottom: '6px' }}>
            <h2 style={{ float: 'left', margin: '0px' }}>
              Inventory Activities
            </h2>
            <div
              style={{ float: 'right', marginTop: '5px', fontStyle: 'italic' }}
            >
              {moment().format('ddd,D MMM YYYY, hh:mm A')}
            </div>
          </div>

          <div style={{ width: '100%', float: 'left', marginBottom: '4px' }}>
            <div style={{ width: '20%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                Rent-A-Center
              </span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>
                {' '}
                ({inventoryInfo?.inventoryHeader?.storeNumber})
              </span>
            </div>
            <div style={{ width: '30%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                Item #:
              </span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>
                {' '}
                {inventoryInfo?.inventoryHeader?.itemNumber}
              </span>
            </div>
            <div style={{ width: '50%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                Description:
              </span>
              <span style={{ fontSize: '14px', fontStyle: 'italic' }}>
                {' '}
                {inventoryInfo?.inventoryHeader?.description}
              </span>
            </div>
          </div>

          {/* {validateObject(inventoryInfo?.inventoryInfoRes) && inventoryInfo?.inventoryInfoRes?.inventoryInformation ? */}
          <>
            <div style={{ width: '100%', float: 'left' }}>
              <h3
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: '3px',
                  marginBottom: '0px',
                }}
              >
                Item Information
              </h3>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Item #</div>
                    <div style={{ fontSize: '14px' }}>
                      {inventoryInfo?.inventoryHeader?.itemNumber
                        ? inventoryInfo?.inventoryHeader?.itemNumber
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Remaining Value</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails?.depreciationEstimator
                      ) &&
                      validateObject(
                        inventoryDetails?.depreciationEstimator?.remainingValue
                      ) &&
                      (inventoryDetails?.depreciationEstimator
                        ?.remainingValue !== '0' ||
                        inventoryDetails?.depreciationEstimator
                          ?.remainingValue !== '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryDetails?.depreciationEstimator
                              ?.remainingValue,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Times on Rent</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        activity?.activity?.activeresponse?.reveneueAndTime
                          ?.timesOnRent
                      ) &&
                      activity?.activity?.activeresponse?.reveneueAndTime
                        ?.timesOnRent !== '0'
                        ? activity?.activity?.activeresponse?.reveneueAndTime
                            ?.timesOnRent
                        : '0'}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Serial Number</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo?.inventoryInformation?.serialNumber
                      )
                        ? inventoryInfo?.inventoryInformation?.serialNumber
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Service Expense</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        activity?.activity?.activeresponse?.reveneueAndTime
                          ?.serviceExpense
                      ) &&
                      activity?.activity?.activeresponse?.reveneueAndTime
                        ?.serviceExpense !== '0'
                        ? activity?.activity?.activeresponse?.reveneueAndTime
                            ?.serviceExpense
                        : '$ 0.00'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Times on Service</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        activity?.activity?.activeresponse?.reveneueAndTime
                          ?.timesOnService
                      ) &&
                      activity?.activity?.activeresponse?.reveneueAndTime
                        ?.timesOnService !== '0'
                        ? activity?.activity?.activeresponse?.reveneueAndTime
                            ?.timesOnService
                        : '0'}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Model Number</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryInfo?.inventoryInformation?.modelNumber
                      )
                        ? inventoryInfo?.inventoryInformation?.modelNumber
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Revenue Collected</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        activity?.activity?.activeresponse?.reveneueAndTime
                          ?.totalRevenue
                      ) &&
                      activity?.activity?.activeresponse?.reveneueAndTime
                        ?.totalRevenue !== '0'
                        ? activity?.activity?.activeresponse?.reveneueAndTime
                            ?.totalRevenue
                        : '$ 0.00'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Times Transferred</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        activity?.activity?.activeresponse?.reveneueAndTime
                          ?.timesTransferred
                      ) &&
                      activity?.activity?.activeresponse?.reveneueAndTime
                        ?.timesTransferred !== '0'
                        ? activity?.activity?.activeresponse?.reveneueAndTime
                            ?.timesTransferred
                        : '0'}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Purchase Date</div>
                    <div style={{ fontSize: '14px' }}>
                      {inventoryDetails?.depreciationEstimator &&
                      inventoryDetails?.depreciationEstimator?.purchaseDate
                        ? moment(
                            inventoryDetails.depreciationEstimator.purchaseDate
                          ).format('MM/DD/YYYY')
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Status</div>
                    <div style={{ fontSize: '14px' }}>
                      {inventoryInfo?.inventoryHeader?.status
                        ? inventoryInfo?.inventoryHeader?.status
                        : '-'}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>MTD Revenue</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        activity?.activity?.activeresponse?.reveneueAndTime
                          ?.mtdRevenue
                      ) &&
                      activity?.activity?.activeresponse?.reveneueAndTime
                        ?.mtdRevenue !== '0'
                        ? activity?.activity?.activeresponse?.reveneueAndTime
                            ?.mtdRevenue
                        : '$ 0.00'}
                    </div>
                  </span>
                </div>
              </div>

              <div
                style={{ width: '100%', float: 'left', marginBottom: '-12px' }}
              >
                <div
                  style={{
                    width: '31.3%',
                    margin: '1%',
                    float: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <span>
                    <div style={{ fontSize: '14px' }}>Purchase Cost</div>
                    <div style={{ fontSize: '14px' }}>
                      {validateObject(
                        inventoryDetails?.depreciationEstimator
                      ) &&
                      validateObject(
                        inventoryDetails?.depreciationEstimator?.purchaseCost
                      ) &&
                      (inventoryDetails?.depreciationEstimator?.purchaseCost !==
                        '0' ||
                        inventoryDetails?.depreciationEstimator
                          ?.purchaseCost !== '0.00') ? (
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={roundOff(
                            inventoryDetails?.depreciationEstimator
                              ?.purchaseCost,
                            3
                          ).toFixed(2)}
                          prefix="$ "
                          className={classes.formLabelValue}
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                        />
                      ) : (
                        '$ 0.00'
                      )}
                    </div>
                  </span>
                </div>
              </div>
            </div>

            {validateObject(activity?.agrHistory?.rentalAgreement) &&
            activity?.agrHistory?.rentalAgreement?.length > 0 ? (
              <div
                style={{ width: '100%', float: 'left', marginBottom: '20px' }}
              >
                <h3
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    padding: '3px',
                  }}
                >
                  Rental Agreements
                </h3>
                <div style={{ width: '100%', float: 'left' }}>
                  <DynamicGridRA
                    gridtype="Normal"
                    id="agreementHistorys"
                    items={activity?.agrHistory?.rentalAgreement}
                    setLoader={false}
                    isPrint={true}
                    ColumnLink={true}
                    orderBy="rentalAgreement"
                    order={sortDirection.ASC}
                  ></DynamicGridRA>
                </div>
              </div>
            ) : null}
            {validateObject(activity?.agrHistory?.promoAgreement) &&
            activity?.agrHistory?.AgrHistory?.promo.length > 0 ? (
              <div
                style={{ width: '100%', float: 'left', marginBottom: '20px' }}
              >
                <h3
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    padding: '3px',
                  }}
                >
                  Loaner/Promos
                </h3>
                <div style={{ width: '100%', float: 'left',marginRight:'1%' }}>
                  <DynamicGridPA
                    gridtype="Normal"
                    id="agreementHistorys"
                    items={activity?.agrHistory?.promoAgreement}
                    setLoader={false}
                    isPrint={true}
                    ColumnLink={true}
                    orderBy="promoAgreement"
                    order={sortDirection.ASC}
                  ></DynamicGridPA>
                </div>
              </div>
            ) : null}
            {validateObject(activity?.agrHistory?.loanerAgreement) &&
            activity?.agrHistory?.loanerAgreement.length > 0 ? (
              <div
                style={{ width: '100%', float: 'left', marginBottom: '20px' }}
              >
                <h3
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    padding: '3px',
                  }}
                >
                  Loaner Agreements
                </h3>
                <div style={{ width: '100%', float: 'left',marginRight:'1%' }}>
                  <DynamicGridLA
                    gridtype="Normal"
                    id="agreementHistorys"
                    items={activity?.agrHistory?.loanerAgreement}
                    setLoader={false}
                    isPrint={true}
                    ColumnLink={true}
                    orderBy="loanerAgreement"
                    order={sortDirection.ASC}
                  ></DynamicGridLA>
                </div>
              </div>
            ) : null}
            {validateObject(activity?.idleAudit?.idleInventoryAudit) &&
            activity?.idleAudit?.idleInventoryAudit.length > 0 ? (
              <div
                style={{ width: '100%', float: 'left', marginBottom: '20px' }}
              >
                <h3
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    padding: '3px',
                  }}
                >
                  Idle Inventory Audits
                </h3>
                <div style={{ width: '100%', float: 'left',marginRight:'1%' }}>
                  <DynamicGridComponent
                    gridtype="Normal"
                    id="packagePriceItems"
                    setLoader={false}
                    items={activity?.idleAudit?.idleInventoryAudit}
                    isPrint={true}
                    ColumnLink={true}
                    orderBy="loanerAgreement"
                    order={sortDirection.ASC}
                  ></DynamicGridComponent>
                </div>
              </div>
            ) : null}
            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <h3
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: '3px',
                }}
              >
                Activity
              </h3>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        border: '1px solid #000',
                        backgroundColor: '#ccc',
                        padding: '5px',
                        fontSize: '14px',
                        textAlign:'left'
                      }}
                    >
                      Date{' '}
                    </th>
                    <th
                      style={{
                        border: '1px solid #000',
                        backgroundColor: '#ccc',
                        padding: '5px',
                        fontSize: '14px',
                        textAlign:'left'
                      }}
                    >
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>{BindGrid()}</tbody>
              </table>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
export default ActivitiesPrintInfo;
