/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { ReactComponent as SortdownIcon } from '../../../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../../../assets/images/sort-up-icon.svg';
import { Grid, RACTable, RACTableCell, RACTableRow, CircularProgress, Typography } from '@rentacenter/racstrap';
import moment from 'moment';
import { useHistory } from 'react-router';
import { ReactComponent as Alerticon } from '../../../../assets/images/No-records.svg';
import { fixedGridStyles } from '../../../componentstyles/fixedGridStyles';
import { globalStyles } from '../../../componentstyles/globalstyles';
import { validateObject } from '../../../Shared/validateObject';

export interface dynamicProps {

  id?: string;
  items: any;
  locationType?: any;
  orderBy: string;
  order: string;
  ColumnLink?: boolean;
  gridtype:
  | 'Normal'
  | 'withSubChild'
  | 'withCheckBox'
  | 'withRadioBtn'
  | 'endWithCheckBox';
  handleActionType?: (value: any, index: any) => void;
  throwStyle?: any;
  isPrint?: boolean;
  setLoader?: boolean;
}
export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;

  subColumns?: columns[];
}
const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
}

const packagePriceColumns: columns[] = [
  {
    columnId: 'loanerAgreementNumber',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Loaner Agreement #',
    IsSorted: true,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'agreementNumber',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Agreement #',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'type',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Type',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'customer',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Customer',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'openDate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Open Date',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'closeDate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Close Date',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'status',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Status',
    IsSorted: false,
    IsSortable: true,
  }
];

const DynamicGridLA = (props: dynamicProps) => {
  const isPrint = props.isPrint;
  const classes = globalStyles();
  const FGS = fixedGridStyles();
  const [gridValues, setGridValues] = useState<any[]>([]);
  const [gridColumn, setGridColumn] = useState<columns[]>([]);

  useEffect(() => {
    const { order, orderBy, items } = props;
    /* eslint-disable no-console */
    console.log('props.items', props.items);

    const sortedData =
      order == sortDirection.DESC
        ? items.sort((a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1))
        : items.sort((a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1));

    setGridValues(sortedData);
    setGridColumn(packagePriceColumns);
  }, [props]);

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) =>
          b[columnId] < a[columnId] ? -1 : 1
        )
        : dataValues.sort((a: any, b: any) =>
          a[columnId] < b[columnId] ? -1 : 1
        );
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };

  const renderTableHead = (dataColumns: any, dataValues: any) => {
    return (
      <>
        {gridColumn.map((value, index) => {
          return (
            <RACTableCell key={index} className={classes.p2}>

              <a
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                id={value.columnId}
                onClick={() =>
                  sortArray(
                    value.columnId,
                    index,
                    dataColumns,
                    dataValues
                  )
                }
              >
                {value.columnTitle + '  '}
                {value.IsSorted === true ? (
                  value.sortOrder == sortDirection.DESC ? (
                    <SortupIcon className={classes.ms0} />
                  ) : (
                    <SortdownIcon className={classes.ms0} />
                  )
                ) : null}
              </a>

            </RACTableCell>
          )
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  };
  const renderPrintTableHead = () => {
    return (
      <>
        {gridColumn.map((value, index) => {
          return (
            <RACTableCell key={index} style={{ border: '1px solid #000', backgroundColor: '#ccc', padding: '5px', fontSize: '14px' }}>
              {value.columnTitle}
            </RACTableCell>
          )
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  };

  const renderTableMain = () => {

    if (props.setLoader == false) {
      if (validateObject(gridValues) && gridValues.length > 0) {
        return renderTableContent(gridValues)
      }
      else {
        return (
          <RACTableRow className={classes.hiddenRow} key="0">
            <RACTableCell colSpan={7} className={`${classes.textCenter} ${classes.mt4}`}>
              <Grid item md={12} className={classes.textCenter}>
                <Alerticon></Alerticon>
                <Typography className={classes.RACPOPMsg}>
                  No Records Found
                </Typography>
              </Grid>
            </RACTableCell>
          </RACTableRow>
        );
      }
    }
    else {
      return (
        <RACTableRow className={classes.hiddenRow} key="0">
          <RACTableCell colSpan={7}>
            <Grid className={classes.GridLoader}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </RACTableRow>
      )
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTableContent = (gridBodyData: any) => {
    if (validateObject(gridBodyData) && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        return (
          <React.Fragment key={index1}>
            <RACTableRow className={classes.bgTransparent}>
              <RACTableCell>
                {props.locationType == 'radius' ?
                  value.loanerAgreementNumber :
                  <a className={classes.racpadLink}>{value.loanerAgreementNumber}</a>
                }
              </RACTableCell>
              <RACTableCell>
                {props.locationType == 'radius' ?
                  value.agreementNumber :
                  <a className={classes.racpadLink} href="#">{value.agreementNumber}</a>
                }
              </RACTableCell>
              <RACTableCell>
                {validateObject(value.type) && value.type != '' ? value.type : '-'}
              </RACTableCell>
              <RACTableCell>
                {validateObject(value.customer) && value.customer != '' ? truncString((value.customer), 15, '...') : '-'}
              </RACTableCell>
              <RACTableCell className={ classes.textCenter}>
                {validateObject(value.openDate) && value.openDate != '' ? moment(value.openDate).format('MM/DD/YYYY') : '-'}
              </RACTableCell>
              <RACTableCell className={ classes.textCenter}>
                {validateObject(value.closeDate) && value.closeDate != '' ? moment(value.closeDate).format('MM/DD/YYYY') : '-'}
              </RACTableCell>
              <RACTableCell>
                {validateObject(value.status) && value.status != '' ? value.status : '-'}
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };

  const renderPrintTableContent = (gridBodyData: any) => {
    if (validateObject(gridBodyData) && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        return (
          <React.Fragment key={index1}>
            <RACTableRow style={{ width: '100%' }}>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{value.loanerAgreementNumber}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{value.agreementNumber}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{validateObject(value.type) && value.type != '' ? value.type : '-'}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{validateObject(value.customer) && value.customer != '' ? value.customer : '-'}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{validateObject(value.openDate) && value.openDate != '' ? moment(value.openDate).format('MM/DD/YYYY') : '-'}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{validateObject(value.closeDate) && value.closeDate != '' ? moment(value.closeDate).format('MM/DD/YYYY') : '-'}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{validateObject(value.status) && value.status != '' ? value.status : '-'}</RACTableCell>
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };

  return (
    <>
      {
        isPrint ?
          <RACTable
            renderTableHead={() => renderPrintTableHead()}
            renderTableContent={() => renderPrintTableContent(gridValues)}
          /> :
          <Grid className={`${FGS.fixTableHead} ${FGS.fixTableHeight275}`}>
            <RACTable
              renderTableHead={() => renderTableHead(gridColumn, gridValues)}
              renderTableContent={() => renderTableMain()}
            />
          </Grid>
      }
    </>
  );
};

export default DynamicGridLA;
