import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
// import { Placeholder1 } from '../components/PlaceHolder1/PlaceHolder1';
import { Placeholder2 } from '../components/PlaceHolder2/PlaceHolder2';
import SearchResult from '../components/InventorySearch/SearchResults';
import { InventoryContextProvider } from '../context/InventoryInfoContext';
import PrintEditPriceTag from '../components/PrintEditPriceTags/PrintEditPriceComponent';
import { InventoryInfoRoute } from '../components/InventoryTabs/InventoryInfoRoute';
import PackagePriceTags from '../components/NewPackageItemSearch/PackagePriceTags/PackagePriceTags';
import SearchResult1 from '../components/NewPackageItemSearch/ItemSearch/searchResults';
import InventoryTransferReceive from '../components/InventoryTransferReceive/InventoryTransferReceiveComponent';
import { PackagePriceTagsContextProvider } from '../context/PackagePriceTagContext';
export const routesTestId = 'routesTestId';

export const Routes = () => (
  <UserProvider>
    <UserFetcher />
    <div data-testid={routesTestId}>
      <InventoryContextProvider>
        <PackagePriceTagsContextProvider>
          <Switch>
            <Route exact path={AppRoute.Index}>
              <Redirect to={AppRoute.Placeholder1} />
            </Route>
            <Route exact path={AppRoute.InventorySearch}>
              <SearchResult />
            </Route>
            <Route exact path={AppRoute.Placeholder2}>
              <Placeholder2 />
            </Route>
            <Route exact path={AppRoute.PrintEditPriceTagList}>
              <PrintEditPriceTag />
            </Route>
            <Route exact path={AppRoute.PackagePriceTagList}>
              <PackagePriceTags />
            </Route>
            <Route exact path={AppRoute.ItemSearch}>
              <SearchResult1 />
            </Route>
            <Route exact path={AppRoute.InventoryTransferReceive}>
              <InventoryTransferReceive />
            </Route>

            <Route path={AppRoute.Inventory}>
              <InventoryInfoRoute />
            </Route>
          </Switch>
        </PackagePriceTagsContextProvider>
      </InventoryContextProvider>
    </div>
  </UserProvider>
);
