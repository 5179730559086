/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ReactComponent as SortdownIcon } from '../../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../../assets/images/sort-up-icon.svg';
// import { ReactComponent as ItemAddition } from '../../assets/images/ItemAddition.svg';

import {
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
  CircularProgress,
} from '@rentacenter/racstrap';
import { fixedGridStyles } from '../../componentstyles/fixedGridStyles';
import moment from 'moment';

export interface dynamicProps {
  id?: string;

  items: any;
  // columns: columns[];
  orderBy: string;
  order: string;
  ColumnLink?: boolean;
  gridtype:
    | 'Normal'
    | 'withSubChild'
    | 'withCheckBox'
    | 'withRadioBtn'
    | 'endWithCheckBox';

  handleActionType?: (value: any, index: any) => void;
  throwStyle: any;
  loaderController?: boolean;
}
export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;

  subColumns?: columns[];
}
const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
}

const packagePriceColumns: columns[] = [
  {
    columnId: 'activityDate',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Date',
    IsSorted: true,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'description',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Description',
    IsSorted: false,
    columnType: 'link',
    IsSortable: true,
  },
  // {
  //   columnId: 'type',
  //   sortOrder: sortDirection.DESC,
  //   columnTitle: 'Type',
  //   IsSorted: false,
  //   IsSortable: true,
  // },
  {
    columnId: 'reason',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Reason',
    IsSorted: false,
    IsSortable: true,
  },
];

const DynamicGridComponent = (props: dynamicProps) => {
  const classes = props.throwStyle;
  const FGS = fixedGridStyles();
  const [gridValues, setGridValues] = useState<any[]>([]);
  const [gridColumn, setGridColumn] = useState<columns[]>([]);

  useEffect(() => {
    const { order, orderBy, items } = props;
    /* eslint-disable no-console */
    console.log('In Grid Component', props.items);

    const sortedData =
      order == sortDirection.DESC
        ? items.sort((a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1))
        : items.sort((a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1));

    setGridValues(sortedData);
    setGridColumn(packagePriceColumns);
  }, [props]);

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
        : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };
  //   const sortArrayForDollar = (
  //     columnId: string,
  //     index: number,
  //     datacolumn: any,
  //     dataValues: any
  //   ) => {
  //     const sortedData =
  //       datacolumn[index].sortOrder == sortDirection.DESC
  //         ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
  //         : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
  //     const reorderColumns: columns[] = datacolumn;
  //     reorderColumns[index].sortOrder =
  //       reorderColumns[index].sortOrder == sortDirection.ASC
  //         ? sortDirection.DESC
  //         : sortDirection.ASC;
  //     reorderColumns.map((value: any, index1: number) => {
  //       reorderColumns[index1].IsSorted = index == index1 ? true : false;
  //     });
  //     setGridValues([...sortedData]);
  //     setGridColumn([...reorderColumns]);
  //     // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  //   };

  const renderTableHead = (dataColumns: any, dataValues: any) => {
    return (
      <>
        {gridColumn.map((value, index) => {
          return (
            <RACTableCell key={index} className={classes.p2}>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                id={value.columnId}
                onClick={() =>
                  sortArray(value.columnId, index, dataColumns, dataValues)
                }
              >
                {value.columnTitle + '  '}
                {value.IsSorted === true ? (
                  value.sortOrder == sortDirection.DESC ? (
                    <SortupIcon className={classes.ms0} />
                  ) : (
                    <SortdownIcon className={classes.ms0} />
                  )
                ) : null}
              </a>
            </RACTableCell>
          );
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTableContent = (gridBodyData: any, gridColumns: any) => {
    if (props.loaderController === true) {
      return (
        <RACTableRow className={classes.bgTransparent} key="0">
          <RACTableCell colSpan={4}>
            <Grid className={classes.GridLoader}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </RACTableRow>
      );
    } else if (!!gridBodyData && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        return (
          <React.Fragment key={index1}>
            <RACTableRow className={classes.bgTransparent}>
              <RACTableCell
                className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
                style={{ width: '20%' }}
              >
                {/* <a className={classes.racpadLink}> */}
                {moment(value.activityDate).format('MM/DD/YYYY')}
                {/* </a> */}
              </RACTableCell>

              <RACTableCell
                className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
                style={{ width: '30.33%' }}
              >
                {value.description}
              </RACTableCell>
              {/* <RACTableCell
                className={`${classes.fs6} ${classes.fontSemiBold} ${classes.p2}`}
              >
                {value.type}
              </RACTableCell> */}

              {value.reason != undefined && value.reason !== '' ? (
                <RACTableCell style={{ width: '33.33%' }}>
                  {value.reason}
                </RACTableCell>
              ) : (
                <RACTableCell> - </RACTableCell>
              )}
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };

  return (
    <Grid className={FGS.fixTableHead}>
      <RACTable
        renderTableHead={() => renderTableHead(gridColumn, gridValues)}
        renderTableContent={() => renderTableContent(gridValues, gridColumn)}
      />
    </Grid>
  );
};

export default DynamicGridComponent;
