/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { useState, useEffect, FC, useContext } from 'react';
import { getActivities } from '../../../api/user';
import DynamicGridComponent from './grid';
import { InventoryGlobalStyles } from '../../InventorySearch/Styles/InventoryGlobalStyles';
import { globalStyles } from '../../componentstyles/globalstyles';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import { printHTML } from '../../Shared/print';
import NumberFormat from "react-number-format";
import { searchResultStyles } from '../../InventorySearch/Styles/searchResultStyles';
import { useHistory } from 'react-router';
import { validateObject } from "../../Shared/validateObject";
import { InventoryContext } from '../../../context/InventoryInfoContext';
import moment from 'moment';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RACButton,
} from '@rentacenter/racstrap';
import ActivitiesPrintInfo from '../ActivitiesPrint'
import { formStyles } from '../../componentstyles/formstyles';

const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export const ActivitiesInfo = (props: any) => {

  const Invclasses = InventoryGlobalStyles();
  const classes = globalStyles();
  const form =formStyles()

  const agrComponent = searchResultStyles();
  const [controlRecord, setcontrolRecord] = React.useState<any>(false);
  const [updateitemadded, setupdateitemadded] = React.useState(false);
  const [Gridloader, setGridloader]: any = React.useState(false);
  const [loaderController, setloaderController]: any = React.useState(true);
  const [internalServerController, setinternalServerController]: any =
    React.useState(false);

  const [ActivitiesValues, setActivitiesValues] = useState<any>([]);
  const [ActivitiesFilter, setActivitiesFilter] = useState<any>([]);
  const [reveneueValues, setReveneueValues] = useState<any>({});
  const [firstCheckbox, setFirstCheckbox] = useState(false);
  const [controlViewMode, setcontrolViewMode] = useState(false);
  const { headerValue } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const [activity,setActivity]=useState<any>({})
  const[inventoryInfo,setinventoryInfo]=useState<any>({})
  const[idleInvAudit,setidleInvAudit]=useState<any>()
  const[AgrHistory,setAgrHistory] =useState<any>({})
  const[inventoryDetail,setinventoryDetail]=useState<any>({})
  const history = useHistory();
  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme';

  const altersearch = updateitemadded
    ? agrComponent.collapsedWigid
    : agrComponent.expandWigid;

  useEffect(() => {
    const getActivity = () => {

      if (props !== undefined && props.response !== undefined && props.response.data !== undefined) {

        //setloaderController(true);
        setGridloader(true);
        const ActivitiesResponse = props.response;
        console.log('ActivitiesResponse', ActivitiesResponse);
        setloaderController(false);
        setGridloader(false);
        
        if (
          validateObject(ActivitiesResponse) &&
          validateObject(ActivitiesResponse.data) &&
          validateObject(ActivitiesResponse.data.activities) && 
          validateObject(ActivitiesResponse.data.reveneueAndTime) &&
          ActivitiesResponse.data.activities.length > 0
        ) {
          console.log('ActivitiesResponse1', ActivitiesResponse);
          setActivity(ActivitiesResponse.data)
          setActivitiesValues(ActivitiesResponse.data.activities);
          setReveneueValues(ActivitiesResponse.data.reveneueAndTime)
          //setActivitiesFilter(ActivitiesResponse.data.activities);
        }

        if (ActivitiesResponse.status == 400 || ActivitiesResponse.status == 500) {
          setinternalServerController(true)
        }
        else if (ActivitiesResponse.data.activities.length == 0) {
          setcontrolRecord(true)
        }
        
      };
      if(props?.inventoryInfo ||props?.idleInventoryRes || props?.AgreeHistory){
       
        const inventoryinfo=props.inventoryInfo?.data
        const idleInventoryRes=props.idleInventoryRes?.data
        const AgreeHistory=props.AgreeHistory?.data
        const inventoryDetails =props.inventoryDetails?.data
        setinventoryInfo(inventoryinfo)
        setidleInvAudit(idleInventoryRes)
        setAgrHistory(AgreeHistory)
        setinventoryDetail(inventoryDetails)

      }
    }
    setFirstCheckbox(false);
    getActivity();
  }, [props]);

  const gridCallMethod = () => {
    console.log('Came inside Grid Method');

    if (validateObject(ActivitiesValues) && ActivitiesValues.length > 0) {
      console.log('Inside table bind', ActivitiesValues);
      return (
        <div>
          <Card className={`${classes.m2} ${classes.racCard}`}>
            <CardContent >
              <DynamicGridComponent
                gridtype="Normal"
                id="packagePriceItems"
                items={ActivitiesValues}
                orderBy="loanerAgreement"
                order={sortDirection.ASC}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );

    }
    else if (loaderController) {
      return (
        <div>

          <Card className={`${classes.m2} ${classes.racCard}`}>
            <CardContent>
              <DynamicGridComponent
                gridtype="Normal"
                id="packagePriceItems"
                items={ActivitiesValues}
                loaderController={loaderController}
                orderBy="loanerAgreement"


                order={sortDirection.ASC}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );
    }

    else if (internalServerController) {
      return (
        <div>
          <div className={`${Invclasses.textCenter} ${Invclasses.mt5}`}>
            <AlertIcon />
            <Typography className={`${Invclasses.subTitle} ${Invclasses.mb3}`}>
              Something Went Wrong
            </Typography>
          </div>
        </div>
      );
    } else if (controlRecord) {
      return (
        <div>
          <div className={`${Invclasses.textCenter} ${Invclasses.mt5}`}>
            <AlertIcon />
            <Typography className={`${Invclasses.subTitle} ${Invclasses.mb3}`}>
              No Records Found
            </Typography>
          </div>
        </div>
      );
    }
  };

  const handleFieldChange = (e: any) => {
    if (e.target.checked) {
      const filterArr = ActivitiesValues.filter(
        (row: any) => row.type == 'inventory'
      );

      setActivitiesValues(filterArr);
      setFirstCheckbox(!firstCheckbox);
    } else {
      setFirstCheckbox(false);
      setActivitiesValues(ActivitiesFilter);
    }
  };

  const roundOff = (num: any, places: number) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  }

  const BindGrid = () => {

    if (validateObject(ActivitiesValues) && ActivitiesValues.length > 0) {

      return ActivitiesValues.map((value: any, index: any) => {

        return (

          <tr key={index}>
            <td style={{ border: '1px soild #000', padding: '5px', fontSize: '14px' }}>{value.activityDate}</td>
            <td style={{ border: '1px soild #000', padding: '5px', fontSize: '14px' }}>{value.description}</td>

          </tr>


        )
      })
    }
  }



  return (
    <>

      <div className={dynamicViewClass} style={{marginBottom:'98px'}}>
        <Grid container spacing={2}>
          <Grid item className={altersearch}>
          <Card className={`${classes.m2} ${classes.racCard}`}style={{marginTop:'1.5rem',marginBottom:'1.5rem'}}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                              <Typography className={form.formLabel}>Revenue Collected</Typography>
                              
                              {/* <Typography className={`${form.formValue} ${classes.mt2}`}>$ 203.49</Typography> */}
                              <Typography className={`${form.formValue} ${classes.mt2}`}>
                              {validateObject(reveneueValues.totalRevenue) && (reveneueValues.totalRevenue !== "0" || reveneueValues.totalRevenue !== "0.00") ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff(reveneueValues.totalRevenue, 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                />
                                : '$ 0.00'}
                            </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                              <Typography className={form.formLabel}>MTD Revenue</Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>
                              {validateObject(reveneueValues.mtdRevenue) && (reveneueValues.mtdRevenue !== "0" || reveneueValues.mtdRevenue !== "0.00") ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff(reveneueValues.mtdRevenue, 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                /> : '$ 0.00'}
                            </Typography>
                              {/* <Typography className={`${form.formValue} ${classes.mt2}`}>$ 203.49</Typography> */}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                              <Typography className={form.formLabel}>Times On Rent </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(reveneueValues.timesOnRent) && reveneueValues.timesOnRent !== "0" ? reveneueValues.timesOnRent : '0'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                              <Typography className={form.formLabel}>Service Expense </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>
                              {validateObject(reveneueValues.serviceExpense) && (reveneueValues.serviceExpense !== "0" || reveneueValues.serviceExpense !== "0.00") ?
                                <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={roundOff(reveneueValues.serviceExpense, 3).toFixed(2)}
                                  prefix="$ "
                                  className={classes.formLabelValue}
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                /> : '$ 0.00'}
                            </Typography>
                              {/* <Typography className={`${form.formValue} ${classes.mt2}`}>-</Typography> */}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                              <Typography className={form.formLabel}>Times On Service </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(reveneueValues.timesOnservice) && reveneueValues.timesOnservice !== "0" ? reveneueValues.timesOnservice : '0'}</Typography>

                              {/* <Typography className={`${form.formValue} ${classes.mt2}`}>-</Typography> */}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2} className={` ${classes.alignCenter} `}>
                              <Typography className={form.formLabel}>Times Transferred </Typography>
                              <Typography className={`${form.formValue} ${classes.mt2}`}>{validateObject(reveneueValues.timesTransferred) && reveneueValues.timesTransferred !== "0" ? reveneueValues.timesTransferred : '0'}</Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
            {/* <RACCheckBox
              id="flexCheckChecked"
              label="Show Only Inventory Activities"
              checked={firstCheckbox}
              onChange={(e: any) => handleFieldChange(e)}
            /> */}

            <div>

              {gridCallMethod()}

            </div>
          </Grid>
        </Grid>
      </div>

      <Grid style={{ zIndex: '1' }} className={Invclasses.fixedBottom}>
        <Grid className={Invclasses.floatLeft}>
          <RACButton
            color="primary"
            variant="outlined"
            className={Invclasses.ms2}
            onClick={() => history.push(`/inventory/inventorysearch`)}
          >
            Cancel
          </RACButton>
        </Grid>
        {headerContext?.storeNumber===sessionStorage.getItem('storeNumber')?
        <Grid className={Invclasses.floatRight}>
          <RACButton
            color="primary"
            variant="contained"
            onClick={() => printHTML(document.getElementById('InventoryPrintHTML'))}
            style={{ marginRight: '4px' }}
          >
            Print
          </RACButton>
        </Grid>:null}
      </Grid>



      {/*Print Html startes here*/}
      <div className={agrComponent.hide}>
        <div style={{ width: '96%', margin: '2%', float: 'left' }} id="activities">
        <ActivitiesPrintInfo activeresponse={activity} inventoryInfoRes={inventoryInfo} idleInvAudit={idleInvAudit} AgrHistory={AgrHistory} inventoryDetail={inventoryDetail}/>

          {/* <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
            <h2 style={{ float: 'left', margin: '0px' }}>Inventory Activities</h2>
            <div style={{ float: 'right', marginTop: '5px' }}>{moment().format('ddd,D MMM YYYY hh:mm A')}</div>
          </div>

          <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
            <div style={{ width: '20%', float: 'left' }}>
              <span style={{ fontSize: '14px' }}>Rent-A-Center</span>
              <span style={{ fontSize: '14px' }}>({sessionStorage.getItem('storeNumber')})</span>
            </div>
            <div style={{ width: '30%', float: 'left' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Item #:</span>
              <span style={{ fontSize: '14px' }}>{headerContext.inventoryNumber}</span>
            </div>
            <div style={{ width: '100%', float: 'left', marginTop: '10px' }}>
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Description:</span>
              <span style={{ fontSize: '14px' }}>{headerContext.description}</span>
            </div>
          </div>

          <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
            <h3 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '3px' }}>Miscellanous</h3>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #000', backgroundColor: '#ccc', padding: '5px', fontSize: '14px' }}>Date </th>
                  <th style={{ border: '1px solid #000', backgroundColor: '#ccc', padding: '5px', fontSize: '14px' }}>Description</th>
                </tr>
              </thead>
              <tbody>
                {BindGrid()}
              </tbody>
            </table>
          </div> */}

        </div>
      </div>
      {/*Print Html ends here*/}

    </>
  );
}

export default ActivitiesInfo;
