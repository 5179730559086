/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import {
  convertDate,
  formatter,
  validateData,
} from '../../../constants/CommonMethods';

export default function PrintPriceTagDocument(props: any) {
  console.log('props inside printPriceTagDoc',props);
  const headerData = props.header;
  const priceData = props.priceDetails;
  const invenData = props.invenDetails;
  const cityName = props.storeDetail;

  const queued = validateData(headerData)
    ? validateData(headerData.priceTagQueued)
      ? headerData.priceTagQueued === true
        ? 'Yes'
        : 'No'
      : ''
    : {};

  return (
    <div>
      <head>
        <title></title>
      </head>
      <body>
        <div
          style={{
            // marginBottom: '400px',
            width: '100%',
            float: 'left',
            fontFamily: 'Arial',
          }}
        >
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
              Price Tag
            </span>
            <span style={{ float: 'right', fontStyle: 'italic' }}>
              {convertDate(new Date(), 'ddd, DD MMM YYYY hh:mm A')}
            </span>
          </div>

          <div style={{ width: '100%', float: 'left' }}>
            <div
              style={{ float: 'left', marginRight: '1%', fontSize: '14px',width:'25%' }}
            >
              <span style={{ fontWeight: 'bold' }}>
                Rent-A-Center <span style={{fontStyle: 'italic',fontWeight: 'normal' }}>({validateData(cityName)?cityName:' - '})</span>
              </span>
            </div>
            <div
              style={{ float: 'left', marginRight: '2%', fontSize: '14px',width:'25%'}}
            >
              <span style={{ fontWeight: 'bold' }}>Item #:</span>
              <span style={{ fontStyle: 'italic' }}>
                {validateData(headerData)
                  ? validateData(headerData.inventoryNumber)
                    ? headerData.inventoryNumber
                    : '-'
                  : '-'}
              </span>
            </div>
            <div style={{ float: 'left', fontSize: '14px',width:'45%' }}>
              <span style={{ fontWeight: 'bold' }}>Description:</span>
              <span style={{ fontStyle: 'italic'}}>
                {validateData(invenData.description)? invenData.description :' - '}
              </span>
            </div>
          </div>

          <div style={{ width: '100%', float: 'left' }}>
            <h4
              style={{
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              General Information
            </h4>
            <div style={{ width: '49%', float: 'left' }}>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
              >
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  Condition
                </span>
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  {' '}
                  {
                  headerData.conditionDescEn=="Term pushed up/down by Coworker" ? "Exception" :
                     headerData.conditionDescEn=="Based on DOR-system push" ? "Auto" : 
                        headerData.conditionDescEn=="New - Full Term"?"New":
                        "-"
                  }
                  {/* {validateData(headerData)?validateData(headerData.condition)?headerData.condition:"-":"-"} */}
                </span>
                
              </div>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
              >
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  Condition Description
                </span>
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  {headerData.conditionDescEn}
                </span>
              </div>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
              >
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                    Term
                </span>
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                {/* <span style={{ width: '25%', float: 'left', fontSize: '13px' }}> */}
                  {validateData(headerData)
                    ? validateData(headerData.maxTerm)
                      ? headerData.maxTerm
                      : '-'
                    : '-'}
                  -
                  {validateData(headerData)
                    ? validateData(headerData.minTerm)
                      ? headerData.minTerm
                      : '-'
                    : '-'}
                  weeks
                {/* </span> */}
                </span>
              </div>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
              >
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  Condition Edit Reason
                </span>
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  {headerData.conditionReason}
                </span>
              </div>
            </div>
            <div style={{ width: '50%', float: 'left', marginLeft: '2px' }}>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
              >
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  Remaining Value
                </span>
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  
                  {validateData(headerData)
                    ? validateData(headerData.remainingValue)
                      ? formatter.format(headerData.remainingValue)
                      : '-'
                    : '-'}
                </span>
              </div>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
              >
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  Purchase Cost
                </span>
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  
                  {validateData(headerData)
                    ? validateData(headerData.purchaseCost)
                      ? formatter.format(headerData.purchaseCost)
                      : '-'
                    : '-'}
                </span>
              </div>
              <div
                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
              >
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  Price Tag Queued
                </span>
                <span style={{ width: '50%', float: 'left', fontSize: '13px' }}>
                  {queued}
                </span>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', float: 'left' }}>
            <h4
              style={{
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              Price Tags
            </h4>
            <div
              style={{
                width: '49%',
                float: 'left',
                marginRight: '1%',
                marginBottom: '20px',
              }}
            >
              <h5
                style={{
                  padding: '5px',
                  border: '1px solid black',
                  margin: '0px',
                }}
              >
                New Price Tag
              </h5>
              <div
                style={{
                  border: '1px solid black',
                  width: '99.7%',
                  float: 'left',
                }}
              >
                <div style={{ width: '96%', float: 'left', margin: '2%' }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%' }}>
                          <table style={{ width: '100%', fontSize: '13px' }}>
                            <tbody>
                              <tr>
                                <td style={{ padding: '5px' }}>
                                  Depreciation Percentage
                                </td>
                                <td style={{ padding: '5px' }}>
                                  {validateData(priceData.newPriceTag[0]) && validateData(priceData.newPriceTag[0].depreciationPercentage)
                                    ? priceData.newPriceTag[0]
                                        .depreciationPercentage +"%"
                                    : 'null'}
                                  
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>
                                  Gross Profit Per Payment
                                </td>
                                <td style={{ padding: '5px' }}>
                                  {' '}
                                  {validateData(priceData.newPriceTag[0]) && validateData(priceData.newPriceTag[0].grossProfitPerPayment)
                                    ? formatter.format(priceData.newPriceTag[0]
                                        .grossProfitPerPayment)
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Turns</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.newPriceTag[0]) && validateData(priceData.newPriceTag[0].turns)
                                    ? priceData.newPriceTag[0].turns
                                    : 'null'}
                                </td>
                              </tr>
                              
                            </tbody>
                          </table>
                        </td>
                        <td style={{ width: '50%' }}>
                          <table style={{ width: '100%', fontSize: '13px' }}>
                            <tbody>
                              <tr>
                                <td style={{ padding: '5px' }}>Rate</td>
                                <td style={{ padding: '5px' }}>
                                  {/* ${' '} */}
                                  {validateData(priceData.newPriceTag[0]) && validateData(priceData.newPriceTag[0].rate)
                                    ? formatter.format(priceData.newPriceTag[0].rate)
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Term</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.newPriceTag[0]) && validateData(priceData.newPriceTag[0].term)
                                    ? priceData.newPriceTag[0].term
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Total Cost</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.newPriceTag[0]) && validateData(priceData.newPriceTag[0].totalCost)
                                    ? formatter.format(
                                        priceData.newPriceTag[0].totalCost
                                      )
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Cash Price </td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.newPriceTag[0]) && validateData(priceData.newPriceTag[0].cashPrice)
                                    ? formatter.format(
                                        priceData.newPriceTag[0].cashPrice
                                      )
                                    : 'null'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              style={{
                width: '49% ',
                float: 'left',
                marginLeft: '1%',
                marginBottom: '20px',
              }}
            >
              <h5
                style={{
                  padding: '5px',
                  border: '1px solid black',
                  margin: '0px',
                }}
              >
                Condition Price Tag
              </h5>
              <div
                style={{
                  border: '1px solid black',
                  width: '99.7%',
                  float: 'left',
                }}
              >
                <div style={{ width: '96%', float: 'left', margin: '2%' }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%' }}>
                          <table style={{ width: '100%', fontSize: '13px' }}>
                            <tbody>
                              <tr>
                                <td style={{ padding: '5px' }}>
                                  Depreciation Percentage
                                </td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.autoPriceTag[0]) && validateData(priceData.autoPriceTag[0].depreciationPercentage)
                                    ? priceData.autoPriceTag[0]
                                        .depreciationPercentage+"%"
                                    : 'null'}
                                  
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>
                                  Gross Profit Per Payment
                                </td>
                                <td style={{ padding: '5px' }}>
                                  {' '}
                                  {validateData(priceData.autoPriceTag[0]) && validateData(priceData.autoPriceTag[0].grossProfitPerPayment)
                                    ? formatter.format(priceData.autoPriceTag[0]
                                        .grossProfitPerPayment)
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Turns</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.autoPriceTag[0]) && validateData(priceData.autoPriceTag[0].turns)
                                    ? priceData.autoPriceTag[0].turns
                                    : 'null'}
                                </td>
                              </tr>
                              
                            </tbody>
                          </table>
                        </td>
                        <td style={{ width: '50%' }}>
                          <table style={{ width: '100%', fontSize: '13px' }}>
                            <tbody>
                              <tr>
                                <td style={{ padding: '5px' }}>Rate</td>
                                <td style={{ padding: '5px' }}>
                                  {' '}
                                  {validateData(priceData.autoPriceTag[0]) && validateData(priceData.autoPriceTag[0].rate)
                                    ? formatter.format(priceData.autoPriceTag[0].rate)
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Term</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.autoPriceTag[0]) && validateData(priceData.autoPriceTag[0].term)
                                    ? priceData.autoPriceTag[0].term
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Total Cost</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.autoPriceTag[0]) && validateData(priceData.autoPriceTag[0].totalCost)
                                    ? formatter.format(
                                        priceData.autoPriceTag[0].totalCost
                                      )
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Cash Price </td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.autoPriceTag[0]) && validateData(priceData.autoPriceTag[0].cashPrice)
                                    ? formatter.format(
                                        priceData.autoPriceTag[0].cashPrice
                                      )
                                    : 'null'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              style={{
                width: '49% ',
                float: 'left',
                marginRight: '1%',
                marginBottom: '10px',
              }}
            >
              <h5
                style={{
                  padding: '5px',
                  border: '1px solid black',
                  margin: '0px',
                }}
              >
                Current Price Tag
              </h5>
              <div
                style={{
                  border: '1px solid black',
                  width: '99.7% ',
                  float: 'left',
                }}
              >
                <div style={{ width: '96%', float: 'left', margin: '2%' }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%' }}>
                          <table style={{ width: '100%', fontSize: '13px' }}>
                            <tbody>
                              <tr>
                                <td style={{ padding: '5px' }}>
                                  Depreciation Percentage
                                </td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.currentPriceTag[0]) && validateData(priceData.currentPriceTag[0].depreciationPercentage)
                                    ? priceData.currentPriceTag[0]
                                        .depreciationPercentage +"%"
                                    : 'null'}
                                  
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>
                                  Gross Profit Per Payment
                                </td>
                                <td style={{ padding: '5px' }}>
                                  {' '}
                                  {validateData(priceData.currentPriceTag[0]) && validateData(priceData.currentPriceTag[0].grossProfitPerPayment)
                                    ? formatter.format(priceData.currentPriceTag[0]
                                        .grossProfitPerPayment)
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Turns</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.currentPriceTag[0]) && validateData(priceData.currentPriceTag[0].turns)
                                    ? priceData.currentPriceTag[0].turns
                                    : 'null'}
                                </td>
                              </tr>
                              
                            </tbody>
                          </table>
                        </td>
                        <td style={{ width: '50%' }}>
                          <table style={{ width: '100%', fontSize: '13px' }}>
                            <tbody>
                              <tr>
                                <td style={{ padding: '5px' }}>Rate</td>
                                <td style={{ padding: '5px' }}>
                                  {' '}
                                  {validateData(priceData.currentPriceTag[0]) && validateData(priceData.currentPriceTag[0].rate)
                                    ? formatter.format(priceData.currentPriceTag[0].rate)
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Term</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.currentPriceTag[0]) && validateData(priceData.currentPriceTag[0].term)
                                    ? priceData.currentPriceTag[0].term
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Total Cost</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.currentPriceTag[0]) && validateData(priceData.currentPriceTag[0].totalCost)
                                    ? formatter.format(
                                        priceData.currentPriceTag[0].totalCost
                                      )
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Cash Price </td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.currentPriceTag[0]) && validateData(priceData.currentPriceTag[0].cashPrice)
                                    ? formatter.format(
                                        priceData.currentPriceTag[0].cashPrice
                                      )
                                    : 'null'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              style={{
                width: '49% ',
                float: 'left',
                marginLeft: '1%',
                marginBottom: '10px ',
              }}
            >
              <h5
                style={{
                  padding: '5px',
                  border: '1px solid black',
                  margin: '0px',
                }}
              >
                Edit Price Tag
              </h5>
              <div
                style={{
                  border: '1px solid black',
                  width: '99.7%',
                  float: 'left',
                }}
              >
                <div style={{ width: '96%', float: 'left', margin: '2%' }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%' }}>
                          <table style={{ width: '100%', fontSize: '13px' }}>
                            <tbody>
                              <tr>
                                <td style={{ padding: '5px' }}>
                                  Depreciation Percentage
                                </td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.editPriceTag[0]) && validateData(priceData.editPriceTag[0].depreciationPercentage)
                                    ? priceData.editPriceTag[0]
                                        .depreciationPercentage+"%"
                                    : 'null'}
                                  
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>
                                  Gross Profit Per Payment
                                </td>
                                <td style={{ padding: '5px' }}>
                                  {' '}
                                  {validateData(priceData.editPriceTag[0]) && validateData(priceData.editPriceTag[0].grossProfitPerPayment)
                                    ? formatter.format(priceData.editPriceTag[0]
                                        .grossProfitPerPayment)
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Turns</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.editPriceTag[0]) && validateData(priceData.editPriceTag[0].turns)
                                    ? priceData.editPriceTag[0].turns
                                    : 'null'}
                                </td>
                              </tr>
                             
                            </tbody>
                          </table>
                        </td>
                        <td style={{ width: '50%' }}>
                          <table style={{ width: '100%', fontSize: '13px' }}>
                            <tbody>
                              <tr>
                                <td style={{ padding: '5px' }}>Rate</td>
                                <td style={{ padding: '5px' }}>
                                  {' '}
                                  {validateData(priceData.editPriceTag[0]) && validateData(priceData.editPriceTag[0].rate)
                                    ? formatter.format(priceData.editPriceTag[0].rate)
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Term</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.editPriceTag[0]) && validateData(priceData.editPriceTag[0].term)
                                    ? priceData.editPriceTag[0].term
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Total Cost</td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.editPriceTag[0]) && validateData(priceData.editPriceTag[0].totalCost)
                                    ? formatter.format(
                                        priceData.editPriceTag[0].totalCost
                                      )
                                    : 'null'}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '5px' }}>Cash Price </td>
                                <td style={{ padding: '5px' }}>
                                {validateData(priceData.editPriceTag[0]) && validateData(priceData.editPriceTag[0].cashPrice)
                                    ? formatter.format(
                                        priceData.editPriceTag[0].cashPrice
                                      )
                                    : 'null'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}
