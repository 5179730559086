/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
import { makeStyles } from '@rentacenter/racstrap';
export const transferReceiveStyles = () => {
  const useClasses = makeStyles((theme) => ({
    bottomButtonRight: {
      float: 'right',
      fontFamily: 'OpenSans-semibold',
      marginLeft: theme.typography.pxToRem(13),
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(28),
      paddingRight: theme.typography.pxToRem(26),
      //pointerEvents: "none"
    },
    title: {
      color: '#212529',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(18),
      marginBottom: theme.typography.pxToRem(40),
    },
    // w80: {
    //   width: "80%"
    // }
    buttonCenter: {
      padding: 'auto',
      marginLeft: theme.typography.pxToRem(160),
    },
    w70: {
      width: '70%',
    },
    w80: {
      width: '80%',
    },
    w55: {
      width: '60%',
    },
    // w100: {
    //   width: "100%"
    // // },
    // saveModalWidth: {
    //   '& div': {
    //     '& div': {
    //       maxWidth: "380px !important",
    //     }
    //   }
    // },
    popupStyle: {
      color: '#212529',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(18),
      marginBottom: theme.typography.pxToRem(20),
    },
    popupTitle: {
      color: '#212529',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(15),
      marginBottom: theme.typography.pxToRem(20),
    },
    mr7: {
      marginRight: theme.typography.pxToRem(120),
    },
    ml6: {
      marginLeft: theme.typography.pxToRem(58),
    },
    ml7: {
      marginLeft: theme.typography.pxToRem(90),
    },
    h2: {
      top: '-20% !important',
    },
    rejectResonsModalWidth: {
      '& div': {
        '& div': {
          maxWidth: '400px !important',
          maxHeight: '500px !important',
        },
      },
    },

    breadcrumblinkfont:{
      textDecoration:'none',
      color:'gray',
  },
  breadcrumb: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      marginBottom: theme.typography.pxToRem(10),
      listStyle: 'none',
    },
    breadcrumbItem: {
      width: 'auto',
      fontFamily: 'OpenSans-bold',
      fontSize: '12px',
    },
    breadcrumbItemActive: {
      width: 'auto',
      fontFamily: 'OpenSans-bold',
      fontSize: '12px',
      color: '#2179FE',
      paddingLeft: 0,
    },
    breadcrumbArrow: {
      '&:before': {
        content: '""',
        width: 0,
        height: 0,
        borderTop: '5px solid transparent',
        borderLeft: '7px solid #707070',
        borderBottom: '5px solid transparent',
        marginTop: 0,
        float: 'left',
        paddingRight: '13px',
        color: '#6c757d',
      },
    },
  }));
  return useClasses();
};
