/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-all-duplicated-branches */

import React, { useEffect, useState } from 'react';
import { ReactComponent as SortdownIcon } from '../../../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../../../assets/images/sort-up-icon.svg';
import NumberFormat from "react-number-format";
import { Grid, RACTable, RACTableCell, RACTableRow, CircularProgress, Typography } from '@rentacenter/racstrap';
import moment from 'moment';
import { ReactComponent as Alerticon } from '../../../../assets/images/No-records.svg';
import { globalStyles } from '../../../componentstyles/globalstyles';
import { fixedGridStyles } from '../../../componentstyles/fixedGridStyles';
import { validateObject } from '../../../Shared/validateObject';
import { useHistory } from 'react-router';
import { formatter } from '../../../Shared/DynamicGrid';

export interface dynamicProps {

  id?: string;
  items: any;
  locationType?: any;
  orderBy: string;
  order: string;
  ColumnLink?: boolean;
  gridtype:
  | 'Normal'
  | 'withSubChild'
  | 'withCheckBox'
  | 'withRadioBtn'
  | 'endWithCheckBox';

  handleActionType?: (value: any, index: any) => void;
  throwStyle?: any;
  isPrint?: boolean;
  setLoader?: boolean;
}
export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;

  subColumns?: columns[];
}
const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
}

const packagePriceColumns: columns[] = [
  {
    columnId: 'agreementNumber',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Agreement #',
    IsSorted: true,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'beginRv',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Begin RV',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'depreciation',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Depreciation',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'endRv',
    sortOrder: sortDirection.DESC,
    columnTitle: 'End RV',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'itemProjectRevenue',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Item Project Revenue',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'itemRevenue',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Item Revenue',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'revenueOrItemProjectTotal',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Revenue/Item Project Total',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'itemAllocation',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Item Allocation',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'openDate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Open Date',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'closeDate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Close Date',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'customer',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Customer Name',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'discount',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Discount',
    IsSorted: false,
    IsSortable: true,
  }
];

const DynamicGridRA = (props: dynamicProps) => {
  const isPrint = props.isPrint;
  const classes = globalStyles();
  const FGS = fixedGridStyles();
  const history = useHistory();
  const [gridValues, setGridValues] = useState<any[]>([]);
  const [gridColumn, setGridColumn] = useState<columns[]>([]);

  useEffect(() => {
    const { order, orderBy, items } = props;
    /* eslint-disable no-console */
    console.log('props.items', props.items);

    const sortedData =
      order == sortDirection.DESC
        ? items.sort((a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1))
        : items.sort((a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1));

    setGridValues(sortedData);
    setGridColumn(packagePriceColumns);
  }, [props]);

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) =>
          b[columnId] < a[columnId] ? -1 : 1
        )
        : dataValues.sort((a: any, b: any) =>
          a[columnId] < b[columnId] ? -1 : 1
        );
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };

  const sortArrayForDollar = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
        : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };

  const renderTableHead = (dataColumns: any, dataValues: any) => {
    return (
      <>
        {gridColumn.map((value, index) => {
          return (
            <RACTableCell key={index} className={classes.p2}>

              <a
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                id={value.columnId}
                className={value.columnTitle === 'Begin RV' || value.columnTitle === 'End RV' || value.columnTitle === 'Depreciation' || value.columnTitle === 'Item Project Revenue' || value.columnTitle === 'Item Revenue' || value.columnTitle === 'Revenue/Item Project Total' || value.columnTitle === 'Item Allocation' || value.columnTitle === 'Discount' ? classes.floatRight : value.columnTitle === 'Open Date' || value.columnTitle === 'Close Date' ? classes.textCenter : value.columnTitle === 'Customer Name' ? classes.floatLeft : ''}
                onClick={() =>
                  value.columnTitle === 'Agreement #' || value.columnTitle === 'Item Allocation' || value.columnTitle === 'Open Date' || value.columnTitle === 'Close Date' || value.columnTitle === 'Customer Name' || value.columnTitle === 'Discount' ?
                    sortArray(
                      value.columnId,
                      index,
                      dataColumns,
                      dataValues
                    )
                    :
                    sortArrayForDollar(
                      value.columnId,
                      index,
                      dataColumns,
                      dataValues
                    )
                }
              >
                {value.columnTitle === 'Revenue/Item Project Total' ?
                  <>
                    <div style={{ textAlign: 'center' }}>Revenue/Item</div>
                    <div style={{ textAlign: 'center',float:'left' }}>Project Total</div>
                  </>
                  :
                  value.columnTitle === 'Item Project Revenue' ?
                    <>
                      <div style={{ textAlign: 'center' }}>Item Project</div>
                      <div style={{ textAlign: 'center',float:'left' }}>Revenue</div>
                    </>
                    :
                    value.columnTitle === 'Item Allocation' ?
                      <>
                        <div style={{ textAlign: 'center' }}>Item</div>
                        <div style={{ textAlign: 'center',float:'left' }}>Allocation</div>
                      </>
                      :
                      value.columnTitle === 'Open Date' ?
                        <>
                          <div style={{ textAlign: 'center',float:'left' }}>Open Date</div>
                        </>
                        :
                        value.columnTitle + '  '
                }
                {value.IsSorted === true ? (
                  value.sortOrder == sortDirection.DESC ? (
                    <SortupIcon className={classes.ms0} />
                  ) : (
                    <SortdownIcon className={classes.ms0} />
                  )
                ) : null}
              </a>

            </RACTableCell>
          )
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  };

  const renderPrintTableHead = () => {
    return (
      <>
        {gridColumn.map((value, index) => {
          return (
            <RACTableCell key={index} style={{ border: '1px solid #000', backgroundColor: '#ccc', padding: '5px', fontSize: '14px' }}>
              {value.columnTitle}
            </RACTableCell>
          )
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  };

  // to round off the values, when the values are having more the 2 numbers after digit.
  const roundOff = (num: any, places: number) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  }
  const dynamicCss = (val: any) => {
    debugger;
    if (val === 'Active') {
      return `${classes.ahActive} ${classes.racpadLink} ${classes.racpadAgrLink}`
    }
    else if (val === 'Inactive') {
      return `${classes.ahInactive} ${classes.racpadLink} ${classes.racpadAgrLink}`
    }
    else if (val === 'Pastdue') {
      return `${classes.ahDue} ${classes.racpadLink} ${classes.racpadAgrLink}`
    }
    else if (val === 'Pending') {
      return `${classes.ahPending} ${classes.racpadLink} ${classes.racpadAgrLink}`
    }
    else {
      return `${classes.racpadLink} ${classes.racpadAgrLink}`
    }
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTableContent = (gridBodyData: any) => {
    if (validateObject(gridBodyData) && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        console.log('value.status', value.status)
        return (
          <React.Fragment key={index1}>
            <RACTableRow className={classes.bgTransparent}>
              <RACTableCell>
                {/* {props.locationType == 'radius' ?
                  value.agreementNumber :
                  <a className={dynamicCss(value.status)} onClick={()=> history.push(`/agreement/info/details/${value.customerId}/${value.agreementId}`)}>{value.agreementNumber}</a>
                } */}
                <a className={dynamicCss(value.status)} onClick={() => history.push(`/agreement/info/details/${value.agreementId}`)}>{value.agreementNumber}</a>
              </RACTableCell>
              <RACTableCell>
                {validateObject(value.beginRv) && (value.beginRv !== "0" || value.beginRv !== "0.00") ?
                  <NumberFormat
                    style={{ float: 'right' }}  
                    thousandsGroupStyle="thousand"
                    value={roundOff((value.beginRv), 3).toFixed(2)}
                    prefix="$ "
                    displayType="text"
                    type="text"
                    thousandSeparator={true}
                  /> : '-'}
              </RACTableCell>
              <RACTableCell>
                {validateObject(value.depreciation) && (value.depreciation !== "0" || value.depreciation !== "0.00") ?
                  <NumberFormat
                    style={{ float: 'right' }}
                    thousandsGroupStyle="thousand"
                    value={roundOff((value.depreciation), 3).toFixed(2)}
                    prefix="$ "
                    displayType="text"
                    type="text"
                    thousandSeparator={true}
                  /> : '-'}
              </RACTableCell>
              <RACTableCell>
                {validateObject(value.endRv) && (value.endRv !== "0" || value.endRv !== "0.00") && parseInt(value.endRv) >= 0 ?
                  <NumberFormat
                    style={{ float: 'right' }}
                    thousandsGroupStyle="thousand"
                    value={roundOff((value.endRv), 3).toFixed(2)}
                    prefix="$ "
                    displayType="text"
                    type="text"
                    thousandSeparator={true}
                  /> : '$ 0.00'}
              </RACTableCell>

              <RACTableCell>
                {validateObject(value.itemProjectRevenue) && (value.itemProjectRevenue !== "0" && value.itemProjectRevenue !== "0.00") ?
                  <NumberFormat
                    style={{ float: 'right' }}
                    thousandsGroupStyle="thousand"
                    value={roundOff((value.itemProjectRevenue), 3).toFixed(2)}
                    prefix="$ "
                    displayType="text"
                    type="text"
                    thousandSeparator={true}
                  /> : '-'}
              </RACTableCell>
              <RACTableCell className={classes.textCenter}>
                {validateObject(value.itemRevenue) && (value.itemRevenue !== "0" && value.itemRevenue !== "0.00") ?
                  <NumberFormat
                    // style={{ float: 'right' }}
                    thousandsGroupStyle="thousand"
                    value={roundOff((value.itemRevenue), 3).toFixed(2)}
                    prefix="$ "
                    displayType="text"
                    type="text"
                    thousandSeparator={true}
                  /> : '-'}
              </RACTableCell>
              {validateObject(value.revenueOrItemProjectTotal) && (value.revenueOrItemProjectTotal !== "0" && value.revenueOrItemProjectTotal !== "0.00") ?
              (<RACTableCell>
            
                  <NumberFormat
                    style={{ float: 'right' }}
                    thousandsGroupStyle="thousand"
                    value={roundOff((value.revenueOrItemProjectTotal), 3).toFixed(2)}
                    prefix="$ "
                    displayType="text"
                    type="text"
                    thousandSeparator={true}
                  /> 
              </RACTableCell>):(<RACTableCell style={{float:'right'}}>-</RACTableCell>)}
              <RACTableCell>
                <span style={{ float: 'right' }}>
                  {validateObject(value.itemAllocation) && (value.itemAllocation !== "0" && value.itemAllocation !== "0.00") ? roundOff(value.itemAllocation * 100, 0) + ' %' : '0 %'}
                </span>
              </RACTableCell>
              <RACTableCell className={classes.textCenter}>
                <span>
                  {validateObject(value.openDate) ? moment(value.openDate).format('MM/DD/YYYY') : '-'}
                </span>
              </RACTableCell>
              <RACTableCell className={classes.textCenter}>
                <span>
                  {validateObject(value.closeDate) ? moment(value.closeDate).format('MM/DD/YYYY') : '-'}
                </span>
              </RACTableCell>
              <RACTableCell className={classes.floatLeft} title={value.customer.length > 14 ? value.customer : null}>
                {validateObject(value.customer) ? truncString((value.customer), 14, '...') : '-'}
              </RACTableCell>
              <RACTableCell>
                <span style={{ float: 'right' }}>
                  {validateObject(value.discount) && (value.discount !== "0" && value.discount !== "0.00") ? value.discount : '-'}
                </span>
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };

  const renderTableMain = () => {

    if (props.setLoader == false) {
      if (validateObject(gridValues) && gridValues.length > 0) {
        return renderTableContent(gridValues)
      }
      else {
        return (
          <RACTableRow className={classes.hiddenRow} key="0">
            <RACTableCell colSpan={12} className={`${classes.textCenter} ${classes.mt4}`}>
              <Grid item md={12} className={classes.textCenter}>
                <Alerticon></Alerticon>
                <Typography className={classes.RACPOPMsg}>
                  No Records Found
                </Typography>
              </Grid>
            </RACTableCell>
          </RACTableRow>
        );
      }
    }
    else {
      return (
        <RACTableRow className={classes.hiddenRow} key="0">
          <RACTableCell colSpan={12}>
            <Grid className={classes.GridLoader}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </RACTableRow>
      )
    }
  }

  const renderPrintTableContent = (gridBodyData: any) => {
    if (validateObject(gridBodyData) && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {

        return (
          <React.Fragment key={index1}>
            <RACTableRow style={{ width: '100%' }}>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{value.agreementNumber}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>
                {validateObject(value.beginRv) && (value.beginRv !== "0" || value.beginRv !== "0.00") ?
                  formatter.format(value.beginRv) : '-'
                  // <NumberFormat
                  //   style={{ float: 'right' }}
                  //   thousandsGroupStyle="thousand"
                  //   value={roundOff((value.beginRv), 3).toFixed(2)}
                  //   prefix="$ "
                  //   displayType="text"
                  //   type="text"
                  //   thousandSeparator={true}
                  // /> : '-'
                }
              </RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>
                {validateObject(value.depreciation) && (value.depreciation !== "0" || value.depreciation !== "0.00") ?
                  formatter.format(value.depreciation) : '-'
                  // <NumberFormat
                  //   style={{ float: 'right' }}
                  //   thousandsGroupStyle="thousand"
                  //   value={roundOff((value.depreciation), 3).toFixed(2)}
                  //   prefix="$ "
                  //   displayType="text"
                  //   type="text"
                  //   thousandSeparator={true}
                  // /> : '-'
                  }
              </RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>
                {validateObject(value.endRv) && (value.endRv !== "0" || value.endRv !== "0.00") && parseInt(value.endRv) >= 0 ?
                  formatter.format(value.endRv) : '-'
                  // <NumberFormat
                  //   style={{ float: 'right' }}
                  //   thousandsGroupStyle="thousand"
                  //   value={roundOff((value.endRv), 3).toFixed(2)}
                  //   prefix="$ "
                  //   displayType="text"
                  //   type="text"
                  //   thousandSeparator={true}
                  // /> : '$ 0.00'
                }
              </RACTableCell>

              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>
                {validateObject(value.itemProjectRevenue) && (value.itemProjectRevenue !== "0" && value.itemProjectRevenue !== "0.00") ?
                  formatter.format(value.itemProjectRevenue) : '-'
                  // <NumberFormat
                  //   style={{ float: 'right' }}
                  //   thousandsGroupStyle="thousand"
                  //   value={roundOff((value.itemProjectRevenue), 3).toFixed(2)}
                  //   prefix="$ "
                  //   displayType="text"
                  //   type="text"
                  //   thousandSeparator={true}
                  // /> : '-'
                }
              </RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>
                {validateObject(value.itemRevenue) && (value.itemRevenue !== "0" && value.itemRevenue !== "0.00") ?
                  formatter.format(value.itemRevenue) : '-'
                  // <NumberFormat
                  //   style={{ float: 'right' }}
                  //   thousandsGroupStyle="thousand"
                  //   value={roundOff((value.itemRevenue), 3).toFixed(2)}
                  //   prefix="$ "
                  //   displayType="text"
                  //   type="text"
                  //   thousandSeparator={true}
                  // /> : '-'
                  }
              </RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>
                {validateObject(value.revenueOrItemProjectTotal) && (value.revenueOrItemProjectTotal !== "0" && value.revenueOrItemProjectTotal !== "0.00") ?
                  formatter.format(value.revenueOrItemProjectTotal) : '-'
                  // <NumberFormat
                  //   style={{ float: 'right' }}
                  //   thousandsGroupStyle="thousand"
                  //   value={roundOff((value.revenueOrItemProjectTotal), 3).toFixed(2)}
                  //   prefix="$ "
                  //   displayType="text"
                  //   type="text"
                  //   thousandSeparator={true}
                  // /> : '-'
                  }
              </RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px', float: 'right' }}>{validateObject(value.itemAllocation) && (value.itemAllocation !== "0" && value.itemAllocation !== "0.00") ? roundOff(value.itemAllocation * 100, 0) + ' %' : '0 %'}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{validateObject(value.openDate) && value.openDate != '' ? moment(value.openDate).format('MM/DD/YYYY') : '-'}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{validateObject(value.closeDate) && value.closeDate != '' ? moment(value.closeDate).format('MM/DD/YYYY') : '-'}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px' }}>{validateObject(value.customer) ? value.customer : '-'}</RACTableCell>
              <RACTableCell style={{ padding: '5px', fontSize: '14px', float: 'right' }}>{validateObject(value.discount) && (value.discount !== "0" && value.discount !== "0.00") ? value.discount : '-'}</RACTableCell>
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };

  return (
    <>
      {isPrint ?
        <RACTable
          renderTableHead={() => renderPrintTableHead()}
          renderTableContent={() => renderPrintTableContent(gridValues)}
        /> :
        <Grid className={`${FGS.fixTableHeadRA} ${FGS.fixTableHeight275}`}>
          <RACTable
            renderTableHead={() => renderTableHead(gridColumn, gridValues)}
            renderTableContent={() => renderTableMain()}
          />
        </Grid>
      }
    </>
  );
};

export default DynamicGridRA;
