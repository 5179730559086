/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { makeStyles } from '@rentacenter/racstrap';
export const fixedGridStyles = () => {
  const useClasses = makeStyles((theme) => ({
    fixTableHead: {
      overflowY: 'auto',
      maxHeight: '205px',
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: 'OpenSans-semibold',
        zIndex: 1,
        padding: '0.9375rem 0.2rem',
        whiteSpace: 'nowrap',
      },
      '& tbody': {
        '& tr': {
          position: 'sticky',
          top: 10,
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: 'OpenSans-regular',
          padding: '0.9375rem 0.2rem',
        },
      },
    },
    fixTableHeight300: {
      maxHeight: '300px',
  },
  fixTableHeight400: {
    maxHeight: '400px',
},
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useClasses();

  return classes;
};
