/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const InventoryGlobalStyles = () => {
  const useClasses = makeStyles((theme) => ({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(189, 189, 196)',
        borderRadius: '20px',
        border: '3px solid rgb(206, 204, 201)',
      },
      '*::-webkit-scrollbar-track': {
        background: 'rgb(245, 243, 239)',
      },
    },
    title: {
      color: '#212529',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(10),
    },
    subTitle: {
      color: '#212529',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.typography.pxToRem(10),
    },
    fixedFooter: {
      position:'fixed',
      bottom:0,
      left:0,
      display:'flex',
      justifyContent:'space-between',
      padding: theme.typography.pxToRem(16),
      backgroundColor:'#ffffff',
      boxShadow:'0 0 3px #d9d9d9',
    },
    w95: {
      width: '95%',
    },
    pl4: {
      paddingLeft: theme.typography.pxToRem(24),
    },
    modalWidth:{
      '& div':{
        '& div':{
          maxWidth: "450px !important",
        },
      }
      
    },    
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
    },
    mbFooterSpacing: {
      marginBottom: theme.typography.pxToRem(98),
    },
    widthAuto: {
      width: 'auto',
    },
    borderNone:{
      border:theme.typography.pxToRem(0),
    },
    mbody:{
      position: "relative",
    flex: "1 1 auto",
    padding: "0rem 1rem 1rem 1rem",
    },
    w50: {
      width: '50%',
    },
    w100: {
      width: '100%',
    },
    textWhite: {
      color: `${RACCOLOR.WHITE}`,
    },
    textRed: {
      color: `${RACCOLOR.MVS_RED}`,
    },
    textBlue: {
      color: `${RACCOLOR.NORMAL_BLUE}`,
    },
    textBlack: {
      color: `${RACCOLOR.WASHED_BLACK}`,
    },
    racLightBlue: {
      backgroundColor: `${RACCOLOR.LAVENDER_MIST}`,
    },
    racBlue: {
      backgroundColor: `${RACCOLOR.NORMAL_BLUE}`,
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.typography.pxToRem(14),
      marginRight: theme.typography.pxToRem(14),
      marginLeft: theme.typography.pxToRem(14),
    },
    formControl: {
      display: 'block',
      width: '100%',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#212529',
      backgroundColor: `${RACCOLOR.WHITE}`,
      border: '1px solid #ced4da',
      appearance: 'none',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '0.25rem',
      borderBottomRightRadius: '0.25rem',
      textAlign: 'right',
    },

    floatLeft: {
      float: 'left',
    },
    floatRight: {
      float: 'right',
    },

    m0: {
      margin: 0,
    },

    m1: {
      margin: theme.typography.pxToRem(4),
    },

    m2: {
      margin: theme.typography.pxToRem(8),
    },

    m3: {
      margin: theme.typography.pxToRem(16),
    },

    m4: {
      margin: theme.typography.pxToRem(24),
    },

    m5: {
      margin: theme.typography.pxToRem(48),
    },

    mAuto: {
      margin: 'auto',
    },

    mx0: {
      marginRight: 0,
      marginLeft: 0,
    },

    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    mx2: {
      marginRight: theme.typography.pxToRem(8),
      marginLeft: theme.typography.pxToRem(8),
    },

    mx3: {
      marginRight: theme.typography.pxToRem(16),
      marginLeft: theme.typography.pxToRem(16),
    },
    mx4: {
      marginRight: theme.typography.pxToRem(24),
      marginLeft: theme.typography.pxToRem(24),
    },

    mx5: {
      marginRight: theme.typography.pxToRem(48),
      marginLeft: theme.typography.pxToRem(48),
    },

    mxAuto: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    my0: {
      marginTop: 0,
      marginBottom: 0,
    },

    my1: {
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },

    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },

    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },

    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },

    my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
    },

    myAuto: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },

    mt0: {
      marginTop: 0,
    },

    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },

    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },

    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },

    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },

    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },

    mtAuto: {
      marginTop: 'auto',
    },

    me0: {
      marginRight: 0,
    },

    me1: {
      marginRight: theme.typography.pxToRem(4),
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },

    me3: {
      marginRight: theme.typography.pxToRem(16),
    },

    me4: {
      marginRight: theme.typography.pxToRem(24),
    },

    me5: {
      marginRight: theme.typography.pxToRem(48),
    },

    meAuto: {
      marginRight: 'auto',
    },

    mb0: {
      marginBottom: 0,
    },

    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },

    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },

    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },

    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },

    mbAuto: {
      marginBottom: 'auto',
    },

    ms0: {
      marginLeft: 0,
    },

    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },

    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },

    ms3: {
      marginLeft: theme.typography.pxToRem(16),
    },

    ms4: {
      marginLeft: theme.typography.pxToRem(24),
    },

    ms5: {
      marginLeft: theme.typography.pxToRem(48),
    },

    msAuto: {
      marginLeft: 'auto',
    },
    pr0: {
      paddingRight: theme.typography.pxToRem(0),
    },
    pr1: {
      paddingRight: theme.typography.pxToRem(4),
    },
    pr2: {
      paddingRight: theme.typography.pxToRem(8),
    },
    pr3: {
      paddingRight: theme.typography.pxToRem(16),
    },
    pr4: {
      paddingRight: theme.typography.pxToRem(24),
    },
    pr5: {
      paddingRight: theme.typography.pxToRem(48),
    },
    prAuto: {
      paddingRight: 'auto',
    },
    p0: {
      padding: 0,
    },

    p1: {
      padding: theme.typography.pxToRem(4),
    },

    p2: {
      padding: theme.typography.pxToRem(8),
    },

    p3: {
      padding: theme.typography.pxToRem(16),
    },

    p4: {
      padding: theme.typography.pxToRem(24),
    },

    p5: {
      padding: theme.typography.pxToRem(48),
    },

    px0: {
      paddingRight: 0,
      paddingLeft: 0,
    },

    px1: {
      paddingRight: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(4),
    },

    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },

    px3: {
      paddingRight: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(16),
    },

    px4: {
      paddingRight: theme.typography.pxToRem(24),
      paddingLeft: theme.typography.pxToRem(24),
    },

    px5: {
      paddingRight: theme.typography.pxToRem(48),
      paddingLeft: theme.typography.pxToRem(48),
    },

    py0: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },

    py2: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },

    py3: {
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },

    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },

    py5: {
      paddingTop: theme.typography.pxToRem(48),
      paddingBottom: theme.typography.pxToRem(48),
    },

    pt0: {
      paddingTop: 0,
    },

    pt1: {
      paddingTop: theme.typography.pxToRem(4),
    },

    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },

    pt3: {
      paddingTop: theme.typography.pxToRem(16),
    },

    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },

    pt5: {
      paddingTop: theme.typography.pxToRem(48),
    },

    pe0: {
      paddingRight: 0,
    },

    pe1: {
      paddingRight: theme.typography.pxToRem(4),
    },

    pe2: {
      paddingRight: theme.typography.pxToRem(8),
    },

    pe3: {
      paddingRight: theme.typography.pxToRem(16),
    },

    pe4: {
      paddingRight: theme.typography.pxToRem(24),
    },

    pe5: {
      paddingRight: theme.typography.pxToRem(48),
    },

    pb0: {
      paddingBottom: 0,
    },

    pb1: {
      paddingBottom: theme.typography.pxToRem(4),
    },

    pb2: {
      paddingBottom: theme.typography.pxToRem(8),
    },

    pb3: {
      paddingBottom: theme.typography.pxToRem(16),
    },

    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },

    pb5: {
      paddingBottom: theme.typography.pxToRem(48),
    },

    ps0: {
      paddingLeft: 0,
    },

    ps1: {
      paddingLeft: theme.typography.pxToRem(4),
    },

    ps2: {
      paddingLeft: theme.typography.pxToRem(8),
    },

    ps3: {
      paddingLeft: theme.typography.pxToRem(16),
    },

    ps4: {
      paddingLeft: theme.typography.pxToRem(24),
    },

    ps5: {
      paddingLeft: theme.typography.pxToRem(48),
    },

    font11: {
      fontSize: theme.typography.pxToRem(11),
    },

    font12: {
      fontSize: theme.typography.pxToRem(12),
    },

    font13: {
      fontSize: theme.typography.pxToRem(13),
    },

    font14: {
      fontSize: theme.typography.pxToRem(14),
    },

    font15: {
      fontSize: theme.typography.pxToRem(15),
    },

    font16: {
      fontSize: theme.typography.pxToRem(16),
    },

    font17: {
      fontSize: theme.typography.pxToRem(17),
    },

    font18: {
      fontSize: theme.typography.pxToRem(18),
    },
    textLeft: {
      textAlign: 'left',
    },

    textCenter: {
      textAlign: 'center',
    },

    textRight: {
      textAlign: 'right',
    },
    /* Text Alignment */

    semiBold: {
      fontFamily: 'OpenSans-semibold',
    },

    bold: {
      fontFamily: 'OpenSans-Bold',
    },

    formLabel: {
      marginBottom: '0 rem',
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    racpadLink: {
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
      fontSize: theme.typography.pxToRem(14),
      textDecoration: 'none',
      cursor: 'pointer',
      // fontFamily: "OpenSans-bold",
    },
    masterLoader: {
      position: 'fixed',
      backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 1050,
      textAlign: 'center',
      margin: 0,
    },
    Loader: {
      display: 'block',
      position: 'fixed',
      zIndex: 1051,
      top: '50%',
      right: '50%',
    },
    GridLoader: {
      textAlign: 'center',
      marginBottom: theme.typography.pxToRem(20),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      margin: '30px 0px',
    },
    fixedBottom: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      padding: '1rem',
    },
    Norecords: {
      textAlign: 'center',
      marginTop: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = useClasses();

  return classes;
};
