/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useState } from 'react';
import { ReactComponent as RACLogo } from '../../../assets/images/RAC-Logo.svg';
import { ReactComponent as Erroricon } from '../../../assets/images/error-icon.svg';

// import OtpInput from 'react-otp-input';

import {
  CircularProgress,
  Typography,
  Grid,
  RACButton,
  RACTextbox,
  RACModalCard,
  makeStyles,
  RACCOLOR,
} from '@rentacenter/racstrap';
// import PinField from 'react-pin-field';
import clsx from 'clsx';
import PinField from 'react-pin-input';

// import '../../styles/pin.css';
// import '../../Styles/global.css';
import {
  getCurrentInfo,
  getPinDetails,
  getprofileaccess,
  getsecondfactor,
} from '../../../api/user';

export type PropsInterface = {
  setTwoFactorCancelClick?: any;
  setTwoFactorCompleted?: any;
  setRoleFromSLAModule?: any;
  setEmployeeDetails?: any;
  moduleName?: string;
  currentRole?: string;
  minRole?: string;
  agrRate?: any;
  throwCurrentPage?: string;
};
const useClasses = makeStyles((theme: any) => ({
  textCenter: {
    textAlign: 'center',
  },
  logoResponsive: {},
  spacerMargin: {
    margin: theme.typography.pxToRem(8),
  },
  spacerMT1: {
    marginTop: theme.typography.pxToRem(4),
  },
  racLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  floatRight: {
    float: 'right',
  },
  spacerMT5: {
    marginTop: theme.typography.pxToRem(48),
  },
  spacerMB3: {
    marginBottom: theme.typography.pxToRem(24),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  spacerME2: {
    marginRight: theme.typography.pxToRem(16),
  },
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  racPinContainer: {
    margin: '0 auto',
    width: '85%',
    paddingLeft: '15px',
  },
  racPinLabel: {
    fontFamily: 'OpenSans-semibold',
    color: `${RACCOLOR.RICH_BLACK}`,
    marginLeft: '0.5rem',
    marginBottom: '3px',
  },
  racPinError: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    marginTop: '5px',
    marginLeft: '0.5rem',
    fontFamily: 'OpenSans-semibold',
  },
  racLoader: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, 85%)',
  },
  paddingButtons: {
    paddingRight: '45px',
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
}));
export default function SecondFactor(props: PropsInterface) {
  const classes = useClasses();
  const {
    setTwoFactorCancelClick,
    setTwoFactorCompleted,
    setRoleFromSLAModule,
    setEmployeeDetails,
    minRole,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [thresHoldExceptionMessage, setthresHoldExceptionMessage] =
    useState('');
  const [openRateAlertModal, setopenRateAlertModal] = useState(false);

  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState('');
  const [openPinModal, setOpenPinModal] = useState(false);
  // eslint-disable-next-line prefer-const
  let [otp, setotp] = useState('');
  const [loader, setloader] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingone, setsomethingone] = React.useState(
    'modal fade show expandPopup'
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingtwo, setsomethingtwo] = React.useState('modal fade');

  const [InvalidPin, setInvalidPin] = React.useState<any>();
  //   const [InvalidPin1, setInvalidPin1] = React.useState(false);
  const [Continue, setContinue] = React.useState(true);

  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };

  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const loginClick = async (username: any, Password: any) => {
    console.log('username,password', username, Password);
    setRoleFromSLAModule !== undefined ? setRoleFromSLAModule(username) : null;
    //setRoleFromSLAModule(username);
    setOpenModal(false);
    setloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      password: Password,
    };
    console.log('SLApayload', payload);
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    console.log('SLA result', result);
    setloader(false);
    UserValidate(result);
  };

  const UserValidate = (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;
        setTwoFactorCancelClick(false);
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else if (
      result.status == 400 &&
      props.throwCurrentPage == 'ItemSearch' &&
      result.data.errors[0].error !== 'Error in LoginAuthenticationservice'
    ) {
      let allowedRate;
      let minAmount;
      // var ResultRole = "AM";
      if (props.currentRole == 'CAR') {
        setthresHoldExceptionMessage(
          `Override is invalid. SLA user can override till $${props.agrRate}`
        );
      } else if (
        props.currentRole == 'AM' ||
        props.currentRole == 'LAM' ||
        props.currentRole == 'SM' ||
        props.currentRole == 'FSC-AM' ||
        props.currentRole == 'FSC-LAM' ||
        props.currentRole == 'FSC-SM'
      ) {
        allowedRate = Math.round(props.agrRate * 0.1);
        minAmount = props.agrRate - allowedRate;
        setthresHoldExceptionMessage(
          `Override is invalid. SLA user can override from $${parseFloat(
            minAmount.toString()
          ).toFixed(2)} to $${props.agrRate}`
        );
      } else if (
        props.currentRole == 'DMT' ||
        props.currentRole == 'DM' ||
        props.currentRole == 'RD' ||
        props.currentRole == 'FSC-DMT' ||
        props.currentRole == 'FSC-DM' ||
        props.currentRole == 'FSC-RD'
      ) {
        allowedRate = Math.round(props.agrRate * 0.5);
        minAmount = props.agrRate - allowedRate;
        setthresHoldExceptionMessage(
          `Override is invalid. SLA user can override from $${parseFloat(
            minAmount.toString()
          ).toFixed(2)} to $${props.agrRate}`
        );
      }
      setOpenModal(false);
      setopenRateAlertModal(true);
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };

  useEffect(() => {
    setsomethingone('modal fade show expandPopup');
    setsomethingtwo('modal fade');
    const payload = {
      module: props.moduleName || window.localStorage.getItem('moduleName'),
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      currentRole:
        props.currentRole || window.localStorage.getItem('currentRole'),
    };
    const PageLoad = async () => {
      const result = await getprofileaccess(payload);
      setloader(false);
      setRole(result.data.role);
      popupSelect(result);
    };
    if (minRole !== undefined && minRole !== '') {
      setloader(false);
      setRole(minRole);
      console.log('minRole in two fact', minRole);

      const Roles = [
        'CAR',
        'FSC-CAR',
        'AM',
        'FSC-AM',
        'LAM',
        'FSC-LAM',
        'SM',
        'FSC-SM',
        'DMT',
        'FSC-DMT',
        'DM',
        'FSC-DM',
        'RD',
        'FSC-RD',
      ];

      console.log(
        'Roles.indexOf(String(props.currentRole))',
        Roles.indexOf(String(props.currentRole))
      );
      console.log('Roles.indexOf(minRole)', Roles.indexOf(minRole));

      const result = {
        data: {
          pin: 0,
          secondFactor:
            Roles.indexOf(String(props.currentRole)) <= Roles.indexOf(minRole)
              ? 1
              : 0,
        },
      };
      console.log('result in sec fact', result);

      popupSelect(result);
    } else {
      PageLoad();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popupSelect = (result: any) => {
    if (result.data.pin === 1 && result.data.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.data.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.data.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };

  const openModelCancelClick = () => {
    setErrorLine(false);
    setOpenModal(false);
    setTwoFactorCompleted(false);
    setTwoFactorCancelClick(true);
  };

  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);

  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    const userResponse = await getCurrentInfo();
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse.data)
      : null;
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    const result = await getPinDetails(payload);
    setloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
      setTwoFactorCancelClick(false);
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
      setTwoFactorCancelClick(false);
    }
  };

  const noClick = () => {
    setOpenPinModal(false);
    setTwoFactorCompleted(false);
    setTwoFactorCancelClick(true);
  };

  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };

  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };

  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };

  const pinValue = (event: any) => {
    otp = event;
  };

  //eslint-disable-next-line sonarjs/no-identical-functions
  const onCloseClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
  };

  const onCloseSecondfacClick = () => {
    setOpenModal(false);
    setTwoFactorCompleted(false);
    setTwoFactorCancelClick(true);
  };
  //eslint-disable-next-line sonarjs/no-identical-functions
  const onPinCloseClick = () => {
    setOpenPinModal(false);
    setTwoFactorCompleted(false);
    setTwoFactorCancelClick(true);
  };

  const openModalContent = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo className={classes.logoResponsive}></RACLogo>
        </Grid>
        {errorline === true ? (
          <Grid className={classes.spacerMargin}>
            <Typography className={classes.racLoginAlert}>
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={classes.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => secondfactorEnter(event, userID, password)}
              isCurrency={false}
              type="password"
              value={password}
              OnChange={(event) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          {userID !== '' && password !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.disabled}
            >
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  const openAlertModalContent = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          {/* <img className={classes.racErrorIcon} src={Erroricon} alt="Logo" /> */}
          <Erroricon className={classes.racErrorIcon} />
          <Typography className={classes.racErrorFunction}>
            The user you entered can not authorize the use of this function.
          </Typography>
        </Grid>
        <Grid className={classes.textCenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={okClick}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const openPinModalContent = () => {
    return (
      <div>
        <Grid container className={classes.racPinContainer}>
          <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid item md={12} onKeyPress={pinEnter}>
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: '0.3rem' }}
              inputStyle={{
                border: '1px solid #adb5bd',
                borderRadius: '0.6rem',
                marginRight: '20px',
              }}
              onComplete={(event) => pinValue(event)}
              onChange={(event) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className={classes.racPinError}>
                Invalid PIN{' '}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.paddingButtons
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={noClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={Continue}
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };

  const acceptRateAlert = () => {
    setopenRateAlertModal(false);
    setOpenModal(true);
    setErrorLine(true);
  };

  const openRateAlert = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          {/* <img className={classes.racErrorIcon} src={Erroricon} alt="Logo" /> */}
          <Erroricon className={classes.racErrorIcon} />
          <Typography className={classes.racErrorFunction}>
            {thresHoldExceptionMessage}
          </Typography>
        </Grid>
        <Grid className={classes.textCenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={acceptRateAlert}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      {loader === true ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <RACModalCard
        isOpen={openModal}
        borderRadius="20px"
        onClose={onCloseSecondfacClick}
        maxWidth={'xs'}
        className="racSecondFacPopup"
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openModalContent()}
      ></RACModalCard>
      <RACModalCard
        isOpen={openSecondModal}
        borderRadius="20px"
        closeIcon={true}
        onClose={onCloseClick}
        maxWidth={'sm'}
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openAlertModalContent()}
      ></RACModalCard>
      <RACModalCard
        isOpen={openPinModal}
        borderRadius="20px"
        className="racPinPopup"
        closeIcon={true}
        onClose={onPinCloseClick}
        maxWidth={'xs'}
        title={'Enter PIN to continue'}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalContent()}
      ></RACModalCard>

      <RACModalCard
        isOpen={openRateAlertModal}
        maxWidth={'sm'}
        //eslint-disable-next-line react/no-children-prop
        children={openRateAlert()}
      ></RACModalCard>
    </div>
  );
}
