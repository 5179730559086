/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-all-duplicated-branches */
// import { printPackageTagList } from './printPackgeTags'

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Filter } from "../../../assets/images/filter-icon.svg";
import { ReactComponent as WrongIcon } from "../../../assets/images/no-records-found.svg";
import { ReactComponent as NoRecord } from '../../../assets/images/No-records.svg';
import {
  Grid,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  RACModalCard,
  RACButton,
  RACSelect,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../components/componentstyles/globalstyles"
import DynamicGridComponent from "../../Shared/DynamicGrid"
import { getCoworkerDetails, getCurrentUser, getPackagePriceTag, getPackagePriceTagList, getprofileaccess, postbreakPackage } from "../../../api/user";
import {
  PriceModifiedDD, languageDD,
  API_ERROR_MESSAGE,
} from "../../../constants/constants";
import { printHTML } from "../../Shared/print";
import SecondFactor from "../../Shared/secondAuthen";
import { formStyles } from "../../componentstyles/formstyles"
import { validateData } from "../../../constants/CommonMethods";
import PrintPackagePrice from "./PackagePricePrintDoc";
import { PackagePriceContext } from "../../../context/PackagePriceTagContext";
import { ContainerContext } from "../../../app/App";
import { CustomPropInterface } from "../../../index";


export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: "link" | "rate";
  IsCheckbox?: boolean;
  handleColumnClick?: () => void;
  subColumns?: columns[];
}

const sortDirection = {
  ASC: "asc",
  DESC: "desc",
};

const Columns: columns[] = [
  {
    columnId: "primaryItem",
    sortOrder: sortDirection.ASC,
    columnTitle: "Primary Item #",
    IsSorted: true,
    columnType: "link",
    IsSortable: true,
    IsCheckbox: true,
  },
  {
    columnId: "packageName",
    sortOrder: sortDirection.DESC,
    columnTitle: "Package Name",
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: "packageRate",
    sortOrder: sortDirection.DESC,
    columnTitle: "Package Rate",
    IsSorted: false,
    // columnType: "rate",
    IsSortable: true,
  },
  {
    columnId: "packageTerm",
    sortOrder: sortDirection.DESC,
    columnTitle: "Package Term",
    IsSorted: false,
    IsSortable: true,

  },
  {
    columnId: "depreciation",
    sortOrder: sortDirection.DESC,
    columnTitle: "Depreciation",
    IsSorted: false,
    IsSortable: true,

  },
  {
    columnId: "isPriceModified",
    sortOrder: sortDirection.DESC,
    columnTitle: "Is Price Modified ?",
    IsSorted: false,
    IsSortable: true,

  },
];
const PackagePriceTags = (props: any) => {
  const classes = globalStyles();
  const form = formStyles();
  const History = useHistory();

  const { packageContextValue } = useContext(PackagePriceContext)
  const [packageDetailContext, setpackageDetailContext] = packageContextValue;

  const [select, setselect] = useState(false);
  const [gridValues, setGridValues] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>(false);
  const [gridColumn, setGridColumn] = useState<any>(Columns);
  const [orderBy, setorderBy] = useState<string>("primaryItem");
  const [order, setorder] = useState<string>(sortDirection.ASC);
  const [filterValue, setfilterValue] = useState<any>('0');
  const [applyFBtn, setapplyFBtn] = useState(true)
  const [packageValues, setPackageValues] = useState<any>([]);
  const [packageValuesTemp, setPackageValuesTemp] = useState<any>([]);
  const [gridLoader, setgridLoader] = useState<boolean>(false)
  const [dropDownLoaded, setdropDownLoaded] = useState(false);
  const [ddfilterValue, setDDfilterValue] = useState("English");

  const [secondLevelPin, setsecondLevelPin] = useState<any>(false);
  const [errMsg, seterrMsg] = useState<any>({ wentWrong: false, noRecord: false });
  const [enablePopup, setEnablePopup] = useState<any>({ openPopUp: false, confirmPrint: false, breakPackage: false,failedBreakPackage:false });
  const [currentRole, setcurrentRole] = useState<any>();
  const storeNumber = validateData(window.sessionStorage.getItem('storeNumber')) ? window.sessionStorage.getItem('storeNumber') : "";
  const [packagePriceTags, setPackagePriceTags] = useState<any>([]);
  const [btnLoader, setbtnLoader] = useState<any>(false);
  const [packageIds, setpackageIds] = useState<any>({ toPrint: [], toBreak: [], notToPrint: [] });
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [profileDetails,setprofileDetails]=useState<any>();
  const [employeeId,setemployeeId]=useState<any>();
  const [initialColumns,setinitialColumns]=useState<any>(Columns);

  useEffect(() => {
    setorderBy('primaryItem');
    setGridColumn(initialColumns);
    getPackagePrice();
    if(containerData!==undefined){
      setcurrentRole(containerData?.GetRole())
      setemployeeId(containerData?.GetEmployeeId())
    }
    else{
      getCoWorkerRole();
    }
    const getprofileaccessDetails=async()=>{
      const payload:any={
        module:'Print Updated Price Tag',
        storeNumber:storeNumber,
        currentRole:containerData?.GetRole()
        }
        const result = await getprofileaccess(payload);
        validateData(result)?
        setprofileDetails(result):
        setprofileDetails(undefined);
    }
    getprofileaccessDetails();
    
  }, []);


  const getPackagePrice = async () => {
    setDDfilterValue("English");
    setgridLoader(true);
    const getPackagePriceResponse = await getPackagePriceTagList(storeNumber);

    console.log("getPackagePriceResponse", getPackagePriceResponse);

    setgridLoader(false);
    
    if (validateData(getPackagePriceResponse) && validateData(getPackagePriceResponse.data)) {
      console.log("getPackagePriceResponse1", getPackagePriceResponse.data);

      if (validateData(getPackagePriceResponse.data.packagePriceTagList)) {

        console.log("getPackagePriceResponse2", getPackagePriceResponse.data.packagePriceTagList);
        const packageResponse = getPackagePriceResponse.data.packagePriceTagList
        setorderBy('primaryItem');
        setGridColumn(initialColumns);
        setPackageValues(packageResponse);
        setPackageValuesTemp(packageResponse);

      }
      else {
        setPackageValues([]);
        seterrMsg({ ...errMsg, noRecord: true });
      }
    }
    else {
      seterrMsg({ ...errMsg, wentWrong: true });
    }

  };


  const getCoWorkerRole = async () => {
    try {
      const currentUserRes: any = await getCurrentUser();
      console.log('currentUserRes', currentUserRes);
      if (currentUserRes.status === 200) {
        const EmployeeId = currentUserRes.data.employeeId;
        console.log('EmployeeId', EmployeeId);
        const payload = {
          coworkerId: EmployeeId,
          storesRequired: true
        }
        console.log('menuandstores-payload', payload);
        const coWorkerRoleObj: any = await getCoworkerDetails(payload);
        if (
          validateData(coWorkerRoleObj) && coWorkerRoleObj.status == 200 &&
          validateData(coWorkerRoleObj.data.coworkerProfile) &&
          validateData(coWorkerRoleObj.data.coworkerProfile.role)
        ) {
          setcurrentRole(coWorkerRoleObj.data.coworkerProfile.role);
        }
        console.log('coWorkerRoleObj', coWorkerRoleObj);
      }
    }
    catch (e: any) {
      console.log('Error in co worker role', e);
    }
  };


  const setGridColumns = (value: any) => {
    setGridColumn(value);
  };



  let packageToPrintWithNo: any = packageIds.toPrint;
  let packageToPrintWithYes: any = packageIds.notToPrint;
  let packageToBreak: any = packageIds.toBreak;

  const handleChecked = (e: any, index: any, value: any) => {

    if (e.target.checked === true) {
      if (value.isPriceModified == "No") {
        packageToPrintWithNo.push(parseInt(value.packagePriceId));
      }
      else {
        packageToPrintWithYes.push(parseInt(value.packagePriceId));
      }
      packageToBreak.push(parseInt(value.packagePriceId));

    }
    else {

      if (value.isPriceModified == "No") {
        packageToPrintWithNo.pop(value.packagePriceIds);
      }
      else {
        packageToPrintWithYes.pop(value.packagePriceIds);
      }
      packageToBreak.pop(value.packagePriceIds);

    }

    setpackageIds({ ...packageIds, toPrint: packageToPrintWithNo, toBreak: packageToBreak, notToPrint: packageToPrintWithYes });

  };


  const handleCheckedAll = (e: any, value: any) => {
    setpackageIds({ ...packageIds, toPrint: [], notToPrint: [], toBreak: [] })

    if (e.target.checked) {

      value.filter((data: any) => {

        if (data.isPriceModified == 'No') {
          packageToPrintWithNo.push(parseInt(data.packagePriceId));
        }
        else {
          packageToPrintWithYes.push(parseInt(data.packagePriceId));
        }
        packageToBreak.push(parseInt(data.packagePriceId));

      })

      setpackageIds({ ...packageIds, toPrint: packageToPrintWithNo, toBreak: packageToBreak, notToPrint: packageToPrintWithYes });
      setselect(true);

    } else {
      setselect(false);
      setpackageIds({ ...packageIds, toPrint: [], notToPrint: [], toBreak: [] });
    }

  };


  const secondLevelCompleted = (e: any) => {
    if (e === true) {
      setsecondLevelPin(false)
      setEnablePopup({ ...enablePopup, openPopUp: true, confirmPrint: true, breakPackage: false,failedBreakPackage:false })
      setDDfilterValue("English");
    }
  }


  const openFilterPopUp = () => {
    return (
      <Grid>
        <Grid className={`${classes.modalBody} ${classes.popUpMainDiv}`}>
          <Grid className={`${classes.modalTitle} ${classes.textCenter} ${classes.w50}`}>

            <RACSelect
              // classes={{ nativeSelect: classes.dynamicCssForSelect }}
              inputLabel="Is Price Modified ?"
              options={PriceModifiedDD}
              name="priceModify"
              defaultValue={
                filterValue !== undefined &&
                  filterValue !== null &&
                  filterValue !== ""
                  ? filterValue
                  : "0"
              }
              loading={
                PriceModifiedDD.length == 0 && !setdropDownLoaded ? true : false
              }
              // onChange={(e: any) => {
              //   e.target.name = `priceModify`;

              //   setfilterValue(e.target.value);
              // }}
              onChange={(e) => priceModifyOnChange(e)}
              {...(PriceModifiedDD.length == 0 &&
                dropDownLoaded && {
                errorMessage: API_ERROR_MESSAGE,
              })}
            />
          </Grid>
        </Grid>
        <Grid className={`${classes.racCol12} ${classes.textCenter} ${classes.pt16}`}>
          <RACButton
            className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => { setfilterValue("0");clearFilter(); }}
            // setPackageValues(packageValues)
            disabled={filterValue=='0'? true : false}
          >
            Clear
          </RACButton>
          {/* <RACButton
            className={`${classes.mx1} ${classes.pb1} ${classes.pt1}`}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setFilter(false)}
          >
            Cancel
          </RACButton> */}
          <RACButton
            className={`${classes.ml1} ${classes.pb1} ${classes.pt1}`}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleOnSelect()}
            disabled={filterValue=='0'? true : false}
          >
            Apply Filter
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const clearFilter=()=>{
    setorderBy('primaryItem');
    setGridColumn(initialColumns);
    setPackageValues(packageValuesTemp);
  
  setFilter(false);
}

  const handleOnSelect = () => {
    setpackageIds({ ...packageIds, toPrint: [], notToPrint: [], toBreak: [] });

    const value = PriceModifiedDD.filter((v: any) => v.value == filterValue);
    const label = value[0].label != undefined ? value[0].label : null;
    const filtered = packageValuesTemp.filter(
      (v: any) => v.isPriceModified == label
    );
    setorderBy('primaryItem');
    setGridColumn(initialColumns);
    setPackageValues(filtered);
    if (filterValue == "0") {
      setorderBy('primaryItem');
      setGridColumn(initialColumns);
      setPackageValues(packageValuesTemp);
    }
    setFilter(false);
  };


  const showPopUp = (value: any) => {
    if (value.confirmPrint === true) {
      return (
        <Grid>
          <Typography
            style={{ textAlign: "justify" }}
            className={` ${classes.ml3} ${classes.mr3} ${classes.mb3} `}
            variant="h5"
          >
            To continue please ensure that the Price Tag forms are in the paper
            tray of <br />the printer and select the language(s) in which to print the
            Price tags below:{' '}
          </Typography>
          <Grid className={classes.componentCenter}>
            <Typography
              variant="caption"
              className={`${form.formLabel} ${classes.pr4} `}
            >
              Language
            </Typography>
            <Grid className={classes.w25}>
              <RACSelect
                classes={{ nativeSelect: classes.selectBox }}
                options={languageDD}
                name="languageModify"
                defaultValue={
                  ddfilterValue !== null &&
                    ddfilterValue !== ""
                    ? ddfilterValue
                    : "English"

                }
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  e.target.value == "English" ? setDDfilterValue("English") : setDDfilterValue(e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid className={`${classes.componentCenter} ${classes.pt4}`}>
            <RACButton
              onClick={() => setEnablePopup({ ...enablePopup, openPopUp: false, confirmPrint: false, breakPackage: false,failedBreakPackage:false })}
              variant="outlined"
              color="secondary"
              className={` ${classes.textBlack} ${classes.mr3}`}
            >
              Cancel
            </RACButton>
            <RACButton
              style={{ marginTop: '-1px' }}
              className={`${classes.px7}`}
              // onClick={() => {printHTML(document.getElementById('PackagePriceTagList')); getPackagePrice();}}
              variant="contained"
              color="primary"
              loading={btnLoader}
              disabled={btnLoader==true?true:false}
              onClick={() => onPrintPackagePriceTag()}

            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      );
    }
    else if (value.breakPackage === true) {
      return (
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            <Typography className={classes.RACPOPMsg} style={{ marginTop: "-10px" }} >
              Do you want to break the package ?
            </Typography>
          </Grid>
          <Grid container className={`${classes.justifyContentCenter} ${classes.mb3}`}>
            <RACButton
              className={`${classes.mx1} ${classes.popUpBtnWidth} ${classes.py2}`}
              // size="small"
              variant="outlined"
              color="primary"
              onClick={() => setEnablePopup({ ...enablePopup, openPopUp: false, confirmPrint: false, breakPackage: false,failedBreakPackage:false })}
            >
              No
            </RACButton>
            <RACButton
              className={`${classes.mx1} ${classes.popUpBtnWidth} ${classes.py2}`}
              // size="small"
              variant="contained"
              color="primary"
              onClick={() => onBreakPackage()}
              disabled={btnLoader==true?true:false}
              loading={btnLoader}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      );
    }
    else if (value.failedBreakPackage === true) {
      return (
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            <Typography className={classes.RACPOPMsg} style={{ marginTop: "-10px" }} >
              Sorry!! Unable to break package at the moment
            </Typography>
          </Grid>
          <Grid container className={`${classes.justifyContentCenter} ${classes.mb3}`}>
            <RACButton
              className={`${classes.mx1} ${classes.popUpBtnWidth} ${classes.py2}`}
              // size="small"
              variant="outlined"
              color="primary"
              onClick={() => setEnablePopup({ ...enablePopup, openPopUp: false, confirmPrint: false, breakPackage: false,failedBreakPackage:false })}
            >
              OK
            </RACButton>
            {/* <RACButton
              className={`${classes.mx1} ${classes.popUpBtnWidth} ${classes.py2}`}
              // size="small"
              variant="contained"
              color="primary"
              onClick={() => onBreakPackage()}
              disabled={btnLoader==true?true:false}
              loading={btnLoader}
            >
              Yes
            </RACButton> */}
          </Grid>
        </Grid>
      );
    }
  }

  const redirectToInvenInfo = (e: any) => {
    console.log('inside redirection', e);
    const inventoryNumber = e.toString();
    History.push({ pathname: `/inventory/${inventoryNumber}/inventoryinformation` });

  }


  const onPrintPackagePriceTag = async () => {

    setbtnLoader(true);
    console.log('getPackagePriceTag req', packageIds.toPrint)
    const respData = await getPackagePriceTag({ packagePriceIds: packageIds.toPrint });
    console.log('getPackagePriceTag', respData)
    setbtnLoader(false);

    if (validateData(respData)) {
      if (validateData(respData.data)) {
        setPackagePriceTags(respData.data);
        setEnablePopup({ ...enablePopup, openPopUp: false, confirmPrint: false, breakPackage: false,failedBreakPackage:false });

        printPackagePriceTag();

        setpackageIds({ ...packageIds, toPrint: [], notToPrint: [], toBreak: [] });
        getPackagePrice();
      }
      else {
        seterrMsg({ ...errMsg, noRecord: true })
      }
    }
    else {
      seterrMsg({ ...errMsg, wentWrong: true })
    }
  }


  const printPackagePriceTag = () => {

    const packagePriceTagDoc = document?.getElementById('PackagePriceTagList')?.innerHTML;
    if (packagePriceTagDoc !== undefined && packagePriceTagDoc !== null) {
      const a: any = window.open(
        "",
        "",
        ''
      );
      a.document.write("<html>");
      a.document.write(packagePriceTagDoc);
      a.document.write("</html>");
      a.document.close();
      a.print();
    }

  }


  const onBreakPackage = async () => {

    setbtnLoader(true);
    const break_Res = await postbreakPackage({ packagePriceIds: packageIds.toBreak });
    setbtnLoader(false);

    if (validateData(break_Res) && validateData(break_Res.data) && break_Res.data.packagePriceIds) {

      setEnablePopup({ ...enablePopup, openPopUp: false, confirmPrint: false, breakPackage: false,failedBreakPackage:false });
      setpackageIds({ ...packageIds, toPrint: [], notToPrint: [], toBreak: [] });
      getPackagePrice();

    }
    else {
      // seterrMsg({ ...errMsg, wentWrong: true })
      setEnablePopup({ ...enablePopup, openPopUp: true, confirmPrint: false, breakPackage: false,failedBreakPackage:true });
      
    }
  }
  const priceModifyOnChange = (e: any) => {
    e.target.name = `priceModify`;
    if (e.target.value == "0") {
      setapplyFBtn(true)
    }
    else {
      setapplyFBtn(false)
    }
    setfilterValue(e.target.value);
  }
  const onEditPackage = (e: any) => {
    const inventoryId = e?.inventoryIds.map((value: any) => {
      return parseInt(value)
    })
    setpackageDetailContext({ ...packageDetailContext, packageId: e.packagePriceIds, packageName: e.packageName, primaryItem: inventoryId });

    History.push({
      pathname: `/inventory/packageitemsearch`
    })
  }

  return (
    <React.Fragment>
      <Grid>
        <Grid container>
          {/* item info starts*/}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid item md={12} className={classes.componentSpaceBetween}>
              <Grid className={classes.m2}>
                <Typography variant="h5">Package Price Tags</Typography>
              </Grid>
              <Grid style={{
                float: 'right',
                marginRight: '-7px',
                padding: '13px'
              }}>
                <RACButton
                  disabled={filter}
                  onClick={() => setFilter(true)}
                  variant="contained"
                  color="primary"
                >
                  <Grid>
                    <Filter />
                  </Grid>
                  Filter
                </RACButton>
              </Grid>
            </Grid>
            <Grid className={`${classes.mbFooterSpacing}`}> {/* changed */}
              {gridLoader === true ? (
                <Grid className={classes.racLoaderPage}>
                  <CircularProgress />
                </Grid>
              ) :
                errMsg.wentWrong === true ? (
                  <Card className={`${classes.mx3} ${classes.racCard}`} style={{ minHeight: "520px" }}>
                    <CardContent>
                      <Grid className={`${classes.textCenter} ${classes.mt5}`} style={{ padding: "10%" }}>
                        <WrongIcon />
                        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                          Something Went Wrong
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>) :
                  errMsg.noRecord === true ? (
                    <Card className={`${classes.mx3} ${classes.racCard}`} style={{ minHeight: "520px" }}>
                      <CardContent>
                        <Grid className={`${classes.textCenter} ${classes.mt5}`} style={{ padding: "10%" }}>
                          {/* <WrongIcon /> */}
                          <NoRecord />
                          <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                            No Records Found
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) 
                  :
                    (
                      <Card className={`${classes.m2} ${classes.racCard}`}>
                        <CardContent>
                          <DynamicGridComponent
                            gridtype="withCheckBox"
                            items={packageValues}
                            columns={Columns}
                            statusCode={false}
                            id="PackagePriceTag"
                            orderBy={orderBy}
                            order={order}
                            gridColumn={gridColumn}
                            gridValues={packageValues}
                            gridActionList="edit"
                            setGridValues={(e: any) => setGridValues(e)}
                            setGridColumns={(e: any) => setGridColumns(e)}
                            handleAnkerType={(e: any) => redirectToInvenInfo(e)}
                            handleActionType={(e: any) => onEditPackage(e)}
                            handleCheckedAll={(e: any, dataValues: any) => handleCheckedAll(e, dataValues)}
                            handleChecked={(e: any, index: any, value: any) =>
                              handleChecked(e, index, value)
                            }
                            isChecked={select}
                            throwStyle={classes}
                            setOrder={(e: any) => setorder(e)}
                            setOrderBy={(e: any) => setorderBy(e)}
                            isAction={true}
                          ></DynamicGridComponent>
                        </CardContent>
                      </Card>
                    )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      <Grid container style={{ zIndex: '0' }} className={classes.fixedFooter}>
        <Grid className={classes.floatLeft}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.ms2}
            onClick={() => { History.push({ pathname: `/dashboard` }) }}
          >
            Cancel
          </RACButton>
        </Grid>
        <Grid className={classes.floatRight}>
          <RACButton
            disabled={(packageIds.toPrint.length > 0 && packageIds.notToPrint.length == 0) ? false : true}
            // disabled={disableButtons.printBtn}
            className={classes.mr2}
            variant="contained"
            color="primary"
            onClick={() => setsecondLevelPin(true)}
          >
            Print
          </RACButton>

          <RACButton
            disabled={packageIds.toBreak.length > 0 ? false : true}
            onClick={() => setEnablePopup({ ...enablePopup, openPopUp: true, breakPackage: true })}
            variant="contained"
            className={classes.mr2}
            color="primary"
          >
            Break Package
          </RACButton>

          <RACButton
            variant="contained"
            color="primary"
            onClick={() => { History.push('/inventory/packageItemSearch') }}
          >
            New Package
          </RACButton>
        </Grid>
      </Grid>

      {/* languageselecting and breakpackage popUp */}
      <RACModalCard
        isOpen={enablePopup.openPopUp}
        title=""
        maxWidth="sm"
        borderRadius='20px'
        closeIcon={enablePopup.failedBreakPackage ? false : true}
        className={enablePopup.confirmPrint === true ? `${classes.SelectLanguageModalWidth}` : `${classes.breakPackageModalWidth}`}
        onClose={() => { setEnablePopup({ ...enablePopup, openPopUp: false, confirmPrint: false, breakPackage: false }) }}
        children={showPopUp(enablePopup)}
      ></RACModalCard>


      <RACModalCard
        isOpen={false}
        maxWidth={undefined}
        title="Printing single Item Price Tag "
        closeIcon={true}
      >
      </RACModalCard>

      {/* filter popUp */}
      <RACModalCard
        isOpen={filter}
        title="Filter"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {setFilter(false);setfilterValue("0")}}
        className={`${classes.filterPositionAlign} ${classes.borderRadiusPosition} ${classes.priceTagPrintModalWidth}`}
        borderRadius={'16px !important'}
        children={openFilterPopUp()}
      ></RACModalCard>


      {/* secondLevelAuthentication */}
      {secondLevelPin == true ? (
        <SecondFactor
          setTwoFactorCancelClick={() => setsecondLevelPin(false)}
          setTwoFactorCompleted={(e: any) => secondLevelCompleted(e)}
          moduleName={"Print Updated Price Tag"}
          CurrentRole={currentRole}
          employeeID={employeeId}
          getprofileaccessResponse={profileDetails}
        ></SecondFactor>

      ) : null}

      <div id="PackagePriceTagList" style={{ display: 'none' }}>
        <PrintPackagePrice
          packageIdsToPrint={packageIds.toPrint}
          detailsToPrint={packagePriceTags}
          language={"English"}
        />
      </div>

    </React.Fragment>
  );
};
export default PackagePriceTags;

