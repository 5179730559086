/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
export const formStyles = () => {
    const useClasses = makeStyles((theme) => ({
        formLabel: {
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
            letterSpacing: 'normal !important',
            color: '#000000',
        },

        formValue: {
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
            letterSpacing: 'normal !important',
            color: '#4a5174',
        },
        formTextArea: {
            width: '100%',
            border: '0.0625rem solid #c4c4c4',
            borderRadius: '0.3125rem',
            resize: 'none',
            outline: 'none',
            minHeight: '80px',
            '&:focus': {
                boxShadow: '0 0 0 0.25rem rgb(0 123 255 / 25%)',
                borderColor: '#80bdff',
            },
        },
        customFormTextBox: {
            width: '100px',
            display: 'inline-block',
            marginLeft: '5px',
        },
        customFormTextBoxPin: {
            width: '20px',
            display: 'inline-block',
            // marginLeft:'5px',
            '& input': {
                height: '30px',
            }
        },
        labelWidth: {
            width: '100px',
        },
        //added by krishnaja for textArea
        textarea:{
            minHeight:'120px',
            marginTop:'6px',
            width:'95%'
        },
        FormTextArea: {
            width: '91%',
            border: '0.0625rem solid #c4c4c4',
            borderRadius: '0.375rem ',
            resize: 'none',
            outline: 'none',
            minHeight: '120px',
            '&:focus': {
                boxShadow: '0 0 0 0.25rem rgb(0 123 255 / 25%)',
                borderColor: '#80bdff',
            },
        },


    }));
    const classes = useClasses();
    return classes;
};