/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/jsx-key */
/* eslint-disable no-console */

import React from 'react';
import { formatter, validateData } from '../../../constants/CommonMethods';

export default function PrintPackagePrice(props: any) {
  const packageIds = validateData(props.packageIdsToPrint)
    ? props.packageIdsToPrint
    : [];
  console.log('packageIds', packageIds);
  const packageDetails = validateData(props.detailsToPrint)
    ? props.detailsToPrint
    : [];
  console.log('packageDetails', packageDetails);

  const printPackagePrice = () => {
    return packageIds.map((value: any) => {
      console.log('value', value);

      return  packageDetails.map((data:any,index:any)=>{
        console.log("data",data);
        
        if(value==(data.packagePriceId).toString())
        {
          console.log("vvv");

          return(
            <div style={{ margin: '0px', fontFamily: 'Arial' }}>
              <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>
    
                  <div style={{ width: '100%', textAlign: 'center', marginBottom: '2%' }}>
                    <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', marginLeft: '37px', display: 'inline-block' }}>
                      {validateData(data)
                        && validateData(data.regularWeeklyRate)?
                        data.regularWeeklyRate : ' - '}
                    </h1>
                  </div>
    
                  <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold' }}>
                  {data.inventoryDetails.map((details:any)=>{
                      return(
                        
                        <span style={{ fontSize: '20px' }}>{validateData(details)&& validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br/></span>
                        )
                    })}

                    </div>
    
    
                  <div style={{ float: 'left', width: '33.33%' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{border: '1px solid black',padding:'6%',margin:'0% 20%'}}>
    
                        <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Regular</span><br/>
                        <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Weekly Price</span>

                        <div>
                          <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                                && validateData(data.regularWeeklyRate)?
                                formatter.format(data.regularWeeklyRate) : ' - '
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                        {validateData(data)
                                && validateData(data.monthlyRate)?
                                formatter.format(data.monthlyRate) : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Monthly Price</span>
                        </div>
                      </div>
    
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                        {validateData(data)
                                && validateData(data.totalCost)?
                                formatter.format(data.totalCost) : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Total Cost</span>
                        </div>
                      </div>
                    </div>
                  </div>
    
    
                  <div style={{ float: 'left', width: '33.33%' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
    
                        <div style={{ paddingTop: '15px' }}>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.weeklyRate)?
                                formatter.format(data.weeklyRate) : ' - '
                            }
                            </span>
    
                        </div>
                        <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                      </div>
                    </div>
    
                  </div>
    
    
                  <div style={{ float: 'left', width: '33.33%' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.weeklyTerm)?
                                data.weeklyTerm : ' - '
                            }
                        </span>
                        <div style={{ paddingTop: '15px' }}>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br/>
                          <span style={{ fontSize: 'larger', fontWeight: '100px'}}>Payments</span>

                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.monthlyTerm)?
                                data.monthlyTerm : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br/>
                          <span style={{ fontSize: 'larger', fontWeight: '100px'}}>Payments</span>

                        </div>
                      </div>
    
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.cashPrice)?
                                formatter.format(data.cashPrice) : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Cash Price</span>
                        </div>
                      </div>
                    </div>
                  </div>
    
    
                  <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', float: 'left' }}>
                  <div style={{ paddingLeft: '54px' }}>
                    {data.inventoryDetails.map((details:any)=>{
                      return(
                        <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          {validateData(details)&&validateData(details.inventoryNumber)?details.inventoryNumber:' - '}
                        </span>
                      )
                    })}
                    </div>
                  </div>
                  <div style={{marginBottom:"600px"}}></div>
    
    
                </div>
    
              </div>
    
            </div>
          )
        }
      })
    });
  };

  return <React.Fragment>{printPackagePrice()}</React.Fragment>;
}
