/* eslint-disable prettier/prettier */
import React from 'react';

import { Card, CardContent, Grid, Typography } from '@rentacenter/racstrap';
import { globalStyles } from '../../componentstyles/globalstyles';
import { formatter, validateData } from '../../../constants/CommonMethods';

export default function PriceTagCard(props: any) {
  const classes = globalStyles();
  let Header: string;

  const priceTagDetails = validateData(props)
    ? validateData(props.priceTagDetails)
      ? validateData(props.priceTagDetails[0])
        ? props.priceTagDetails[0]
        : {}
      : []
    : [];

  const priceTagHeader = validateData(priceTagDetails)
    ? priceTagDetails.conditionRefCode
    : '';

  const bindHeader = () => {
    switch (priceTagHeader) {
      case 'NEW':
        Header = 'New Price Tag';
        break;
      case 'ATO':
        Header = 'Auto Price Tag';
        break;
      case 'EXCP':
        Header = 'Edit Price Tag';
        break;
      case 'CURRENT':
        Header = 'Current Price Tag';
        break;
    }
    return Header;
  };

  return (
    <Grid>
      <Typography variant="h5">{bindHeader()}</Typography>
      <Card className={`${classes.mt1} ${classes.racCard}`}>
        <CardContent className={`${classes.py0} ${classes.px4}`}>
        <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6} ${classes.textGrey}`}>{validateData(priceTagDetails.depreciationPercentage)===true ? priceTagDetails.depreciationPercentage+ "%" :'-'}</Typography>
                        <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6} ${classes.textGrey}`}>{validateData(priceTagDetails.grossProfitPerPayment)===true ?formatter.format(priceTagDetails.grossProfitPerPayment):'-'}</Typography>
                        <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6} ${classes.textGrey}`}>{validateData(priceTagDetails.turns)===true ?priceTagDetails.turns:'-'}</Typography>
                        <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6} ${classes.textGrey}`}>{validateData(priceTagDetails.rate)===true ?formatter.format(priceTagDetails.rate):'-'}</Typography>
                        <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6} ${classes.textGrey}`}>{validateData(priceTagDetails.term)===true ?priceTagDetails.term:'-'}</Typography>
                        <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6} ${classes.textGrey}`}>{validateData(priceTagDetails.cashPrice)===true ?formatter.format(priceTagDetails.cashPrice):'-'}</Typography>
                        <Typography className={`${classes.py3} ${classes.pl2} ${classes.fs6} ${classes.textGrey}`}>{validateData(priceTagDetails.totalCost)===true ?formatter.format(priceTagDetails.totalCost):'-'}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
