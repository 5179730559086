/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { useParams } from 'react-router';

import SearchFilter from './CriteriaSearch';
import { getInventorySearch, PrintFaxSheet, getSalesInfo } from '../../api/user';
import DynamicGridComponent from './grid';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg'
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  RACModalCard,
  RACButton,
} from '@rentacenter/racstrap';
import { InventoryContext } from '../../context/InventoryInfoContext';
import { InventoryGlobalStyles } from './Styles/InventoryGlobalStyles';
import { searchResultStyles } from './Styles/searchResultStyles';
import { useHistory } from 'react-router';
import moment from 'moment';

export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;
  subColumns?: columns[];
  columnColor?: string;
}
const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

// export const Usercontext = createContext({});
// interface Params {
//   customerId: string;
// }

const SearchResult = () => {
  const classes = InventoryGlobalStyles();
  const agrComponent = searchResultStyles();
  const history = useHistory();
  const { headerValue } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  // const searchCriteriaClassName = searchCriteriaStyles();
  // const { customerId } = useParams<Params>();
  const [enablehyperlink, setenablehyperlink] = useState(false);
  const search = useLocation().search;
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');
  const [tabledata, settabledata] = useState<any>();
  const [addAllPopUp, setaddAllPopUp] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateitemadded, setupdateitemadded] = React.useState(false);
  const [request, setRequest] = useState<any>()
  const [controlRecord, setcontrolRecord] = React.useState<any>(false);
  const [webLeadId, setwebleadbyId] = useState<any>()
  const [Gridloader, setGridloader]: any = React.useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [masterLoader, setmasterLoader]: any = React.useState(false);
  const [loaderController, setloaderController]: any = React.useState(false);
  const [internalServerController, setinternalServerController]: any =
    React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [controlViewMode, setcontrolViewMode] = useState(false);
  const [storeDetails, setstoreDetails] = useState<any>();
  const storeTimeing: { days: any, Time: any }[] = [];
  const popUpObj =
  {
    storeNum: "",
    storeAddress: "",
    phoneNumber: "",
    Timing: [{ days: "", timeShift: "" }],
    Latitude: "",
    longtitude: ""
  }
  const [popUpData, setpopUpData] = useState(popUpObj);
  const [errorMsg, setErrorMsg] = useState<boolean>(false)

  const [lastStore,setLastStore]=useState<string>('');
  const [radiusStoreDetails,setradiusStoreDetails]=useState<any>();

  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme';
  const altersearch = updateitemadded
    ? agrComponent.collapsedWigid
    : agrComponent.expandWigid;


  useEffect(() => {

    if (salesLeadId != undefined) {
      saleFunction()
    }

  }, [salesLeadId])

  const saleFunction = async () => {
    const salesData = await getSalesInfo(salesLeadId);
    console.log('salesData', salesData);
    setwebleadbyId(salesData)
    const locationNumber: any = salesData.data.inventories[0].reservedStoreNumber
    const inventoryNumber: any =
      salesData?.data?.inventories[0]?.inventoryNumber
        ? salesData.data.inventories[0].inventoryNumber
        : '';
    const modelNumber: any =
      salesData?.data?.inventories[0]?.modelNumber
        ? salesData.data.inventories[0].modelNumber
        : '';
    const searchValue = {
      itemNo: inventoryNumber,
      serialNo: '',
      bracket: '',
      brand: '',
      model: modelNumber,
      statusId: 1,
      subStatusId: [2],
      conditionId: null,
      departments: '',
      subDepartments: '',
      packageTypeval: '',
      locationNumber: locationNumber,
      currentStoreNumber: '',
    };
    searchClick(searchValue)

  }
  const handlerAnchorType = (itemid: any, description: any, status: any, storeNo: any) => {
    setheaderContext({
      ...headerContext,
      inventoryNumber: itemid,
      description: description,
      status: status,
      storeNumber: storeNo
    });
    if (salesLeadId) {
      history.push({
        // eslint-disable-next-line no-useless-escape
        pathname: `/inventory/${itemid}/inventoryinformation?salesLeadId=Y`,
      })
    }
    else {
      history.push({
        // eslint-disable-next-line no-useless-escape
        pathname: `/inventory/${itemid}/inventoryinformation`,
      })
    }

  }

  const searchClick = async (value: any, neighborstore?: any) => {
    try {
      setloaderController(true);
      setGridloader(true);

      const req: any = {
        storeNumbers: value.locationNumber == 'Radius All' ? neighborstore : [value.locationNumber],
        itemNumber: value.itemNo == '' ? null : [value.itemNo],
        serialNumber: value.serialNo.trim(),
        bracket: value.bracket.trim(),
        brand: value.brand.trim(),
        modelNumber: value.model.trim(),
        poNumber: value.poNo == "" ? null : parseInt(value.poNo),
        statusId: value.statusId == 0 ? null : parseInt(value.statusId),
        subStatusId: value.locationTypeVal === "Store" ? null : value.subStatusId,
        conditionId: value.conditionId == 0 ? null : parseInt(value.conditionId),
        department: value.department,
        subDepartment: value.subDepartment,
        setNumber: 0,

      };
      console.log('req', req);

      setRequest(req)

      const response: any = await getInventorySearch(req);
      settabledata([])

      // eslint-disable-next-line no-console
      console.log('reached below data obtained', response);
      // eslint-disable-next-line no-console
      console.log('reached response.data obtained', response.data);
      // eslint-disable-next-line no-console
      console.log('reached below data.response obtained', response.data.response);

      if (response.status == 400 || response.status == 500) {
        setinternalServerController(true);
        setloaderController(false);
      } else if (response.status == 200 && response.data.response.length > 0) {
        //setsearchResultContext(response.data.response);
        setloaderController(false);
        // eslint-disable-next-line no-console
        console.log('Response from api', response);

        const ApiResult: any = response.data.response;
        // eslint-disable-next-line no-console
        console.log('response', ApiResult);
        // eslint-disable-next-line no-console
        console.log('response-array', ApiResult[0]);

        settabledata(ApiResult);
      } else if (response.data.response.length <= 0) {
        setcontrolRecord(true);
        setloaderController(false);
      }
    }
    catch {
      setinternalServerController(true);
      setloaderController(false);
    }
  };

  const openStoreInfo=async(e:any)=>{
    if(lastStore!== e) {
      setaddAllPopUp(true);
      setmasterLoader(true);
      setLastStore(e);
      const responsePrintFaxSheet: any = await PrintFaxSheet(e);
      console.log('PrintFaxSheet', responsePrintFaxSheet);
      setmasterLoader(false);
      if (responsePrintFaxSheet.status == 200) {
        setradiusStoreDetails(responsePrintFaxSheet.data.response);
        bindStoreInfo(responsePrintFaxSheet.data.response);
      } 
    }
    else{
      bindStoreInfo(radiusStoreDetails);
      setaddAllPopUp(true);
    }
  }

  const bindStoreInfo = async (storeData: any) => {
    // setaddAllPopUp(true);
    // setmasterLoader(true);


    // const responsePrintFaxSheet: any = await PrintFaxSheet(e);
    // // eslint-disable-next-line no-console
    // console.log('PrintFaxSheet', responsePrintFaxSheet);
    // setmasterLoader(false);
    // // eslint-disable-next-line sonarjs/no-collapsible-if
    // if (responsePrintFaxSheet.status == 200) {
    //   const response = responsePrintFaxSheet.data.response;
      if (storeData !== undefined && storeData !== null) {
        const weekDays: any = {
          'SUN': 1, 'MON': 2, 'TUE': 3, 'WED': 4, 'THU': 5, 'FRI': 6, 'SAT': 7
        };
        console.log('particularres', storeData);
        //const particularStore: any = response.filter((q: { storeNumber: any; }) => q.storeNumber == sessionStorage.getItem('storeNumber'))
        const particularStore: any = storeData
        console.log('particularStore', particularStore);

        for (let i = 0; i < particularStore.length; i++) {
          console.log('particularStore[i]', particularStore[i]);
          particularStore[i].calendar.sort((a: any, b: any) => {
            return weekDays[a.dayOfWeek] - weekDays[b.dayOfWeek];
          });
        }
        console.log('particularStore[0]', particularStore[0]);
        particularStore[0].calendar.map((Days: { startTime: any; endTime: any; dayOfWeek: any; storeHoliday: number; racHoliday: any }) => {
          if (Days.storeHoliday !== 1 && Days.racHoliday !== 1) {
            const startTime = Days.startTime.replace('Z', '')
            const endTime = Days.endTime.replace('Z', '')
            const temp: any = {
              days: Days.dayOfWeek,
              timeShift: moment(new Date(startTime)).format("hh:mm A") + " - " + moment(new Date(endTime)).format("hh:mm A")
            }
            console.log('temp', temp);
            storeTimeing.push(temp)
          }


          else if (Days.storeHoliday !== 0 || Days.racHoliday !== 0) {
            const temp: any = {
              days: Days.dayOfWeek,
              timeShift: "Closed"
            }
            storeTimeing.push(temp)
          }
        })

        const popUpObj: any = {
          // storeNum: currentStore,
          // storeAddress: particularStore[0].addressLine1 + ", " + particularStore[0].city + ", " + (particularStore[0].stateName).toUpperCase() + ", " + particularStore[0].zip,
          // phoneNumber: formatPhoneNumber(particularStore[0].workPhoneNumber),
          // closedTime: closeStoreTiming,
          Timing: storeTimeing,
        }

        // set state object popUpObj in state variable popUpData
        setpopUpData(popUpObj)
        storeData.map((value: any) => {
          setstoreDetails(value);
        });

      // }
    }
  };
  const OpenTime = () => {
    return popUpData.Timing.map((obj, index) => {
      return (
        <React.Fragment key={index} >
          <Grid >
            <Grid style={{ width: '20%', float: 'left', color: '#6C86A5' }}>
              <Typography className={`${classes.p1} ${classes.semiBold} ${classes.font15} `}>
                {obj.days}
              </Typography>
            </Grid>
            <Grid style={{ width: '75%', float: 'left', color: '#6C86A5' }}>
              <Typography className={`${classes.p1} ${classes.semiBold} ${classes.font15} `}>
                {obj.timeShift}
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>

      )
    })
  }

  const mapClick = () => {

    if (storeDetails == undefined || storeDetails.latitude == undefined || storeDetails.longitude == undefined) {
      setErrorMsg(true)
      setaddAllPopUp(false)
    }
    else {
      setErrorMsg(false)
      window.open(
        `https://maps.google.com?q=${storeDetails !== undefined &&
          storeDetails !== null &&
          storeDetails.latitude !== ''
          ? storeDetails.latitude
          : ''},${storeDetails !== undefined &&
            storeDetails !== null &&
            storeDetails.longitude !== '' && storeDetails?.longitude
            ? storeDetails.longitude
            : ''
        }`
      )
    }

  }
  const noRecordPopup = () => {
    return (
      <Grid>
        <Grid>
          <Grid className={classes.textCenter}>
            <AlertIcon />
            <Typography >
              {' '}
              Something Went Wrong.
            </Typography>

          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => setErrorMsg(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const storeDetailsModal = () => {
    return (
      <React.Fragment>
        {masterLoader ?
          <Grid className={classes.GridLoader}>
            <CircularProgress />
          </Grid>
          :
          <React.Fragment>
            <Grid style={{ marginLeft: '2%', marginRight: '2%' }}>

              <Grid
                className={`${classes.floatLeft} ${classes.mb3} ${classes.w100}`}
              >
                <Typography
                  style={{ marginTop: '-14px' }}
                  className={`${classes.bold} ${classes.font15}  ${classes.textBlack}  ${classes.me2} ${classes.floatLeft}`}
                >
                  Store # :
                </Typography>
                <Typography
                  style={{ color: '#6C86A5', marginTop: '-14px' }}
                  className={`${classes.semiBold} ${classes.font15}`}
                >
                  {storeDetails !== undefined &&
                    storeDetails !== null &&
                    storeDetails.storeNumber !== ''
                    ? storeDetails.storeNumber
                    : ''}
                </Typography>
              </Grid>
              <Grid
                className={`${classes.floatLeft} ${classes.mb3} ${classes.w100}`}
              >
                <Grid className={`${classes.floatLeft} ${classes.pr1}`}>
                  <LocationIcon />
                </Grid>
                <Grid
                  style={{ color: '#6C86A5' }}
                  className={`${classes.semiBold} ${classes.font15} ${classes.floatLeft} ${classes.w95}`}
                >
                  <Typography style={{ paddingRight: '0px' }}>
                    {storeDetails !== undefined &&
                      storeDetails !== null &&
                      storeDetails.addressLine1 !== ''
                      ? storeDetails.addressLine1
                      : ''},&nbsp;
                    {storeDetails !== undefined &&
                      storeDetails !== null &&
                      storeDetails.stateName !== ''
                      ? storeDetails.stateName
                      : ''}
                    ,&nbsp;
                    {storeDetails !== undefined &&
                      storeDetails !== null &&
                      storeDetails.city !== ''
                      ? storeDetails.city
                      : ''},&nbsp;
                    {storeDetails !== undefined &&
                      storeDetails !== null &&
                      storeDetails.zip !== ''
                      ? storeDetails.zip
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className={`${classes.floatLeft} ${classes.mb3} ${classes.w100}`}
              >
                <Grid className={`${classes.floatLeft} ${classes.pr1}`}>
                  <PhoneIcon />
                </Grid>
                <Grid
                  style={{ color: '#6C86A5', marginTop: '1px', marginLeft: '2px', lineHeight: 1 }}
                  className={`${classes.semiBold} ${classes.font14} ${classes.px0} ${classes.floatLeft}`}
                >

                  {storeDetails !== undefined &&
                    storeDetails !== null &&
                    storeDetails.workPhoneNumber !== ''
                    ? storeDetails.workPhoneNumber.replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3')
                    : ''}
                </Grid>
              </Grid>
              <Grid
                style={{ borderRadius: '10px', backgroundColor: '#F0F0F0' }}
                className={`${classes.floatLeft} ${classes.mb4} ${classes.w100} ${classes.pb3} ${classes.pl4}`}
              >
                <p
                  className={`${classes.semiBold} ${classes.font15}  ${classes.textBlack} ${classes.mb1} `}
                >
                  Store Hours :
                </p>

                {OpenTime()}
              </Grid>
            </Grid>

            <Grid className={`${classes.floatRight}`}>
              <RACButton
                variant="outlined"
                color="secondary"
                className={`${classes.mx1} `}
                size="large"
                onClick={() => setaddAllPopUp(false)}
              >
                Cancel
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                className={classes.mx1}
                size="large"
                onClick={() => mapClick()}
              >
                {/* Open in Google Map */}
                Store Locator
              </RACButton>
            </Grid>
          </React.Fragment>}
      </React.Fragment>
    );
  };
  const gridCallMethod = () => {
    // eslint-disable-next-line no-console
    console.log('Came inside Grid Method');

    if (tabledata !== undefined && tabledata.length > 0) {
      // eslint-disable-next-line no-console
      console.log('Inside table bind', tabledata);
      return (
        <div>
          <Typography className={`${classes.title} ${classes.mt3}`}>
            Search Results
          </Typography>
          <Card className={`${classes.card} ${classes.mb4} ${classes.p3}`}>
            <CardContent className={classes.p0}>
              <DynamicGridComponent
                gridtype="Normal"
                id="packagePriceItems"
                items={tabledata}
                Request={request}
                handleAnkerType={handlerAnchorType}
                ColumnLink={enablehyperlink}
                orderBy="loanerAgreement"
                handleActionType={(e) => openStoreInfo(e)}
                order={sortDirection.ASC}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );
    } else if (internalServerController) {
      return (
        <div>
          <Typography className={`${classes.title} ${classes.mt3}`}>
            Search Results
          </Typography>
          <div className={`${classes.textCenter} ${classes.mt5}`}>
            <AlertIcon />
            <Typography className={`${classes.subTitle} ${classes.mb3}`}>
              Something Went Wrong
            </Typography>
          </div>
        </div>
      );
    } else if (controlRecord) {
      return (
        <div>
          <Typography className={`${classes.title} ${classes.mt3}`}>
            Search Results
          </Typography>
          <div className={`${classes.textCenter} ${classes.mt5}`}>
            <NoRecord />
            <Typography className={`${classes.subTitle} ${classes.mb3}`}>
              Search criteria did not return any results
            </Typography>
          </div>
        </div>
      );
    }
  };

  const enableHyperLink = (value: any) => {
    if (value == 'Radius') {
      setenablehyperlink(true);
    } else if (value == 'Store') {
      setenablehyperlink(false);
    }
  };

  return (
    <div className={dynamicViewClass}>
      {/* {masterLoader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null} */}
      <Grid container spacing={2}>
        <Grid item className={altersearch}>
          <SearchFilter
            searchClick={searchClick}
            IsPackage={true}
            enableHyperLink={enableHyperLink}
            throwStyle={classes}
            webLeadId={webLeadId}
          />
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.mbFooterSpacing}>
            {loaderController === true && Gridloader === true ? (
              <>
                <Typography className={`${classes.title} ${classes.mt3}`}>
                  Search Results
                </Typography>
                <Grid className={classes.GridLoader}>
                  <CircularProgress />
                </Grid>
              </>
            ) : (
              gridCallMethod()
            )}
          </Grid>

          <RACModalCard
            isOpen={addAllPopUp}
            closeIcon={true}
            title="Store Info"
            titleVariant="h4"
            maxWidth="xs"
            className={classes.modalWidth}
            onClose={() => setaddAllPopUp(false)}
            borderRadius={'25px !important'}
          >
            {/* store Info Modal Starts here */}
            {storeDetailsModal()}
            {/* store Info ends here */}
          </RACModalCard>
          <RACModalCard
            isOpen={errorMsg}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={"9px !important"}

          >

            {noRecordPopup()}

          </RACModalCard>
        </Grid>
      </Grid>
      <Grid container className={classes.fixedFooter}>
        <Grid>
          <RACButton variant="outlined" color="primary" className={`${classes.borderNone} ${classes.textBlack}`} onClick={() => {
            history.push({
              // eslint-disable-next-line no-useless-escape
              pathname: `/dashboard`,
            })
          }}>Cancel</RACButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchResult;
