/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable no-console */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';


import { Card, CardContent, CircularProgress, Grid, RACButton, RACModalCard, RACSelect, RACTextbox, Typography } from "@rentacenter/racstrap";
import CurrencyInput from 'react-currency-input-field';

import { formStyles } from "../../componentstyles/formstyles";
import { globalStyles } from "../../componentstyles/globalstyles";

import { getInventoryPriceTags, getExceptionReasonList, updatePriceTagStatus, updatePriceTagException, PrintFaxSheet, getCurrentUser, getCoworkerDetails, getprofileaccess } from "../../../api/user";
import { getPriceTagsReq, InventoryPriceTags, updateCurrentPriceTagReq, PriceException, paramType, updatePriceExceptionReq } from "./PriceTagProps";

import { formatter, sleep, validateData } from "../../../constants/CommonMethods";
import { InventoryContext } from "../../../context/InventoryInfoContext";
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import PriceTagCard from "./DynamicPriceCard";
import PrintPriceTagDocument from "./PriceTagTabDoc";
import { addedItemStyles } from "../../componentstyles/addedItemStyles";
import SecondFactor from "../InventoryInfo/secondAuthen";
import SecondFactorPassWord from "./SecondAuthPassword";
import PrintFunction from "../../Shared/singleItemPriceTag";
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';

export interface Loaders {
  pageLoader: boolean;
  printLoader: boolean;
  btnLoader:boolean;
}

export interface buttonType {
  printBtn: boolean;
  priceExpBtn: boolean;
  saveBtn: boolean;
}

export interface lastModified {
  excepLast: boolean;
  autoLast: boolean;
}

export interface validPopUpType {
  rateFieldEmpty: boolean;
  minRateAllowed: boolean;
  secondLevelAuth: boolean;
  overrideInvalid: boolean;
  depreciationAllowed: boolean;
  allowedRates: boolean;
  maxRateAllowed: boolean;
  allowedTermRange: boolean;
  cancelPriceException: boolean;
  secondLevelPin: boolean
}

let depr: number;
let minrateAllowed: number;
let newTotal: number;

export default function PriceTag(props:any){

  const classes = globalStyles();
  const formclasses = formStyles();
  const addedItemClassName=addedItemStyles();

  const history=useHistory();

  const {inventoryNum} = useParams<paramType>();

  const { headerValue } = useContext(InventoryContext);
  const [headerContext, setheaderContext] = headerValue;
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [currentRole,setCurrentRole]=useState('');
  
  const [DisableBtn,setDisableBtn]=useState<buttonType>({printBtn:true, priceExpBtn:true,saveBtn:true });

  const priceExcepValues :PriceException=
  {
    rate:0,
    term:'',
    excepReason: '',
    excepExplanation:''
  }
  const [priceExcep,setpriceExcep]=useState<PriceException>(priceExcepValues);

  const [validPopUpType,setvalidPopUpType]=useState<validPopUpType>(
  {
    rateFieldEmpty:false,
    maxRateAllowed:false,
    minRateAllowed:false,
    secondLevelAuth:false,
    secondLevelPin:false,
    overrideInvalid:false,
    depreciationAllowed:false,
    allowedRates:false,
    allowedTermRange:false,
    cancelPriceException:false,
  });
  const [validationPopUp,setvalidationPopUp]=useState<boolean>(false);

  const [priceExcepPopUp,setpriceExcepPopUp]=useState<boolean>(false);

  const [priceTags,setPriceTags]=useState<InventoryPriceTags>({
    newPriceTag:[],
    autoPriceTag:[],
    currentPriceTag:[],
    editPriceTag:[]
  });

  const [errMsg,seterrMsg]=useState<any>({wentWrong:false,norecord:false,noactivetags:false});

  const [priceTagInfo,setpriceTagInfo]=useState<any>();
  const [popUp,setpopUp] = useState(false);
  const [popUpType,setpopUpType]= useState<string>("");

  const [loader,setloader]= useState<Loaders>({pageLoader:false,printLoader:false,btnLoader:false});
  const [reasonList,setreasonList]=useState<any[]>([]);

  const [allowedRate,setallowedRate]=useState<any>();
  const [singleItemPrice,setsingleItemPrice]=useState<any>();
  const [singleItemVisble,setsingleItemVisble]= useState(false);

  const storeNum:any= window.sessionStorage.getItem('storeNumber') ? window.sessionStorage.getItem('storeNumber'): '';
  const [storeCity,setstoreCity]=useState("");
  const [moduleName,setmoduleName]=useState<any>('Edit Price tag');
  const [profileDetails,setprofileDetails]=useState<any>();
  const [employeeId,setemployeeId]=useState<any>();


  useEffect(()=>{

    if(validateData(props) && validateData(props.invenpricetaginfo) && validateData(props.invenpricetaginfo.data)){
      if(validateData(props.invenpricetaginfo.data.response)){
        if(validateData(props.invenpricetaginfo.data.response[0])){
          setloader({...loader,pageLoader:true});

          props.invenpricetaginfo.data.response.map((value:any)=>{
              value.headerDetails.minTerm=10;
              value.headerDetails.maxTerm = value.priceTags[3].term;
            setpriceTagInfo(value);

          });

          // setpriceTagInfo(props.invenpricetaginfo.data.response[0]);
          setloader({...loader,pageLoader:false});
          console.log('priceTags',priceTags);
          if(priceTags.autoPriceTag.length==0 && priceTags.currentPriceTag.length==0 && priceTags.editPriceTag.length==0 && priceTags.newPriceTag.length==0)
          {
            buildCards(props.invenpricetaginfo.data.response[0]);
          }         
        }
        else{
          seterrMsg({...errMsg,norecord:true});
        }
      }
      else{
        if(props.invenpricetaginfo.data.errors[0].error == "Tags are invalid to this inventory"){
          seterrMsg({...errMsg,noactivetags:true});
        }
        else{
          seterrMsg({...errMsg,wentwrong:true});
        }
      } 
      
    }    

  },[props]);

  useEffect(()=>{
    if(headerContext!==undefined && headerContext.status=='Rent Ready'){
      if(containerData!==undefined){
        setCurrentRole(containerData?.GetRole())
        setemployeeId(containerData?.GetEmployeeId())
      }
      else{
        CurrentUserDetails();
      }
      
      const getprofileaccessDetails=async()=>{
        const payload:any={
        module:moduleName,
        storeNumber:storeNum,
        currentRole:containerData?.GetRole()
        }
        const result = await getprofileaccess(payload);
        validateData(result)?
        setprofileDetails(result):
        setprofileDetails(undefined);
      }
      getprofileaccessDetails();
    }
    
  },[]);


  const CurrentUserDetails=async()=>{
    const currentUserRes = await getCurrentUser();
    if(validateData(currentUserRes)===true){
      const EmployeeId = currentUserRes.data.employeeId;

      const payload = {
        coworkerId: EmployeeId,
        storesRequired: true
      }
      
      const coWorkerRoleObj: any = await getCoworkerDetails(payload);
      setCurrentRole(coWorkerRoleObj.data.coworkerProfile.role);
    }
    else{
      // seterrMsg({...errMsg,wentwrong:true});

      throw new Error ('cannot fetch employeeId');
    }


  }

  const getPriceTagAPICall =async()=>{
    try{
      const request :getPriceTagsReq={
        inventoryNumbers:[inventoryNum],
        isCurrentPriceTag:false
      }

      setloader({...loader,pageLoader:true,printLoader:false});

        const apiResponse = await getInventoryPriceTags(request);

        console.log("pricetagresponse1",apiResponse)

      setloader({...loader,pageLoader:false,printLoader:false});

        if(apiResponse!==undefined && apiResponse !==null && apiResponse.data!==undefined && apiResponse.data!==null && apiResponse.data.response!==undefined && apiResponse.data.response!==null){

          if(apiResponse.data.response[0] !==undefined && apiResponse.data.response[0]!==null){
            // setpriceTagInfo(apiResponse.data.response[0]);

            apiResponse.data.response.map((value:any)=>{
              // if(value.headerDetails.minTerm ==undefined || value.headerDetails.minTerm ==null){
                value.headerDetails.minTerm=10;
              // }
              // if(value.headerDetails.maxTerm == null || value.headerDetails.maxTerm ==undefined){
                value.headerDetails.maxTerm= value.priceTags[3].term;
              // }
              
              setpriceTagInfo(value);
            })

            buildCards(apiResponse.data.response[0]);

          }
          else{
            seterrMsg({...errMsg,norecord:true});
          }

        }
        else{
          if(apiResponse.data.errors[0].error == "Tags are invalid to this inventory"){
            seterrMsg({...errMsg,noactivetags:true});
          }
          else{
            seterrMsg({...errMsg,wentwrong:true});
          }
          // seterrMsg({...errMsg,wentwrong:true});
        }

    }
    catch(error:any){
        throw new Error('something went wrong'+ error);
    }

  }

  const buildCards=(data:any)=>{

    if(validateData(data)===true){
      if(data.priceTags !==undefined && data.priceTags !==null){

        const newPrice :any =buildPriceTags(data.priceTags,'new');
        const autoPrice:any= buildPriceTags(data.priceTags,'auto');
        const currentPrice:any= buildPriceTags(data.priceTags,'current');
        const editPrice:any= buildPriceTags(data.priceTags,'excep');

        setPriceTags({...priceTags,newPriceTag:newPrice,autoPriceTag:autoPrice,currentPriceTag:currentPrice,editPriceTag:editPrice});
      }

      if(data.headerDetails.priceTagQueued === true && headerContext.status=='Rent Ready' && (headerContext.storeNumber == storeNum)){ 

          setpopUpType("pageLoad");
          setpopUp(true);
          setDisableBtn({...DisableBtn,printBtn:true,priceExpBtn:true,saveBtn:true});

      }
      else if (data.headerDetails.priceTagQueued === true && headerContext.status !=='Rent Ready'){

        setDisableBtn({...DisableBtn,printBtn:true,priceExpBtn:true,saveBtn:true});

      }
      else if (data.headerDetails.priceTagQueued === false && headerContext.status !=='Rent Ready'){

        setDisableBtn({...DisableBtn,printBtn:true,priceExpBtn:true,saveBtn:true});
      
      }
      else{
        setDisableBtn({...DisableBtn,printBtn:false,priceExpBtn:false,saveBtn:true});  
      }
    }
    // CurrentUserDetails();

  }
  
  
/************Functions to map response received from API Call into corresponding PriceTags */
  const buildPriceTags=(data: any,key:string )=>{
    let newP:any;
    const priceData = validateData(data)? data:[];

      switch(key){
        case 'new':
          priceData.filter((value:any)=>{
            if(value.conditionRefCode==='NEW'){
                newP = [{
                depreciationPercentage : value.depreciationPercentage,
                grossProfitPerPayment : value. grossProfitPerPayment,
                turns: value.turns,
                rate : value.rate,
                term : value.term,
                cashPrice : value.cashPrice,
                totalCost : value.totalCost,
                conditionRefCode: value.conditionRefCode,
                addOnRate : value.addOnRate,
                lastModified:value.lastModifiedDate,
                inventoryPriceId:value.inventoryPriceId
              }]
            }
          })
          break;
        case 'auto':
          priceData.filter((value:any)=>{
            if(value.conditionRefCode==="ATO"){
              newP = [{
                depreciationPercentage : value.depreciationPercentage,
                grossProfitPerPayment : value. grossProfitPerPayment,
                turns: value.turns,
                rate : value.rate,
                term : value.term,
                cashPrice : value.cashPrice,
                totalCost : value.totalCost,
                conditionRefCode: value.conditionRefCode,
                addOnRate : value.addOnRate,
                lastModified:value.lastModifiedDate,
                // lastModified:"2019-03-20 15:20:54.607571+05:30",
                inventoryPriceId:value.inventoryPriceId
              }]
            }
          })
          break;
        case 'current':
          priceData.filter((value:any)=>{
            if(value.conditionRefCode==="CURRENT"){
              newP = [{
                depreciationPercentage : value.depreciationPercentage,
                grossProfitPerPayment : value. grossProfitPerPayment,
                turns: value.turns,
                rate : value.rate,
                // rate : 10.99,
                term : value.term,
                cashPrice : value.cashPrice,
                totalCost : value.totalCost,
                conditionRefCode: value.conditionRefCode,
                addOnRate : value.addOnRate,
                lastModified:value.lastModifiedDate,
                inventoryPriceId:value.inventoryPriceId
              }]
            }
          })
          break;
        case 'excep':
          priceData.filter((value:any)=>{
            if(value.conditionRefCode==="EXCP"){
              newP = [{
                depreciationPercentage : value.depreciationPercentage,
                grossProfitPerPayment : value. grossProfitPerPayment,
                turns: value.turns,
                rate : value.rate,
                term : value.term,
                cashPrice : value.cashPrice,
                totalCost : value.totalCost,
                conditionRefCode: value.conditionRefCode,
                addOnRate : value.addOnRate,
                inventoryPriceId:value.inventoryPriceId,
                lastModified:value.lastModifiedDate
                // lastModified:"2019-03-30 10:20:54.607571+05:30",

              }]
            }
          })
          break;
      }

    return newP;
  }

/***********Function invoked inthe Onclick of Print button to Print  all the pricetags   */
const onClickPrint=async()=>{
  setloader({...loader,btnLoader:true});
  const response:any=await PrintFaxSheet(storeNum);
  setstoreCity(validateData(response)?validateData(response.data)?validateData(response.data.response)?validateData(response.data.response[0])?validateData(response.data.response[0].city)?response.data.response[0].city:'':'':'':'':'') 
  setloader({...loader,btnLoader:false});

  printPriceTagsTab();

}

const printPriceTagsTab=()=>{
const printReport = document?.getElementById('printPriceTagDoc')?.innerHTML;
if (printReport !== null && printReport !== undefined) {
  const a: any = window.open(
    '',
    '',
    'width=1000,height=650,scrollbars=1,menuBar=1'
  );
  a.document.write('<html>');
  a.document.write(printReport);
  a.document.write('</html>');
  a.document.close();
  a.print();
}
}



/***********Function invoked inthe Onclick of PrintPriceTag button    */
const onPrintPriceTag=()=>{

setpopUp(false);
setDisableBtn({...DisableBtn,priceExpBtn:false});


let req:any[];

          
    const updatePriceTag = async()=>{

      if(priceTags.editPriceTag[0].lastModified > priceTags.autoPriceTag[0].lastModified ){
         req= [priceTags.editPriceTag[0].inventoryPriceId]
         console.log("vbnm",priceTags.editPriceTag[0].inventoryPriceId)

      }
      else if(priceTags.editPriceTag[0].lastModified < priceTags.autoPriceTag[0].lastModified){
        console.log("vbnmnnn",priceTags.editPriceTag[0].inventoryPriceId)

        req=[priceTags.autoPriceTag[0].inventoryPriceId] 
      }
      else if ((priceTags.currentPriceTag[0].inventoryPriceId == priceTags.autoPriceTag[0].inventoryPriceId) && (priceTags.editPriceTag[0].inventoryPriceId == priceTags.newPriceTag[0].inventoryPriceId)){
        req=[priceTags.newPriceTag[0].inventoryPriceId] 
      }
      else{
        req=[priceTags.newPriceTag[0].inventoryPriceId] 
      }
      // setpopUp(true); 
      // setpopUpType('printing');
      // setloader({...loader,printLoader:true,pageLoader:false})
      console.log('req received in pricetagStatus',req);
      setpopUpType('printing');
      setpopUp(true); 

      const res: any = await updatePriceTagStatus({inventoryPriceIds:req});
      console.log('res received in pricetagStatus',res);

      

      setloader({...loader,printLoader:false})
      
      const resp:any = validateData(res) ? res : 0 ;
      console.log('resp received in pricetagStatus',resp);


      if(resp.status === 200){
        // setpopUp(true); 
        // setpopUpType('printing');

        setsingleItemPrice(resp.data.response);
        setpopUp(false);
        
        getPriceTagAPICall();
        setsingleItemVisble(true);
        printSingleItemPriceTag();
        setsingleItemVisble(false);

      }
      else{
        setpopUpType('err_priceTagStatus');
        setpopUp(true); 

        // seterrMsg({...errMsg,wentwrong:true});
      }
    }
    updatePriceTag(); 
    console.log('setsingleItemPrice received in pricetagStatus',singleItemPrice);
     
}


/***********Function to Print the Single Item Pricetag PDF   */
// const printSingleItemPriceTag=()=>{

// const singlePriceTag  = document?.getElementById('singleItemPriceTag')?.innerHTML;
// if(singlePriceTag !==undefined && singlePriceTag !== null){
//   const a: any = window.open(
//     "",
//     "",
//     'width=384,height=576,scrollbars=1,menuBar=1'
//   );
//   a.document.write("<html>");
//   a.document.write(singlePriceTag);
//   a.document.write("</html>");
//   a.document.close();
//   a.print();
// }

// }

const singlePriceTag=useRef<HTMLDivElement>(null);

/***********Function to Print the Single Item Pricetag PDF   */
const printSingleItemPriceTag=useReactToPrint({ 
  content: () => singlePriceTag.current,
  pageStyle: `
  @media print {
    html, body {
      display: block !important;
      width: 129mm !important;
      height: 105mm !important;
      }   
  }
  
  `
})


/***********Function invoked inthe Onclick of PriceException  button    */
const onClickPriceException= async ()=>{

setpriceExcepPopUp(true);
setpopUp(false);

if(priceExcep.rate==0 || priceExcep.term=='' || priceExcep.term.length ==0 ){
    setpriceExcep({...priceExcep,excepReason:'Select',rate:priceTags.editPriceTag[0].rate,term:priceTags.editPriceTag[0].term.toString(),excepExplanation:''});

    setloader({...loader,printLoader:true});
    const resp: any = await getExceptionReasonList();

    setloader({...loader,printLoader:false});


const reasonsArr:any[]=(validateData(resp)?validateData(resp.data)? validateData(resp.data.referenceDetails)?resp.data.referenceDetails:[]:[]:[]);

const setreasonArr:any[]=[{referenceCode:"Select",description:'Select'},...reasonsArr];

setreasonList(validateData(setreasonArr)? setreasonArr:[]);  
}

}

/***********Function to bind  the dropdown values in Exception Reason SelectBox  */
const buildReasonList = () => {
if (reasonList !== undefined) {
  return reasonList.map((entry: any) => {
    return { label: entry.description, value: entry.id };
  });
} else {
  return [{ label: "Select", value: "Select" }];
}
};


const enableSaveButton=()=>{

if(priceExcep !==undefined && priceExcep !==null
  && priceExcep.rate!==undefined
  && priceExcep.rate!==null
  && priceExcep.term!==undefined 
  && priceExcep.term!==null
  && priceExcep.excepReason!=='Select'
  && priceExcep.excepExplanation!==undefined
  && priceExcep.excepExplanation.length!==0
  && priceExcep.excepExplanation!=='' )
  
{
  return false;
}
else{
  
  return true;
}
}

/***********Function invoked inthe onBlur  of term field , to validate the term entered is within the range or not    */
const validateTerm=(e:any)=>{
  console.log('pppppp',e.target.value);
const minTermAllowed = priceTagInfo.headerDetails.minTerm;
const maxTermAllowed = priceTagInfo.headerDetails.maxTerm;

if (priceExcep.term > maxTermAllowed || (priceExcep.term)< minTermAllowed){
  setvalidationPopUp(true);
  setvalidPopUpType({...validPopUpType,allowedTermRange:true});
}
}



const validateRateField=(e:any)=>{

if(e.target.value=='' || e.target.value.length==0){
  setvalidPopUpType({...validPopUpType,rateFieldEmpty:true});
  setvalidationPopUp(true);
}

else if(parseFloat(e.target.value) > (priceTags.editPriceTag[0].rate)){
  setvalidPopUpType({...validPopUpType,maxRateAllowed:true});
  setvalidationPopUp(true);
}

}

const onCancelPriceException=()=>{

if (priceExcep.rate !== priceTags.editPriceTag[0].rate || priceExcep.term !== priceTags.editPriceTag[0].term.toString() || priceExcep.excepExplanation!=='' || priceExcep.excepReason!=='Select')
{
  setvalidationPopUp(true); 
  setvalidPopUpType({...validPopUpType,cancelPriceException:true});
}
else{
  setpopUp(false) ; 
  setDisableBtn({...DisableBtn,saveBtn:true});
  setpriceExcepPopUp(false)
  //need to add
  //setpriceExcep({...priceExcep,rate:0,term:0,excepExplanation:'',excepReason:'Select'})
}

}

const checkDepreciation=(currentDepr:number,sla:boolean)=>{

  if(sla === false){

    if(currentDepr>33){
      setvalidationPopUp(true);
      setvalidPopUpType({...validPopUpType,depreciationAllowed:true});
    }
    else{
      saveRateAndTerm();
    }
  }
  else{

    if(currentDepr > 33)
    {
    setvalidPopUpType({...validPopUpType,depreciationAllowed:true,secondLevelAuth:false});
    }
    else{
      setvalidationPopUp(false);
      setvalidPopUpType({...validPopUpType,secondLevelAuth:false});
      setpriceExcepPopUp(false);
      saveRateAndTerm();
    }
  }

}


const onSavePriceException= async()=>{

if(
  (priceTags.editPriceTag[0].rate !== priceTags.currentPriceTag[0].rate || 
  priceTags.editPriceTag[0].term !== priceTags.currentPriceTag[0].term ||
  priceTags.editPriceTag[0].turns !== priceTags.currentPriceTag[0].turns ||
  priceTags.editPriceTag[0].totalCost !== priceTags.currentPriceTag[0].totalCost ||
  priceTags.editPriceTag[0].cashPrice !== priceTags.currentPriceTag[0].cashPrice ||
  priceTags.editPriceTag[0].depreciationPercentage !== priceTags.currentPriceTag[0].depreciationPercentage ||
  priceTags.editPriceTag[0].grossProfitPerPayment !== priceTags.currentPriceTag[0].grossProfitPerPayment)
  && 
  (new Date(priceTags.editPriceTag[0].lastModified) > new Date(priceTags.autoPriceTag[0].lastModified))
){
  setpopUpType('pageLoad');
  setpopUp(true);
}
else{

      newTotal = (priceExcep.rate)* parseInt(priceExcep.term);
      depr = parseFloat(((parseFloat(priceTagInfo.headerDetails.remainingValue)/newTotal)*100).toFixed(2));

    if(priceTags.editPriceTag[0].rate !== priceExcep.rate){

      
      if(currentRole =='CAR'){

          if((priceExcep.rate) < (priceTags.editPriceTag[0].rate)){

            setvalidationPopUp(true);
            setvalidPopUpType({...validPopUpType,minRateAllowed:true});
            setallowedRate((priceTags.editPriceTag[0].rate));
          }
          else{
            // saveRateAndTerm();
            checkDepreciation(depr,false);
          }
      }
      else if(currentRole== 'AM' || currentRole== 'LAM' || currentRole== 'SM' ){

            minrateAllowed=parseFloat(((priceTags.editPriceTag[0].rate) - ((priceTags.editPriceTag[0].rate)*0.1)).toFixed(2));

            if((priceExcep.rate) < minrateAllowed)
            {
            setvalidationPopUp(true);
            setallowedRate(minrateAllowed);
            setvalidPopUpType({...validPopUpType,minRateAllowed:true});
            }
            else{

              checkDepreciation(depr,false);
            }
            
      }

      else if(currentRole=='RD' || currentRole=='DM' || currentRole=='DMT'){
            minrateAllowed =parseFloat(((priceTags.editPriceTag[0].rate) - ((priceTags.editPriceTag[0].rate)*0.5)).toFixed(2));

            if((priceExcep.rate) < minrateAllowed)
            {
              setvalidationPopUp(true);
              setallowedRate(minrateAllowed);
              setvalidPopUpType({...validPopUpType,allowedRates:true});
            }
            else{
              checkDepreciation(depr,false);
            } 
      }
    }
    else if(priceTags.editPriceTag[0].term.toString() !== priceExcep.term){
      
      checkDepreciation(depr,false);
    }
    else {
      saveRateAndTerm();
    } 

  }
}


/****function invoked in the onClick of Save button , to save the PriceException  */
const saveRateAndTerm=async()=>{

const payload:updatePriceExceptionReq={
  rate:(priceExcep.rate).toString(),
  term:parseInt(priceExcep.term),
  inventoryPriceId:priceTags.editPriceTag[0].inventoryPriceId,
  exceptionReasonId:parseInt(priceExcep.excepReason),
  explanation:priceExcep.excepExplanation,
}

setpopUp(false);
setpriceExcepPopUp(false);
setvalidationPopUp(false); 
setDisableBtn({...DisableBtn,priceExpBtn:true});
console.log('payload in updatePriceTagException',payload)
setloader({...loader,pageLoader:true});
const resp = await updatePriceTagException(payload);
setloader({...loader,pageLoader:false});

if(validateData(resp)===false){
  setpopUpType('err_priceException');
  setpopUp(true);
  // seterrMsg({...errMsg,wentwrong:true})
}

console.log('resp received in priceTagException',resp)

if(validateData(resp)===true && validateData(resp.data)===true){
  if(resp.data.statusMessage=='success'){
    setDisableBtn({...DisableBtn,priceExpBtn:false});
    setpriceExcep(priceExcepValues);
    getPriceTagAPICall();
  }
  else{
    setpopUpType('err_priceException');
    setpopUp(true);
    setDisableBtn({...DisableBtn,priceExpBtn:false});

    // throw new Error ('something went Wrong, priceException not updated');
  }
}
else{
    setpopUpType('err_priceException');
    setpopUp(true);
    setDisableBtn({...DisableBtn,priceExpBtn:false});

  // throw new Error ('something went Wrong, updatePriceTagExceptionFailed');
}
}

const onPageLoadOk=()=>{
if(priceExcepPopUp===true){
  setpopUp(false);
  setvalidPopUpType({...validPopUpType,secondLevelPin:true});
  setvalidationPopUp(false);
}
else{
  setpopUp(false);setDisableBtn({...DisableBtn,printBtn:false});
}
}

/****function to show the popUps needed for the screen according to the popUptype */
const showPopup=(value:any)=>{
    if(value=='pageLoad'){
          return(
            <Grid>
              <Typography className={`${classes.fontBold} ${classes.fs6} ${classes.mx4} ${classes.textCenter}`} >
                Please print the price tag before changing price tag information 
              </Typography>

              <RACButton color="primary" variant="contained" 
                onClick={()=>onPageLoadOk()}
                className={`${classes.marginLeftRight40} ${classes.mt4}`}>
                OK            
              </RACButton>
            </Grid>  
          )
    }
    else if(value=='printPriceTag'){
          return(
            <Grid>
              <Grid>
                {/* changes */}
                <Typography className={`${classes.fontBold} ${classes.fs6} ${classes.mx4} ${classes.textCenter}`}>
                  Are the Price Tag forms in the Paper tray of the pointer.
                </Typography>
              </Grid>

              <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
                <RACButton color="secondary" variant="outlined" onClick={()=>setpopUp(false)} className={`${classes.mr3} ${classes.btnWidth}`}>
                No          
                </RACButton>

                <RACButton color="primary" variant="contained" onClick={()=>onPrintPriceTag()}className={`${classes.btnWidth}`} >
                  Yes            
                </RACButton>
              </Grid>
            </Grid>  
          )
    }
    else if(value=='printing'){
      return(
        <Grid >
          <Grid >
          <Typography className={`${classes.textCenter} ${classes.mb2}`}>printing...</Typography>
          </Grid >
          <Grid className={`${classes.componentCenter} ${classes.mb2}`}>
            <CircularProgress/>
          </Grid>
          <Grid >
          <Typography className={`${classes.textCenter} ${classes.mb2} ${classes.fontBold}`}>SingleItemPriceTag</Typography>

          </Grid>
          <Grid className={`${classes.textCenter} ${classes. mb2}`}>
          </Grid>
        </Grid>
      )
    }
    else if(value=='err_priceTagStatus'){
      return(
        <Grid >
          <Grid className={`${classes.componentCenter} ${classes.mb1}`}>
            <AlertIcon/>
          </Grid>
          <Grid>
              <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx3} ${classes.textCenter}`}>
                Current Price Tag is not updated!
                {/* to ${priceTags.editPriceTag[0].rate}. */}
              </Typography>
          </Grid>

          <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
              <RACButton color="primary" variant="contained" onClick={()=>{setpopUp(false)}} className={`${classes.mr3} ${classes.btnWidth}`}>
                OK          
              </RACButton>
          </Grid>
    </Grid>
      )
    }
    else if(value=='err_priceException'){
      return(
        <Grid >
          <Grid className={`${classes.componentCenter} ${classes.mb1}`}>
            <AlertIcon></AlertIcon>
          </Grid>
        <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx3} ${classes.textCenter}`}>
              Price Exception is not updated!
              {/* to ${priceTags.editPriceTag[0].rate}. */}
            </Typography>
        </Grid>

        <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={()=>{setpopUp(false)}} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK          
            </RACButton>
        </Grid>
    </Grid>
      )
    }
}


/****function to show the popUps needed for the validation of rate field  */
const validationPopUps=(validType:any)=>{
if (validType.maxRateAllowed===true){
  return(
        <Grid >
          <Grid>
                <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
                  The maximum rate that can be changed is ${(priceTags.editPriceTag[0].rate).toFixed(2)}.                   
                </Typography>
              </Grid>

              <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
                <RACButton color="primary" variant="contained" onClick={()=>{setvalidationPopUp(false),setvalidPopUpType({...validPopUpType,maxRateAllowed:false}),setpriceExcep({...priceExcep,rate:priceTags.editPriceTag[0].rate})}} className={`${classes.mr3} ${classes.btnWidth}`}>
                OK          
                </RACButton>

              </Grid>
        </Grid>
  )
}
else if (validType.minRateAllowed===true){
  return(
    <Grid >
        <Grid>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
            Coworker can change Rate upto ${allowedRate.toFixed(2)}. Second Level is required for this change. Do you want to proceed?   
          </Typography>
        </Grid>

        <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>

          <RACButton color="secondary" variant="outlined" onClick={()=>{setvalidationPopUp(false),setvalidPopUpType({...validPopUpType,minRateAllowed:false}),setpriceExcep({...priceExcep,rate:priceTags.editPriceTag[0].rate})}} className={`${classes.mr3} ${classes.btnWidth}`}>
          No          
          </RACButton>

          <RACButton color="primary" variant="contained" onClick={()=>{setvalidationPopUp(false),setvalidPopUpType({...validPopUpType,secondLevelAuth:true,minRateAllowed:false}),setpriceExcepPopUp(false)}}className={`${classes.btnWidth}`} >
            Yes            
          </RACButton>
         
        </Grid>
  </Grid>
  )
}

else if(validType.overrideInvalid===true){
  return(
    <Grid >
        <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
              Override is invalid . SLA user can override till ${allowedRate.toFixed(2)}.
              {/* to ${priceTags.editPriceTag[0].rate}. */}
            </Typography>
        </Grid>

        <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={()=>{setvalidPopUpType({...validPopUpType,overrideInvalid:false,secondLevelAuth:true}),setvalidationPopUp(false),setpriceExcepPopUp(false)}} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK          
            </RACButton>
        </Grid>
    </Grid>
  )
}
else if(validType.depreciationAllowed===true){
  return(
    <Grid >
        <Grid>
            <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
                The depreciation $({depr.toFixed(2)}) is greater than (33.00%). " This may be due to state specific Price rules ".
            </Typography>
        </Grid>

        <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
            <RACButton color="primary" variant="contained" onClick={()=>{setpriceExcepPopUp(false);setvalidPopUpType({...validPopUpType,depreciationAllowed:false,secondLevelAuth:false});setvalidationPopUp(false);saveRateAndTerm()}} className={`${classes.mr3} ${classes.btnWidth}`}>
              OK          
            </RACButton>
        </Grid>
    </Grid>
  )
}
else if(validType.allowedRates===true){
  return(
    <Grid>
      <Grid>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
              Rate  is invalid. Allowable rate values are ${allowedRate.toFixed(2)} to ${(priceTags.editPriceTag[0].rate).toFixed(2)}.             
          </Typography>
      </Grid>

      <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
          <RACButton color="primary" variant="contained" onClick={()=>{setvalidPopUpType({...validPopUpType,allowedRates:false}),setpriceExcep({...priceExcep,rate:priceTags.editPriceTag[0].rate}),setvalidationPopUp(false)}} className={`${classes.mr3} ${classes.btnWidth}`}>
            OK          
          </RACButton>
      </Grid>
    </Grid>
  )
}
else if(validType.rateFieldEmpty===true){
  return(
    <Grid>
      <Grid>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6}  ${classes.textCenter}`}>
            Invalid value in the rate field. 
          </Typography>
      </Grid>

      <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
          <RACButton color="primary" variant="contained" onClick={()=>{setvalidPopUpType({...validPopUpType,rateFieldEmpty:false}),setpriceExcep({...priceExcep,rate:priceTags.editPriceTag[0].rate}),setvalidationPopUp(false)}} className={`${classes.mr3} ${classes.btnWidth}`}>
            OK          
          </RACButton>
      </Grid>
    </Grid>
  )
}
else if(validType.allowedTermRange===true){
  return(
    <Grid>
      <Grid>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
            Term is invalid. Allowed terms are {priceTagInfo.headerDetails.minTerm}-{priceTagInfo.headerDetails.maxTerm}          
          </Typography>
      </Grid>

      <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
          <RACButton color="primary" variant="contained" onClick={()=>{setvalidPopUpType({...validPopUpType,allowedTermRange:false}),setpriceExcep({...priceExcep,term:priceTags.editPriceTag[0].term.toString()}),setvalidationPopUp(false)}} className={`${classes.mr3} ${classes.btnWidth}`}>
            OK          
          </RACButton>
      </Grid>
    </Grid>
  )
}
else if(validType.cancelPriceException===true){
    return(
      <Grid>
            <Grid>
              <Typography className={`${classes.fontSemiBold} ${classes.fs6} ${classes.mx5} ${classes.textCenter}`}>
                  Do you want to save the edit price first?
              </Typography>
            </Grid>

            <Grid className={`${classes.componentCenter} ${classes.mt4} ${classes.mb3}`}>
              <RACButton color="secondary" variant="outlined" onClick={()=>{setpopUp(false) ;setpriceExcepPopUp(false) ;setvalidationPopUp(false);setvalidPopUpType({...validPopUpType,cancelPriceException:false});setDisableBtn({...DisableBtn,saveBtn:true});setpriceExcep({...priceExcep,rate:priceTags.editPriceTag[0].rate,term:priceTags.editPriceTag[0].term.toString(),excepReason:'Select',excepExplanation:''})}} className={`${classes.mr3} ${classes.btnWidth}`}>
              No          
              </RACButton>

              <RACButton color="primary" variant="contained" onClick={()=>{setvalidationPopUp(false);setvalidPopUpType({...validPopUpType,cancelPriceException:false})}}className={`${classes.btnWidth}`} >
                Yes            
              </RACButton>
            </Grid>
          </Grid>
    )
  }

}



//stub need to remove after implementing authentication
const SLACompleted=(value:any)=>{
  setpriceExcepPopUp(true);
  console.log('SLACompleted VAL',value);

  if(value.status===true){
    const logginedRole=value.userId.substring('4');
    console.log('SLACompleted logginedRole',logginedRole);

    setpopUp(false);
    setvalidationPopUp(true);
    
    newTotal = (priceExcep.rate)* parseInt(priceExcep.term);
    console.log('SLACompleted newTotal',newTotal);

    depr = parseFloat(((parseFloat(priceTagInfo.headerDetails.remainingValue)/newTotal)*100).toFixed(2));
    console.log('SLACompleted depr',depr);

  
    if(currentRole=='CAR'){
  
        if(logginedRole== "002" || logginedRole== "003" || logginedRole=="004" ){
  
          minrateAllowed =parseFloat(((priceTags.editPriceTag[0].rate) - ((priceTags.editPriceTag[0].rate)*0.1)).toFixed(2));
          
            if(priceExcep.rate >= minrateAllowed)
            {

              checkDepreciation(depr,true);
            }
            else{
              setallowedRate(minrateAllowed);
              setvalidPopUpType({...validPopUpType,overrideInvalid:true,secondLevelAuth:false});
            }  
        }
        else if (logginedRole== "006" || logginedRole== "007" || logginedRole=="008"){
          minrateAllowed =parseFloat(((priceTags.editPriceTag[0].rate) - ((priceTags.editPriceTag[0].rate)*0.5)).toFixed(2));
  
          if((priceExcep.rate) >= minrateAllowed)
            {
              checkDepreciation(depr,true);

            }
            else{
              setallowedRate(minrateAllowed);
              setvalidPopUpType({...validPopUpType,overrideInvalid:true,secondLevelAuth:false});
            }
        }
        else{
          setvalidPopUpType({...validPopUpType,overrideInvalid:true,secondLevelAuth:false});
        }
    }
    else if(currentRole=='AM' || currentRole=='SM' || currentRole =='LAM'){
  
        if(logginedRole== "006" || logginedRole== "007" || logginedRole=="008"){
          minrateAllowed =parseFloat(((priceTags.editPriceTag[0].rate) - ((priceTags.editPriceTag[0].rate)*0.5)).toFixed(2));
  
          if((priceExcep.rate) >= minrateAllowed)
            {
              checkDepreciation(depr,true);

            }
            else{
              setallowedRate(minrateAllowed);
              setvalidPopUpType({...validPopUpType,overrideInvalid:true,secondLevelAuth:false});
            }
        }
        else{
          setvalidPopUpType({...validPopUpType,overrideInvalid:true,secondLevelAuth:false});
        }
    
  
    }
  }
  
}


const openPriceExceptionPopUp=()=>{
return(
  <Grid className={`${classes.ml5} ${classes.my2} `} > 
          {/* top1 */}
              <Grid className={`${classes.floatLeft} ${classes.w50} ${classes.mb4}`} >
                  <Grid className={`${classes.floatLeft} ${classes.mr3} `} >
                      <Typography>
                          Rate
                      </Typography>
                  </Grid>
                  <Grid 
                  className={`${addedItemClassName.inputGroup} ${classes.w50} `} style={{marginTop: '-8px'}}
                  >
                    <Typography className={addedItemClassName.inputGroupText}>
                      $
                    </Typography>

                    <CurrencyInput

                      id="rate"
                      name="rate"
                      value={ priceExcep.rate }
                      className={`${classes.formControl} ${addedItemClassName.currencyAlign} ${classes.fontSemiBold} `} 
                      decimalScale={2}
                      decimalsLimit={2}
                      onValueChange={(e:any) => setpriceExcep({...priceExcep,rate:(e)})}
                      onBlur={(e:any) => validateRateField(e)}
                      
                    />

              </Grid>
              </Grid>
              <Grid className={`${classes.floatLeft} ${classes.w50}`}>
                  <Grid className={`${classes.floatLeft} ${classes.mr3}`}>
                      <Typography>
                          Term
                      </Typography>
                  </Grid>
                  <Grid className={`${classes.floatLeft}`}
                  //  ${classes.mr3}
                   style={{marginTop: '-13px',fontSize:'17px !important'}}
                   >
                  <RACTextbox
                      className={`${classes.inputBox}  ${classes.textRightAlign}`} 
                      type="number"
                      id="term"
                      name="term"
                      value={(priceExcep.term)}
                      maxlength={5}
                      OnChange={(e)=>setpriceExcep({...priceExcep,term:(e.target.value)})}
                      Onblur={(e)=>validateTerm(e)}
                  />  
                  </Grid>
            </Grid>
          {/* middle*/}
              <Grid className={` ${classes.width40}`}>
                  <Typography className={`${classes.fontSemiBold} ${classes.fs7} ${classes.my2}`}>
                      Exception Reason
                  </Typography> 
                  <RACSelect
                    loading={loader.printLoader}
                    name="exceptionList"
                    isDisabled={false}
                    defaultValue={priceExcep.excepReason!==''?priceExcep.excepReason:'Select'}
                    options={buildReasonList()}
                    onChange={(e)=>{setpriceExcep({...priceExcep,excepReason:e.target.value})}} 
                  />
              </Grid>
              <Grid className={`${classes.mt4} `}>
                  <Typography className={`${classes.mb2}`}>
                      Explanation for Inventory Depreciation Cap Exception
                  </Typography>
                  <textarea
                  name="explanation"
                  maxLength={255}
                  value={priceExcep.excepExplanation}
                  className={`${formclasses.FormTextArea} `}
                  onChange={(e)=> setpriceExcep({...priceExcep,excepExplanation:e.target.value})}>
                  </textarea>
              </Grid>
          {/* footer */}
          <Grid className={`${classes.mt4} ${classes.mr6} `}>
          <Grid className={classes.floatRight}>
              <RACButton color="primary" variant="contained" disabled={enableSaveButton()}
              onClick={()=>onSavePriceException()}
              style={{width:'90px'}}
              >
                    Save          
              </RACButton>
              </Grid>

              <Grid className={classes.floatRight}>    
              <RACButton color="secondary" variant="outlined" 
                  className={`${classes.mr3} `}
                  onClick={()=>{ onCancelPriceException() }}>
                    Cancel        
              </RACButton>
              </Grid>
          </Grid>
  </Grid>

)
}

/***********Function invoked inthe Onclick of continue button in the PinPopUp   *///need rework on this
const onClickContinuePin=(e:any)=>{
if(e === true){
setvalidPopUpType({...validPopUpType,secondLevelPin:false});
setvalidationPopUp(false);
setpopUpType('printPriceTag');
setpopUp(true);
}
}

const showLoader=()=>{
return(
    <Grid>
      <Grid className=
      {loader.printLoader===true? classes.loaderStyle: classes.racLoaderPage}
      >
        <CircularProgress />
      </Grid>
    </Grid>
)
}
return (  
    <React.Fragment>

{/**Component starts */}  
   {
     errMsg.wentwrong === true|| errMsg.norecord === true || errMsg.noactivetags === true?
        <Grid className={`${classes.textCenter} ${classes.mt5}`}>
          <AlertIcon />
          <Typography className={`${classes.subTitle} ${classes.mb3}`}>
            {errMsg.wentwrong === true?"Something Went Wrong": errMsg.noactivetags === true? "No Active Price Tags":"No Record Found" }
            
          </Typography>
        </Grid> :

      // errMsg.norecord === true ?
      // <Grid className={`${classes.textCenter} ${classes.mt5}`}>
      //     <AlertIcon />
      //     <Typography className={`${classes.subTitle} ${classes.mb3}`}>
      //       No Record Found       
      //     </Typography>
      //   </Grid> :

  priceTagInfo==undefined || loader.pageLoader=== true? 
      <Grid className={`${classes.textCenter} ${classes.mt5}`}>
        {/* <AlertIcon /> */}
        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
        {showLoader()}
        </Typography>
      </Grid>
  :

/**headerDetails */
      <Grid style={{backgroundColor:"#f2f2f2"}}>
          <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={`${classes.mx1} ${classes.mt4} ${classes.racCard}`}>
                      <CardContent  >
                          <Grid container spacing={2} className={classes.alignCenter}>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                <Typography component="span" className={formclasses.formLabel}>Remaining Value : </Typography>
                                <Typography component="span" className={`${formclasses.formValue} ${classes.mt2}`}>
                                   {validateData(priceTagInfo) ? validateData(priceTagInfo.headerDetails) ? formatter.format(priceTagInfo.headerDetails.remainingValue) :'-':[]} 
                                </Typography>
                              </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                              <Typography component="span" className={formclasses.formLabel}>Purchase Cost : </Typography>
                              <Typography component="span" className={`${formclasses.formValue} ${classes.mt2}`}>
                                   {validateData(priceTagInfo) ? validateData(priceTagInfo.headerDetails) ? formatter.format(priceTagInfo.headerDetails.purchaseCost) :'-' :[]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                              <Typography component="span" className={formclasses.formLabel}>Price Tag Queued : </Typography>
                              <Typography component="span" className={`${formclasses.formValue} ${classes.mt2}`}>
                                {validateData(priceTagInfo) ? validateData(priceTagInfo.headerDetails) ? priceTagInfo.headerDetails.priceTagQueued===true?'Yes':'No':'-':{}}  
                              </Typography>
                            </Grid>
                            
                            {/* <Grid item xs="auto" sm="auto" md="auto" lg="auto" >
                              <Grid container className={`${classes.p3} ${classes.bgLightBlue} ${classes.borderRadius5}`}>
                                <Grid item xs="auto">
                                  <Typography className={`${classes.textBlue} ${classes.fs6}`}>Term : &nbsp;&nbsp;</Typography>
                                </Grid>
                                <Grid item xs="auto">
                                  <Typography className={`${classes.textBlue} ${classes.fs6} ${classes.fontBold}`} >{
                                      validateData(priceTagInfo) ? 
                                        validateData(priceTagInfo.headerDetails) ? 
                                          ((priceTagInfo.headerDetails.maxTerm)!== undefined && (priceTagInfo.headerDetails.maxTerm)!== null) ?
                                                priceTagInfo.headerDetails.maxTerm + " - " +priceTagInfo.headerDetails.minTerm+"  Weeks"
                                          :""
                                        :''
                                      :""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid> */}
                          </Grid>
                      </CardContent>
                  </Card>
              </Grid>
          </Grid>

{/**PriceTags */}            
          <Grid container className={classes.mbFooterSpacing}>
              <Grid item md={3} className={`${classes.width10}`}>
                  <Card className={
                   `${classes.bgTransparent} ${classes.shadow0}`}>
                      <CardContent>
                          <Grid container spacing={2}>
                              <Grid item md={12}>
                                <Typography variant="h5" className={classes.textIndent}>Price Tag Title</Typography>
                                <Card className={`${classes.bgTransparent} ${classes.shadow0} ${classes.mt1}`}>
                                    <CardContent className={`${classes.pt2} ${classes.px2}`}>
                                      <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Depreciation Percentage</Typography>
                                      <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Gross Profit Per Payment</Typography>
                                      <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Turns</Typography>
                                      <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Rate</Typography>
                                      <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Term</Typography>
                                      <Typography className={`${classes.py3} ${classes.pl2} ${classes.borderBottom} ${classes.fs6}`}>Cash Price</Typography>
                                      <Typography className={`${classes.py3} ${classes.pl2} ${classes.fs6}`}>Total Cost</Typography>
                                    </CardContent>
                                </Card>
                              </Grid>
                          </Grid>
                      </CardContent>
                  </Card>
              </Grid>

              <Grid item md={9}>
                  <Card className={`${classes.m2} ${classes.bgTransparent} ${classes.shadow0}`}>
                      <CardContent>
                          <Grid container spacing={2}>
                          
                              {/**newPriceTag */}
                              <Grid item md={3}>
                                <PriceTagCard
                                    priceTagDetails={priceTags.newPriceTag}/>
                              </Grid>

                              {/**autoPriceTag */}
                              <Grid item md={3}>
                                <PriceTagCard
                                    priceTagDetails={priceTags.autoPriceTag}/>
                              </Grid>

                              {/**currentPriceTag */} 
                              <Grid item md={3}>
                                <PriceTagCard
                                    priceTagDetails={priceTags.currentPriceTag}/>
                              </Grid>
                              
                              {/**editPriceTag */}
                              <Grid item md={3}>
                                <PriceTagCard
                                    priceTagDetails={priceTags.editPriceTag}/>
                              </Grid>
                          </Grid>
                      </CardContent>
                  </Card>
              </Grid>
          </Grid>

{/**Footer */}          
          <Grid container className={classes.fixedFooter}>
            <Grid>
              <RACButton 
                variant="outlined" 
                color="primary" 
                className={`${classes.textBlack}`}
                onClick={()=>history.push({pathname:`/inventory/inventorysearch`})}
              >
                Cancel
              </RACButton>
            </Grid>

           {(headerContext.storeNumber == storeNum) ? 
            
              <Grid>
              <RACButton 
                variant="contained" 
                color="primary" 
                className={classes.mr2}
                loading={loader.btnLoader}
                onClick={()=>onClickPrint()}
              >
                  Print
              </RACButton>

               <RACButton 
                variant="contained" 
                color="primary" 
                className={classes.mr2} 
                disabled={
                 DisableBtn.printBtn
                } 
                onClick={()=>{validateData(currentRole)? setvalidPopUpType({...validPopUpType,secondLevelPin:true}):setvalidPopUpType({...validPopUpType,secondLevelPin:false}) ; setvalidationPopUp(false)}}
              >
                  Print Price Tag
              </RACButton>

              <RACButton 
                variant="contained" 
                color="primary" 
                disabled={DisableBtn.priceExpBtn} 
                onClick={()=>onClickPriceException()} 
              >
                Price Exception
              </RACButton> 
            </Grid>
            : null 
            }
            
          </Grid>
              
    </Grid>
  }

{/**Component ends  */}


{/**Screen popups*/}
      <RACModalCard
          className={ popUpType==='pageLoad' ? classes.priceTagPageLoadModalWidth :classes.priceTagPrintModalWidth}
          closeIcon={popUpType=='printPriceTag'? true:false}
          onClose={()=>setpopUp(false)}
          maxWidth="lg"
          borderRadius={'20px !important'}
          isOpen={popUp}
          children={showPopup(popUpType)}

      />
{/**validation popups*/}
      <RACModalCard
        title=''
        isOpen={validationPopUp}
        maxWidth="xs"
        className={validPopUpType.rateFieldEmpty===true? `${classes.priceTagPrintModalWidth}`:`${classes.mAuto}`}
        borderRadius={'20px !important'}
        children={validationPopUps(validPopUpType)}
      />


{/**pinPopUp */}
{/* changes */}
      {validPopUpType.secondLevelPin===true?
      
      <SecondFactor
      setTwoFactorCancelClick={() => {setvalidPopUpType({...validPopUpType,secondLevelPin:false})}}
      setTwoFactorCompleted={(e: any) => onClickContinuePin(e)}
      moduleName={'Edit Price tag'}
      CurrentRole={currentRole}
      employeeID={employeeId}
      getprofileaccessResponse={profileDetails}
      ></SecondFactor>

      : null
      }

{/**Username Password PopUP */}
      {validPopUpType.secondLevelAuth===true?
        <SecondFactorPassWord
        setTwoFactorCancelClick= {()=>{setvalidationPopUp(false),setvalidPopUpType({...validPopUpType,secondLevelAuth:false}),setpriceExcepPopUp(true)}}
        setTwoFactorCompleted= {(e:any)=>SLACompleted(e)}
        moduleName={'Edit PriceTag by Exception'}
        CurrentRole={currentRole}
        />
        
        : null
      }

        <RACModalCard
          title='&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Price Exception '
          className={`${classes.priceExceptionModalWidth}`}
          isOpen={priceExcepPopUp}
          maxWidth="xs"
          borderRadius={'20px !important'}
          children={openPriceExceptionPopUp()}
        />
        
{/**printPriceTag Tab entire Screen */}
      <div id ="printPriceTagDoc" style={{display:'none'}}>
        <PrintPriceTagDocument
          header={validateData(priceTagInfo)?validateData(priceTagInfo.headerDetails)?priceTagInfo.headerDetails:{}:{}}
          priceDetails={priceTags}
          invenDetails={headerContext}
          storeDetail={storeCity}
        />
      </div>

{/**singleItemPriceTag */}
{
  singleItemVisble===true ?
  <div id="singleItemPriceTag" ref={singlePriceTag} >
  <PrintFunction
  printItemNo={[headerContext.inventoryNumber]}
  printLanguage=''
  printItemResp={singleItemPrice}/>
  </div>:null
}
      

    </React.Fragment>  
     
)
}


